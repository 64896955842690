@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.select-image {
    position: static;
    top: 64px;
    right: 64px;
    z-index: 5000;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    margin: 1.25rem;
    color: black;
    text-align: center;
    cursor: pointer;
    background: white;
    .material-design-icon {
        width: 36px;
        height: 36px;
        color: black;
    }
    &.circle {
        padding: 8px;
        border: 2px solid black;
        border-radius: 40px;
    }
    &.small {
        width: 36px;
        height: 36px;
        padding: 4px;
        border-radius: 1.25rem;
        .material-design-icon {
            width: 24px;
            height: 24px;
        }
    }
    &.transparent {
        background: rgba(255, 251, 251, 0.5);
    }

    .image-tool-icons {
        .material-design-icon.plus-icon,
        .material-design-icon.minus-icon {
            left: -5px;
            width: 36px;
            height: 36px;
        }
    }
}
