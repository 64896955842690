@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#cooperative-page {
    .contents {
        max-width: 1800px;
        margin-top: 2rem;
        h1 {
            margin: 0;
            font-family: 'Sabon MT W01 Regular', serif;
            font-size: 2rem;
            line-height: normal;
            text-transform: uppercase;
        }
        h2 {
            font-family: 'Sabon MT W01 Regular', serif;
            font-size: 1.5rem;
            line-height: 2rem;
        }
        h3 {
            margin: 0 auto 2rem;
            font-family: $main-sans-serif;
            font-size: 2rem;
            text-align: center;
        }
        h4 {
            margin: 0 auto 1rem;
            font-family: $main-sans-serif;
            font-size: 1.25rem;
            text-align: center;
        }
        p {
            max-width: 60rem;
            margin-bottom: 1rem;
            font-family: $helvetica-thin;
            font-size: 1.1rem;
        }
        .pics {
            margin: 1rem auto 4rem;
            text-align: center;
            .member {
                margin: 0 auto;
                .name {
                    font-size: 1.25rem;
                }
                .title {
                    font-size: 1.05rem;
                }
                .subtitle {
                    font-family: $helvetica-light;
                    font-style: italic;
                    font-size: 1rem;
                }
                .img-wrapper {
                    @include actor-circle;
                    margin-bottom: 1rem;
                }
                img {
                    display: block;
                    width: 180px;
                    height: auto;
                    margin: 0 auto;
                    background: white;
                }
            }
        }
    }
}
