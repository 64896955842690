@import "~assets/styles/mixins.scss";
/**
 * DevExtreme style overrides
 */

@import './mixins';

/* stylelint-disable no-descending-specificity */

.dx-slider-wrapper {
    .dx-tooltip-wrapper .dx-overlay-content {
        background: unset !important;
        box-shadow: none !important;
        .dx-popover-arrow {
            display: none !important;
        }
    }
}
.dx-button.btn {
    &.dx-state-hover, &.dx-state-focused {
        background-color: unset;
        border-color: unset;
    }
    .dx-button-content {
        height: auto;
        padding: 0;
    }
}
.dx-button-has-icon .dx-icon {
    font-size: 0.8rem;
}
.dx-overlay-wrapper,
.dx-tooltip-wrapper,
.dx-overlay-content {
    z-index: 14010 !important; // has to overlay .dx-drawer
    .dx-toolbar-items-container {
        // switch OK and cancel
        .dx-toolbar-after {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 1rem 0.5rem 2rem 0;
        }
    }
    // visible popover arrow with material theme
    .dx-popover-arrow {
        display: block !important;
        &::after {
            background: white;
        }
    }
}
// tooltip content, restore dx own theme (material theme makes the tooltip transparent)
.dx-tooltip-wrapper .dx-overlay-content {
    background: white !important;
    border-radius: 4px;
    // box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12) !important;
    box-shadow: 0 11px 15px -7px rgba(5, 5, 0, 0.2), 0 12px 38px 5px rgba(5, 5, 0, 0.12) !important;
    .dx-template-wrapper > div {
        display: inline-flex;
    }
}
.dx-overlay-wrapper.dx-invalid-message {
    background: white;
}
.dx-state-disabled .dx-slider-handle .dx-tooltip-wrapper .dx-overlay-content::before {
    // material theme slider handle in disabled mode
    background-color: $medium-gray-2;
}
.dx-toast-content {
    padding: 1.5rem;
    font-family: $main-sans-serif;
    font-size: 1.75rem;
    .dx-toast-icon {
        width: 32px;
        height: 32px;
    }
    .dx-toast-message {
        padding-left: 1rem;
    }
}
.dx-popup-title.dx-toolbar {
    .dx-toolbar-items-container {
        height: 2rem;
    }
    .dx-toolbar-label {
        max-width: unset !important;
        font-family: 'Sabon LT W01 Bold', serif;
    }
    .dx-popup-content {
        padding-top: 0.75rem;
    }
}
.dx-context-menu-container-border {
    border: 0;
}
.dx-checkbox {
    line-height: normal;
}
.dx-checkbox-icon {
    background-color: transparent;
    border-color: black;
}
.dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text {
    padding-right: 25px;
}
.dx-submenu .dx-menu-item > .dx-menu-item-content .dx-menu-item-text {
    font-family: $main-sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
}
.dx-list-item, .dx-placeholder {
    font-family: 'Sabon MT W01 Regular', serif;
    font-size: 1rem;
}
.dx-popup-wrapper > .dx-overlay-content {
    // wizard popup helpers
    .dx-popup-content {
        height: auto !important;
    }
}
.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
    font-family: $main-sans-serif;
    font-size: 1rem;
    & > div {
        display: inline-flex;
        align-items: center;
    }
    .material-design-icon {
        align-self: center;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.25rem;
        padding: 0 0.5rem;
        &.alert-decagram-icon {
            margin-right: 1rem;
        }
    }
    .error {
        margin: 0.5rem 1rem 0.5rem 0;
    }
}
.dx-actionsheet-popover-wrapper {
    .dx-overlay-content {
        width: 50vw;
        min-width: 500px;
        @media screen and (max-width: $breakpoint-mobile - 1) {
            min-width: unset;
        }
    }
    .dx-toolbar-items-container {
        display: flex;
        justify-content: center;
        .dx-toolbar-before {
            position: relative;
        }
    }
    .dx-popup-content {
        .dx-button {
            font-family: $main-sans-serif;
            font-size: 1rem;
        }
    }
}
.dx-scrollable-scrollbar {
    .dx-scrollable-scroll-content {
        background-color: rgba(5, 5, 0, 0.8) !important;
    }
}
.dx-scheduler-header-panel-cell {
    font-family: "Sabon MT W01 Regular", serif;
    text-align: center !important;
    background-color: $medium-gray-2;
}
.dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
    height: 3.75rem !important;
    font-family: "Sabon MT W01 Regular", serif;
    font-size: 1.2rem !important;
    color: black;
    text-align: center !important;
    vertical-align: middle;
    background-color: $light-gray;
}
.dx-scheduler {
    .dx-scheduler-header {
        color: $dark-gray-2;
        border: 0;
        .dx-button,
        .dx-icon {
            color: inherit !important;
        }
    }
    .dx-scheduler-navigator {
        min-width: 220px;
        color: inherit;
        .dx-button {
            height: auto;
            border: 0;
        }
        .dx-button,
        .dx-icon {
            color: inherit !important;
        }
    }
}
.dx-invalid-message {
    font-family: $main-sans-serif;
    font-size: 1rem;
    background: transparent !important; // or else the input will be hidden
}
.dx-texteditor-container {
    font-family: 'Sabon MT W01 Regular', serif;
    font-size: 1rem;
    line-height: 1 !important;
    color: $dark-gray-2;
    text-align: start;
    .dx-texteditor-input {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        text-align: inherit;
    }
    .dx-placeholder {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        text-align: inherit;
    }
}
.dx-calendar-body {
    .dx-calendar-cell {
        text-align: center !important;
    }
}
.dx-calendar-navigator .dx-button .dx-button-text {
    color: black;
}
.dx-datebox-wrapper .dx-popup-bottom .dx-button {
    color: black;
    border: 1px solid black;
}
.dx-dropdowneditor-button-visible {
    input {
        width: 100% !important;
    }
}
.dx-filemanager-container {
    z-index: 10000; // .dx-filemanager-container < .vodal
}
