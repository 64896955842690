@import "~assets/styles/mixins.scss";

.home-artworks {
    .slick-slide {
        display: inline-block;
        float: none;
        vertical-align: middle;
        & > div {
            margin: 0.375rem; // margin for box-shadow
        }
    }
}
