@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.preview-ingress-image {
    margin-bottom: 1rem;
    h3 {
        font-size: 1rem;
    }
    .select-image {
        display: inline-block;
        margin: 1rem 0 1.25rem 0;
        cursor: pointer;
    }
    figure {
        width: 180px;
        height: auto;
    }
    .material-design-icon {
        width: 36px;
        height: 36px;
        color: black;
    }
    .spinner {
        justify-content: left;
        width: 45px;
        height: 45px;
        margin: 2rem 0 0;
    }
    .image-not-available {
        display: none;
    }
}
