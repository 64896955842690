@import "~assets/styles/mixins.scss";

// specific styles in components/Home/FrontPage
// @import '~assets/styles/variables';

#home {
    width: 100vw;
    margin: 0;
    padding: 0;

    .contents {
        width: 100%;
        margin: 0;
        padding: 0 $main-content-margin 1rem;
    }
}
