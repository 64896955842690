@import "~assets/styles/mixins.scss";

@import '../components/FeatureArticle/index-cards';

#articles-index {
    font-size: 1rem;
    .segment-header {
        margin-bottom: 2rem;
        .display-options {
            top: 20px;
            right: 1em;
            align-self: baseline;
        }
    }
    .segment-title {
        display: block;
        margin: 0 auto;
        text-align: center;
        h1, h2 {
            font-family: "Sabon MT W01 Regular", serif;
            font-size: 3.25rem;
            color: $dark-gray;
        }
        hr {
            display: block;
            float: none;
            margin: 0.75rem auto;
        }
    }
    .contents {
        margin-bottom: 1rem;
    }
    .no-results {
        text-align: center;
    }
    @include feature-article-index-cards;

    @media screen and (min-width: $breakpoint-tablet) {
        .segment-header .display-options {
            position: absolute;
            top: 45px;
            right: $main-content-margin + 16px; // container magic margin
        }
    }
}
