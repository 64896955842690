@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';
@import '../Event/index-cards';
@import '../FeatureArticle/index-cards';

.subject-items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: $main-content-max-width;
    margin: 0 auto 4rem;
    @media screen and (max-width: $breakpoint-tablet - 1) {
        max-width: $mobile-content-max-width;
    }
    h4.title {
        font-family: "Sabon LT W01 Italic", serif;
        font-weight: normal;
        font-size: 1.25rem;
        border-right: 0;
    }
    .card {
        margin: 0 1rem 1rem;
        // do not wrap card titles
        .card-header .card-header-title {
            h1, h2 {
                @include dowrap;
            }
        }
    }
    .subject-actors {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        order: 1;
        width: 100%;
        margin: 2rem auto;
        .actor-cards {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            .card-image {
                height: auto;
            }
            .artworks-counted {
                display: none;
            }
        }
    }
    .subject-artworks {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        order: 4;
        max-width: 95vw;
        margin: 2rem auto;
        .card {
            margin: 0;
        }
        @media screen and (max-width: $breakpoint-mobile - 1) {
            .pagination-wrapper .masonry-wrapper {
                margin: 0 auto !important;
            }
        }
    }
    .subject-organizations {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        order: 6;
        width: 100%;
        margin: 2rem auto;
        .organization-cards {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            width: auto;
            .card {
                width: auto !important; // override masonry calculated width
                min-width: 400px;
            }
            .card-image {
                width: auto !important;
                height: 400px;
                min-width: 400px;
            }
            .card:nth-child(1n) {
                flex-grow: 4;
            }
            .card:nth-child(2n) {
                flex-grow: 3;
            }
            .card:nth-child(3n) {
                flex-grow: 4;
            }
            .card:nth-child(5n) {
                flex-grow: 5;
            }
            @media screen and (max-width: $breakpoint-mobile - 1) {
                .card, .card-image {
                    min-width: 250px;
                }
            }
        }
    }
    .subject-events {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        order: 2;
        margin: 2rem auto;
        @include event-index-cards;
    }
    .subject-collections {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        order: 5;
        width: 100%;
        margin: 2rem auto;
        .collection-cards {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            // stylelint-disable-next-line no-descending-specificity
            .card {
                width: auto !important; // override masonry calculated width
            }
            .card-image {
                width: auto !important;
                height: 350px;
                min-width: 350px;
            }
            .card:nth-child(1n) {
                flex-grow: 4;
            }
            .card:nth-child(2n) {
                flex-grow: 2;
            }
            .card:nth-child(3n) {
                flex-grow: 3;
            }
            .card:nth-child(5n) {
                flex-grow: 5;
            }
            @media screen and (max-width: $breakpoint-mobile - 1) {
                .card {
                    max-width: 250px;
                }
                .card-image {
                    width: 100% !important;
                    min-width: 250px;
                }
            }
        }
    }
    .subject-articles {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        order: 3;
        width: 100%;
        margin: 2rem auto;
        .article-cards {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            @include feature-article-index-cards;
            .feature-article-card {
                display: inline-flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: flex-start;
            }
            .card-header {
                min-width: 25rem;
            }
            .card-image {
                width: auto !important;
                min-width: 50vw;
                .image {
                    min-height: 480px !important;
                }
            }
        }
    }
    .subject-videos,
    .subject-podcasts {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        order: 7;
        width: 100%;
        margin: 2rem auto;
        .video-cards, .audio-cards {
            display: inline-flex;
            flex-wrap: wrap;
            width: 100%;
            .video-card {
                flex-grow: 1;
                width: auto !important;
                min-width: 40vw;
                .card-image {
                    height: 350px;
                }
            }
            .audio-card {
                flex-grow: 1;
                width: auto !important;
                min-width: 30vw;
                .card-image {
                    height: 350px;
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-mobile - 1) {
        .subject-actors,
        .subject-events,
        .subject-artworks,
        .subject-collections,
        .subject-articles,
        .subject-videos,
        .subject-audios,
        .subject-organizations {
            max-width: 100%;
            margin: 2rem 0;
        }
    }
}
