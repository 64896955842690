@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#actors-index {
    .actor-query-options {
        margin-bottom: 2rem;
    }
    .option {
        margin: 0 1rem 0.5rem 0;
    }
    .other-actors {
        display: block;
        flex-wrap: wrap;
        .slick-track {
            display: flex;
            min-height: unset;
        }
        .slick-list {
            padding-left: 1rem;
        }
        .slick-dots {
            margin-bottom: 0;
        }
        .slick-dotted.slick-slider {
            margin-bottom: 50px;
        }
        .slick-slide {
            display: inline-flex;
            float: none;
            &.slick-active > div:last-child {
                margin: 0 auto;
            }
        }
        .card {
            align-items: center;
            margin: 0 auto;
            text-align: center;
            white-space: break;
            .card-header {
                margin: 1rem auto;
                h2 {
                    white-space: normal;
                }
                .card-header-title {
                    text-align: center;
                }
            }
        }
    }

    .artwork-spotlights {
        // both heigths are required!
        .card-image {
            height: 800px;
            .image {
                height: 800px;
            }
        }
    }
}
