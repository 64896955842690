@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-file-library-details {
    // height: 100%;
    .dx-filemanager {
        // height: clamp(500px, 90vh, 1200px); // dynamic height
        .dx-filemanager-thumbnails .dx-filemanager-thumbnails-item.dx-filemanager-item-selected {
            background: $blue;
        }
    }
    .browse-files {
        margin: 0.25rem 1rem;
        text-align: left;
    }
}
