@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.audio-playlist {
    .podcast {
        display: inline-block;
        width: 70%;
        min-width: 85vw;
        margin-bottom: 2rem;
        .audio-player {
            width: 100%;
        }
    }
    .podcast-title {
        display: flex;
        align-items: center;
        width: 100%;
        h2.title {
            display: inline-flex;
            width: 100%;
            overflow: initial;
        }
    }
}
