@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

section.page-text {
    margin-bottom: 1rem;
    h3 {
        font-family: 'Sabon MT W01 Regular', serif;
        font-weight: bold;
        font-size: 1.35rem;
    }
    .edit-access {
        display: flex;
        justify-content: space-between;
        h3 {
            align-self: center;
            font-family: $main-sans-serif;
            font-weight: normal;
            font-size: 1rem;
        }
        .flag {
            align-self: center;
            justify-self: right;
            width: 37px;
            height: 25px;
        }
    }
    .edit-toolbar {
        margin-top: 1rem;
        .is-loading {
            margin-top: 6px;
        }
    }
    .dx-htmleditor {
        flex-direction: column-reverse; // switch toolbar to bottom
        min-width: 550px;
        max-width: calc(100vw - 180px);
        border-color: transparent;
        @media screen and (max-width: $breakpoint-tablet - 1) {
            width: 100%;
            min-width: 70vw;
        }
        &.narrow {
            width: auto !important;
            min-width: 70vw;
        }
        &.dx-widget {
            font-family: inherit;
            font-size: inherit;
        }
        .dx-quill-container {
            border-bottom: 1px dotted $medium-gray;
        }
        .dx-toolbar .dx-toolbar-items-container {
            height: 46px;
        }
        .dx-toolbar-item {
            line-height: 42px;
            .dx-button {
                // selected button has background with border-radius, this needs to be 1:1
                width: 38px;
                height: 38px;
                margin-top: 5px;
                vertical-align: top;
            }
            .dx-htmleditor-toolbar-separator {
                height: 42px;
            }
            input.dx-texteditor-input[role="combobox"],
            .dx-placeholder::before {
                padding-top: 7px !important;
                padding-bottom: 7px !important;
                color: $dark-gray-2;
            }
            .dx-dropdowneditor .dx-placeholder {
                font-size: 18px;
            }
        }
        .dx-toolbar-item,
        .dx-toolbar-menu-container {
            vertical-align: top;
        }
    }
    .dx-htmleditor-toolbar-wrapper {
        border-bottom: 0 !important;
        transform: opacity;
        transition-duration: 1s;
        transition-timing-function: ease-out;
        //- very important to get icon size right to avoid "..." instead of the icon
        .dx-toolbar-text-auto-hide .dx-button .dx-button-content {
            padding-right: 0;
            padding-left: 0;
        }
        .dx-button .dx-icon {
            color: $dark-gray !important;
        }
        &.hidden {
            opacity: 0;
        }
    }
    .dx-textarea.dx-editor-outlined.dx-state-hover {
        border-color: $medium-gray-3;
    }
    .dx-textarea.dx-editor-outlined.dx-state-focused {
        border-color: $medium-gray-2;
    }
    .editing {
        width: 100%;
        .dx-widget,
        .dx-texteditor-container {
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
        }
    }
}
