@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.user-feature-article-table {
    table {
        width: 100%;
    }
    tr {
        user-select: none;
    }
    td, td > span {
        vertical-align: top;
    }
    .row-image {
        overflow: hidden;
        user-select: none;
    }
    .detail-container .row-details {
        display: flex;
        .row-image {
            flex-grow: 0;
            justify-self: left;
            width: auto;
            margin-right: 0.5rem;
        }
        .row-content {
            flex-grow: 3;
            padding: 0.5rem 0.75rem;
            .details-head {
                font-family: $main-sans-serif;
            }
            a {
                font-size: 1.25rem;
            }
            .article-details-content {
                height: 150px;
                max-width: 60rem;
                margin-top: 1rem;
                padding: 0.25rem 0.5rem;
                overflow-x: hidden;
                overflow-y: scroll;
                font-family: $helvetica-roman;
                background: linear-gradient(to bottom, white, white 75%, rgba(255, 251, 251, 0));
                // border: 2px inset $light-gray;
                // @include fade-read-more(150px);
            }
        }
        .row-actions {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 0;
            justify-self: right;
            margin-left: 0.5rem;
            & > * {
                margin-bottom: 0.5rem;
            }
        }
    }
    .publish-date > span {
        font-size: 1rem;
    }
    .material-design-icon {
        cursor: pointer;
        &.remove {
            color: $red;
        }
    }
    .article-is-derived.material-design-icon {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0.25em 0.5em;
        cursor: default;
    }
    .b-table {
        .level {
            margin-top: 1rem;
        }
        tr.disabled {
            cursor: progress !important;
            .material-design-icon {
                cursor: progress !important;
            }
        }
    }
    // hack for bulma chevron
    // https://brajeshwar.me/entities/
    .chevron-cell {
        .icon::after {
            color: black;
            content: "►";
            transform: scale(1.8);
        }
        .icon.is-expanded::after {
            content: "▼";
        }
    }
}
