@import "~assets/styles/mixins.scss";

@import '../Video/Card';

.audio-player {
    display: inline-flex;
    flex: 1 0 auto;
    flex-direction: column;
    // min-width must not exceed 300px!
    max-width: $main-content-max-width;
    margin: 0;
    .podcast-title {
        .duration {
            margin-bottom: 1.5rem;
        }
    }
    audio::-webkit-media-controls-panel { // HACK for chrome
        background: white;
    }
    .card-header-title {
        padding-left: 0;
    }
    .card-image {
        width: auto;
        height: 250px;
    }
    .social-button-wrapper {
        display: block;
    }
    .failure {
        display: flex;
        flex-direction: column;
        margin: auto;
        font-family: $main-sans-serif;
        // stylelint-disable-next-line no-descending-specificity
        .material-design-icon {
            width: 3rem;
            height: 3rem;
        }
    }

    @media screen and (max-width: $breakpoint-tablet - 1) {
        margin: 0;
    }
}
