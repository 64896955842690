@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

// most styles in app.scss
.sort-option {
    transition: transform 0.5s;
    transform-style: preserve-3d;
    &.state0 {
        transform: rotateX(0deg);
    }
    &.state1 {
        transform: rotateX(180deg);
        & > * {
            transform: scaleY(-1);
        }
    }
    &.state2 {
        transform: rotateX(360deg);
    }
}
