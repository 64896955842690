@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.organization-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 2rem !important;
    font-family: $helvetica-roman;
    font-size: 0.875rem;
    .column.article {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .body-html {
            margin-right: 3rem;
        }
        .video-player {
            min-height: 300px; // HACK
        }
        .video-details {
            max-width: 800px;
            margin-bottom: 1rem;
        }
        .audio-player .podcast-title .duration {
            margin-bottom: 0;
        }
    }
    .column.sidebar {
        display: flex;
        flex-basis: auto;
        max-width: 220px;
        margin-left: 2rem;
        color: black;
        border-color: black;
        .sidebar-contents {
            height: min-content;
            padding-left: 1rem;
            border-color: inherit;
            border-left: 1px solid black;
        }
        section {
            display: block;
            margin: 1rem 0;
        }
        h1 {
            margin-bottom: 1rem;
            font-family: $helvetica-bold;
            font-size: 1.15rem;
        }
        h3 {
            margin: 0;
            font-family: $helvetica-bold;
            font-size: 1rem;
        }
        .entry-information {
            display: inline-flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
        ul {
            margin-left: 10px;
            padding: 0;
            list-style-type: none;
        }
        hr {
            width: 80%;
            background-color: transparent;
            border-bottom: 1px gray dashed;
        }
        .map-link,
        .visitor-link {
            display: inline-flex;
            align-items: center;
            margin-top: 0.75rem;
            margin-bottom: 1rem;
            font-family: $helvetica-bold;
            cursor: pointer;
        }
        b {
            font-size: 1rem;
            color: $dark-gray;
            vertical-align: middle;
        }
        .public-address {
            margin-bottom: 1rem;
        }
        .telephone {
            display: flex;
            align-items: baseline;
            margin: 1rem 0;
            .label {
                margin: 0 1rem 0 0;
            }
        }
        .material-design-icon {
            width: 35px;
            height: 35px;
            margin-right: 1rem;
            color: $dark-gray;
        }
        .entry-information-opening-hours {
            .opening-closing-week-row {
                font-size: 0.875rem;
                td {
                    padding-left: 0 !important;
                }
            }
            .exceptions-header,
            .times-open-exceptions {
                display: none;
            }
        }
        .entry-information-ticket-prices {
            margin-top: 2rem;
            .ticket-prices-table {
                flex-direction: column;
                .ticket-price {
                    flex-direction: row;
                    margin-bottom: 1rem;
                    .price-amount {
                        display: inline-flex;
                        flex-shrink: 0;
                        flex-wrap: wrap;
                        align-items: center;
                        align-self: stretch;
                        order: 1;
                        width: 3.5rem;
                        padding: 0 0.5rem;
                        font-size: 1rem;
                        background: $light-gray;
                    }
                    .ticket-classes {
                        align-self: center;
                        order: 2;
                        margin-left: 0.5rem;
                    }
                }
            }
            .free-entry-card {
                .dx-checkbox {
                    display: none;
                }
                &.not-checked {
                    display: none;
                }
            }
        }
        .entry-information-opening-hours,
        .entry-information-ticket-prices {
            .segment-title {
                margin-bottom: 0;
                h2 {
                    font-family: $helvetica-medium;
                    font-size: 1rem;
                }
            }
        }
    }
    .column:first-child.sidebar {
        // without the first "Info column", style sidebar differently
        max-width: unset;
        margin-left: 0;
        .sidebar-contents {
            padding-left: 0;
            border: 0;
            .entry-information {
                flex-direction: row;
                align-items: flex-start;
                justify-items: space-evenly;
                & > div {
                    margin-top: 0;
                    margin-left: 7vw;
                    &:nth-child(1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-widescreen - 1) {
        .column.article {
            flex-wrap: wrap;
            .organization-videos {
                margin: 1rem 0;
            }
        }
    }
    @media screen and (max-width: $breakpoint-desktop - 1) {
        .column.article {
            .body-html {
                margin-right: 1rem;
            }
        }
    }
    @media screen and (max-width: $breakpoint-tablet - 1) {
        display: inline-flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        .column.article {
            display: inline-block;
            max-width: 80vw;
        }
        .column.sidebar {
            margin-left: 0;
            .sidebar-contents {
                padding-left: 0;
                border-left: 0;
                .entry-information {
                    display: inline-flex;
                    flex-direction: row;
                }
            }
        }
    }
}
