@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.social.do-follow {
    height: 30px;
    min-width: 120px;
    padding: 0 1.5rem;
    font-size: 0.8rem;
    border-color: black;
    border-width: 1px;
    &.active {
        color: white;
        background-color: black;
    }
    &:hover {
        font-weight: bold;
        color: black;
        background: rgba(240, 240, 240, 0.45);
        &.active {
            color: white;
            background-color: black;
        }
    }
}
