@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.video-playlist-slides {
    width: 100%;
    height: auto;
    min-width: $mobile-absolute-max-width;
    margin-bottom: 1.5rem;
    .slick-slider {
        // max-width: 450px;
        margin-right: $main-content-margin;
        .slick-track {
            width: 100%;
            min-height: unset;
        }
        .slick-prev {
            top: 200px;
            left: 15px;
        }
        .slick-next {
            top: 200px;
            right: 15px;
        }
        .slick-prev::before,
        .slick-next::before {
            color: white;
        }
        .slick-slide {
            overflow: hidden;
            & > div > div {
                display: block !important;
                width: auto !important;
            }
        }
    }

    @media screen and (max-width: $breakpoint-tablet - 1) {
        .slick-slider {
            max-width: unset !important;
            margin-right: 0;
        }
    }
}
