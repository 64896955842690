@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.error-page {
    .segment.slides {
        margin: 2rem $main-content-margin;
    }
    .multi-stack {
        .stacking-modes-toolbar {
            position: relative;
            justify-content: flex-end;
            width: 100%;
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }
}
