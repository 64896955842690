/**
 * Common SCSS variables.
 * Color names are based on http://chir.ag/projects/name-that-color/
 **/

/* stylelint-disable color-no-hex */

$light-gray-3:  #fffbfb; // rose white -- rgb(255, 251, 251)
$light-gray-2:  #f3f3f3; // concrete
$light-gray:    #efefef; // gallery
$medium-gray-3: #d4d4d4; // alto
$medium-gray-2: #b9b9b9; // silver
$medium-gray:   #9d9d9d; // silver chalice
$dark-gray-4:   #757575; // boulder
$dark-gray-3:   #5f5f5f; // scorpion
$dark-gray-2:   #363636; // mine shaft
$dark-gray:     #141414; // cod gray
$dark-black:    #050500; // nero -- rgba(5, 5, 0)

$pastel-green:  #75d95c; // pastel green - PUBLIC PROFILE
$green:         #8bc34a; // sushi
$yellow:        #ffc107; // amber
$red:           #ea2222; // alizarin crimson
$blue:          #337ab7; // astral
$havelock:      #5c7ad9; // havelock blue - EDIT MODE
$light-purple:  #9c59ac; // wisteria - LINKS - MAIN ACCENT COLOR
$purple:        #531f57; // grape

$success:       $green;
$warning:       $yellow;
$error:         $red;

$main-serif:        'Sabon Next W01 Regular', serif;
$main-sans-serif:   'Helvetica Neue LT W01_55 Roman', sans-serif;

$helvetica-roman:   'Helvetica Neue LT W01_55 Roman', sans-serif;
$helvetica-bold:    'Helvetica Neue LT W01_71488914', sans-serif;
$helvetica-italic:  'Helvetica Neue LT W01_56 It', sans-serif;
$helvetica-medium:  'Helvetica Neue LT W01_65 Md', sans-serif;
$helvetica-light:   'Helvetica Neue LT W01_41488878', sans-serif;
$helvetica-thin:    'Helvetica Neue LT W01_35 Thin', sans-serif;

$sabon-roman:       'Sabon Next W01 Regular', serif;
$sabon-bold:        'Sabon Next W01 Bold', serif;

// Bulma + custom breakpoints
$breakpoint-mobile-xs: 400px;       // custom
$breakpoint-mobile: 576px;          // custom
$breakpoint-tablet: 769px;          // Bulma; from 769px
$breakpoint-desktop: 1024px;        // Bulma; from 1024px
$breakpoint-widescreen: 1216px;     // Bulma; from 1216px
$breakpoint-fullhd: 1408px;         // Bulma; from 1408px
$breakpoint-fullhd-xl: 1600px;      // custom
$breakpoint-fullhd-xxl: 1900px;     // custom


$main-content-max-width: calc(100vw - 105px);
$main-content-margin: 45px;
$mobile-content-margin: 16px;
$mobile-content-max-width: calc(100vw - 45px);
$mobile-absolute-max-width: 288px; // 320px (min. mobile screen width) - 2*16px margin
$navigation-min-height: 45px;


// Sabon on ArticlePage
$article-title-font-serif: 'Sabon Next W01 Bold', serif;
// Helvetica on ArticlesIndex
$article-title-font-sans-serif: 'Helvetica Neue LT W01_71488914', sans-serif;
