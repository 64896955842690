@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.artwork-query-options {
    margin: 0;
    padding: 0;
    font-family: $main-serif;
    .search-input-text {
        width: 100%;
        min-width: $mobile-absolute-max-width;
        max-width: 600px;
    }
    .more-options, .feeling-lucky {
        display: inline-flex;
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
        margin: 0 0 auto 1rem;
        vertical-align: top;
        .btn {
            @extend .btn;
            display: block;
            max-width: auto;
            margin: 0.1rem 0 0 auto;
            padding: 0.25rem 0.5rem 0.2rem 1rem;
            color: black;
            border: 1px solid black;
            // border-radius: 2px;
            // background: linear-gradient(to left top, #000 0%, #222 30%, #333 50%, #010 70%, #000 100%);
            // &:hover {
            //     box-shadow: 0 2px 2px rgba(0,0,0,0.42), 0 2px 2px rgba(0, 0, 0, 0.84);
            //     background: linear-gradient(to left top, #000 0%, #2f2f2f 30%, #4f4f4f 50%, #1f1f1f 70%, #000 100%);
            // }
            .label {
                display: inline-block;
                margin: auto 0;
                font-family: $helvetica-medium;
                font-weight: normal;
                letter-spacing: 1px;
                color: inherit;
                vertical-align: middle;
            }
            .material-design-icon {
                width: 2.5em;
                height: 2.5em;
                margin-left: 1rem;
                color: $dark-gray-4;
                vertical-align: middle;
            }
        }
        @media screen and (max-width: $breakpoint-tablet - 1) {
            margin: auto;
            // hide the "refine search" and "feeling lucky" text on small displays
            .label {
                display: none !important;
            }
            .material-design-icon {
                margin: 0 1rem;
            }
        }
    }
    .more-options {
        margin-right: 1rem;
    }
    .active-options {
        display: block;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .active-option {
            display: inline-flex;
            margin: 0 1rem 0.5rem 0;
            padding: 0.25rem 0.5rem;
            font-family: $main-sans-serif;
            font-size: 1rem;
            color: $medium-gray-3;
            vertical-align: middle;
            cursor: pointer;
            background: rgba(220, 220, 220, 0.4);
            .material-design-icon {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }
    div.panel-dock {
        .info-panel {
            p {
                margin-top: 1rem;
                font-family: $main-sans-serif;
            }
        }
        // stylelint-disable-next-line no-descending-specificity
        .material-design-icon {
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            margin-left: 0;
            vertical-align: middle;
        }
        .segment-title h2 {
            font-size: 2rem;
        }
        // lift guide close button
        .panel:last-child {
            a.action-close {
                margin-bottom: 1.5rem;
            }
        }
    }
    .filter-options {
        // allow room for scroll view
        padding-bottom: 4rem;
    }
    .segment {
        user-select: none;
        .segment-title {
            width: auto;
        }
        &.colour {
            .chart-wrapper {
                justify-content: center;
                & > div {
                    margin: auto;
                }
            }
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        .panel-dock {
            .navbar-item .label {
                font-size: 0.875rem;
            }
            .segment .segment-title h2 {
                font-size: 1.25rem;
            }
        }
    }
}
