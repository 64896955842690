@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.event-page-header {
    .ingress-image {
        display: block;
        max-width: unset;
        margin-right: -5 - $main-content-margin;
        margin-left: 0 - $main-content-margin;
        padding: 2 * $main-content-margin;
        cursor: pointer;
        background-size: cover;
        background-position: 50% 33%; // default; FocusPoint will override
        .select-image {
            float: right;
        }
    }
    .header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-right: - $main-content-margin;
        margin-left: - $main-content-margin;
        padding: 0 $main-content-margin;
        font-family: $main-sans-serif;
        color: $dark-gray;
        background-color: rgba(255, 255, 255, 0.5);
        @media screen and (max-width: $breakpoint-mobile - 1) {
            flex-direction: column;
        }
        h1 {
            font-size: 26px;
        }
        .locality-area {
            &, .dx-texteditor-container {
                margin-top: 0.5rem;
                font-family: $helvetica-thin;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
        hr {
            margin: 10px 0 2rem;
        }
        .outline {
            display: block;
            min-width: unset;
            margin: 0;
            padding: 0.5rem 2rem 0 0;
            .event-name {
                &, .dx-texteditor-container {
                    margin-bottom: 2rem;
                    font-family: "Sabon LT W01 Bold", serif;
                    font-weight: normal;
                    font-size: 1.875rem;
                    textarea {
                        width: 99%;
                        margin-bottom: 12px;
                        overflow: auto;
                        line-height: 1;
                    }
                }
                .dx-widget {
                    font-size: inherit !important;
                }
            }
            .event-organizer {
                display: inline-flex;
                flex-direction: column;
                align-items: baseline;
                font-family: $helvetica-light;
                font-size: 1.5rem;
                .label {
                    letter-spacing: 1px;
                }
            }
            .event-period {
                h2 {
                    font-family: $helvetica-roman;
                    font-family: $helvetica-light;
                    font-size: 1.5rem;
                }
                &.row {
                    display: flex;
                    .period-dash {
                        margin: auto 1rem;
                    }
                    .period-end {
                        margin-right: 1rem;
                    }
                }
                .lasting-event-not-periodical {
                    display: inline-flex;
                    align-items: center;
                    .label {
                        margin-left: 0.5rem;
                    }
                }
            }
            .event-classes {
                margin-bottom: 1rem;
            }
            .ingress-text {
                &, .dx-texteditor-container {
                    height: auto;
                    margin: 0 2rem 1rem 0;
                    font-family: 'Sabon LT W01 Regular', serif;
                    font-weight: normal;
                    font-size: 1.25rem !important;
                }
            }
            .social-actions {
                display: flex;
                align-items: center;
                .do-follow {
                    margin-right: 1rem;
                }
                .social-network-share {
                    margin-bottom: 0;
                }
            }
        }
        .event-venue {
            display: block;
            margin-left: auto;
            padding: 0 2rem;
            border-left: 1px solid black;
            .venue-name {
                font-family: $helvetica-bold;
                font-size: 18px;
            }
            p {
                font-family: $helvetica-light;
                font-size: 14px;
            }
        }
        .contact {
            z-index: 1000;
            font-size: 1rem;
        }
        @media screen and (min-width: $breakpoint-fullhd-xl) {
            .banner {
                min-height: unset !important;
            }
        }
    }

    .associated-artist-infos {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        margin: 2rem 0 0;
        text-align: center;
        // background: radial-gradient(circle at center, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0) 80%);
        h4.title {
            font-family: "Sabon LT W01 Italic", serif;
            font-weight: normal;
            font-size: 1.25rem !important;
            text-align: left;
            border-right: 0;
        }
        .artist-info-cards {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0.5rem 0.25rem;
        }
        .actor-card {
            display: flex !important;
            flex-direction: row;
            flex-grow: 0;
            flex-shrink: 0;
            margin: 0 0 1.5rem 2rem;
            .card-image {
                display: inline-flex;
                flex-grow: 1;
                flex-shrink: 2;
                min-width: 85px;
                .image {
                    width: 85px;
                    height: 85px;
                }
            }
            .card-header {
                display: inline-flex;
                flex-grow: 1;
                flex-shrink: 1;
                align-self: center;
                text-align: center;
                .card-link {
                    align-self: baseline;
                }
                .title {
                    margin-left: 0.75rem;
                    font-size: 1.1rem;
                    cursor: pointer; // actor name is a link
                    border-right: 0;
                }
            }
            .artworks-counted, .lifetime {
                display: none;
            }
        }
    }
}
