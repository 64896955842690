@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.last-modified {
    margin: 1rem 0;
    font-family: $helvetica-medium;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0;
    color: $dark-gray-4;
    vertical-align: middle;

    .editors {
        display: flex;
        align-items: center;
        .icon-taide-art {
            margin-right: 1rem;
        }
    }

    .add-editor {
        display: inline-flex;
        margin: 1rem auto 0 0;
        cursor: pointer;
        .label {
            // similar label with artwork-wizard "add contributor / maker"
            margin-left: 0.5rem;
            font-family: $helvetica-thin;
            font-weight: 700;
            font-size: 1rem;
            text-transform: lowercase;
            user-select: none;
        }
    }

    .editors-edit {
        display: table;
        border-spacing: 5px;
        border-collapse: separate;
        .row {
            display: table-row;
            align-items: center;
            justify-content: space-between;
            & > * {
                display: table-cell;
                padding: 2px;
            }
            &.labels {
                margin-right: 0;
                margin-left: auto;
                padding: 0;
                font-family: $helvetica-thin;
                .label {
                    font-size: 0.7rem;
                }
            }
            .dx-checkbox {
                vertical-align: middle;
                .dx-checkbox-container {
                    display: inherit;
                }
            }
            .remove-editor.material-design-icon > * {
                bottom: 11px;
                width: 16px;
                height: 16px;
                cursor: pointer;
            }
        }
    }
}
