@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-file-libraries {
    .card-header-title {
        display: none !important;
    }
    .silhouette-assistant {
        .assistant-message {
            flex-direction: column;
            p {
                margin: 1.5rem auto;
                color: $dark-gray-2;
            }
        }
    }
    .add-files {
        margin: 0 0 1rem auto;
    }
    .file-library {
        margin: 0 0 2rem;
        // height: clamp(500px, 90vh, 1200px); // dynamic height
    }
    .accordion-title-toolbar {
        min-height: 0;
        margin-bottom: 0;
        padding: 0.5rem 0.5rem 0;
    }
}
