$font-path: '~assets/fonts/';

// fonts.com tracking stylesheet (empty contents) in @/plugins/trackers
// @import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=7edd37f3-01d9-4a1a-8240-1f58dbf86e7e");


@font-face {
    font-family: "Helvetica Neue LT W01_35 Thin";
    src:url($font-path + "4ff9f3fa-9221-4fc5-97e6-93572b6efa24.woff2") format("woff2"),url($font-path + "ca8d25cd-e264-4404-b271-4afc45c779c4.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Helvetica Neue LT W01_41488878";
    src:url($font-path + "3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"),url($font-path + "50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Helvetica Neue LT W01_55 Roman";
    src:url($font-path + "3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),url($font-path + "34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Helvetica Neue LT W01_56 It";
    src:url($font-path + "21c44514-f4d6-4cff-a5de-e4cac5e61aff.woff2") format("woff2"),url($font-path + "e7c4b231-76ad-47c7-a54b-5d84dcd78d0f.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Helvetica Neue LT W01_65 Md";
    src:url($font-path + "5b1fbd62-45dc-4433-a7df-a2b24a146411.woff2") format("woff2"),url($font-path + "050b1948-f226-4d20-a65a-15d8ed031222.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Helvetica Neue LT W01_71488914";
    src:url($font-path + "531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"),url($font-path + "439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Helvetica Neue LT W01_76 Bd It";
    src:url($font-path + "ade4dbae-c607-4c84-a375-f0c4de0cc357.woff2") format("woff2"),url($font-path + "5b864741-6f14-4ed9-a297-27a4d73edf51.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Sabon Next W01 Regular";
    src:url($font-path + "d913b226-c0d7-49e2-b231-1fc570fcdc9a.woff2") format("woff2"),url($font-path + "9bbfbdbd-1ab0-4d8f-80d8-c690ab217070.woff") format("woff");
    font-display: swap;
}
// @font-face {
//     font-family: "Sabon Next W01 Italic";
//     src:url($font-path + "665097c7-8110-499e-b4db-12cf6081477b.woff2") format("woff2"),url($font-path + "a1a8e1e5-7387-45ce-96bb-70af11ca66c0.woff") format("woff");
//     font-display: swap;
// }
@font-face {
    font-family: "Sabon Next W01 Bold";
    src:url($font-path + "533ce8a9-a0a4-4a40-96c5-76aa564a3d7f.woff2") format("woff2"),url($font-path + "952c2825-2ba9-448a-8375-492e78886fc9.woff") format("woff");
    font-display: swap;
}
// @font-face {
//     font-family: "SabonNextW01-BoldItalic";
//     src:url($font-path + "a868a4e8-5f86-4ab4-91d6-af840757cc63.woff2") format("woff2"),url($font-path + "e5fcd2e6-2ba1-4d60-98ec-11a62cfeb8f4.woff") format("woff");
//     font-display: swap;
// }
@font-face {
    font-family: "Sabon LT W01 Regular";
    src:url($font-path + "c03ab202-0421-440a-a076-114c4d0f1db2.woff2") format("woff2"),url($font-path + "d4fc221d-b8b1-4903-8319-0c33b6309574.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Sabon LT W01 Italic";
    src:url($font-path + "4e92805e-7a27-40fe-8fc0-f5808636f7e7.woff2") format("woff2"),url($font-path + "56ec1e2d-8bae-49c9-b161-be6c87a22289.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Sabon LT W01 Bold";
    src:url($font-path + "46492dd5-2051-4e08-8d5f-138e48d0e05d.woff2") format("woff2"),url($font-path + "bba74e26-4e61-4e32-b876-02ca7b9beb79.woff") format("woff");
    font-display: swap;
}
// @font-face {
//     font-family: "Sabon LT W01 Bold Italic";
//     src:url($font-path + "8ec69368-520b-49f2-bf43-4b60c37015cd.woff2") format("woff2"),url($font-path + "a93f8bfd-16d7-43cc-a494-9f50879d0ccd.woff") format("woff");
//     font-display: swap;
// }
@font-face {
    font-family: "Sabon MT W01 Regular";
    src:url($font-path + "e9acb407-e453-4080-9d23-7addc8c8eff1.woff2") format("woff2"),url($font-path + "c11ac310-5d09-4128-b19d-3a013a4cbefe.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Sabon MT W01 Italic";
    src:url($font-path + "656b7c40-401d-4bb0-a9b9-f5c67b149521.woff2") format("woff2"),url($font-path + "405c9f2f-bc48-4bb0-ac2b-5c03e98157b5.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Sabon MT W01 Semibold";
    src:url($font-path + "eb90c654-e368-47de-8004-f5d0b25566af.woff2") format("woff2"),url($font-path + "526a2a7b-0724-4f12-8917-37ed3ea846bb.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "Sabon MT W01 Semibold Italic";
    src:url($font-path + "8200805f-ca02-408f-b672-479193ee22aa.woff2") format("woff2"),url($font-path + "20c5e46d-0ef2-47ee-9781-4beafe5cd9d1.woff") format("woff");
    font-display: swap;
}

