@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.add-article-part {
    .material-design-icon {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
}

#article-page {
    text-align: center;
    .article-name {
        width: 100%;
        margin: 40px auto 30px;
        padding: 0 1rem;
        line-height: 1.35 !important;
        &, .dx-texteditor-container {
            font-family: $article-title-font-serif;
            font-weight: normal;
            font-size: 2.25rem;
            color: black;
            text-align: center;
            @media screen and (max-width: $breakpoint-mobile) {
                width: 100%;
            }
            .dx-placeholder {
                width: 100%;
                margin: auto;
            }
            textarea {
                width: 99%;
                margin-bottom: 12px;
                overflow: auto;
                line-height: 1;
            }
        }
    }
    .publish-info {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: 0.5rem auto;
        font-family: $helvetica-roman;
        font-size: 1rem;
        text-align: center;
        span.label {
            display: inline;
            margin: 0 0.5rem;
            font-weight: normal;
            color: inherit;
            text-transform: lowercase;
        }
        .author span.label {
            text-transform: none;
        }
        .dx-widget,
        .dx-texteditor-container {
            font-family: inherit;
            font-size: inherit;
            color: inherit;
        }
        .dx-radiogroup,
        .dx-selectbox {
            display: inline-flex;
            margin: 0.5rem;
        }
        .dx-selectbox {
            .dx-item-content::first-letter {
                text-transform: lowercase;
            }
        }
        .author-row {
            display: inline-flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: center;
        }
        .author {
            width: 20em; // leave some space for long names and email addrs
            .page-text {
                width: 100%;
            }
        }
        .author, .publish-date {
            display: inline-flex;
            align-items: baseline;
            justify-content: center;
            margin: 0.5rem;
            &, .dx-texteditor-container {
                font-family: $helvetica-roman;
                font-weight: normal;
                font-size: 1rem;
            }
        }
        .hide-author {
            display: inline-flex;
            flex-direction: row-reverse;
            align-items: baseline;
            margin: 0.5rem;
            span.label {
                font-family: $helvetica-italic;
            }
        }
        .ingress-text {
            width: 60%;
            min-width: 400px;
            @media screen and (max-width: $breakpoint-mobile - 1) {
                min-width: 80vw;
            }
            margin: 2rem auto 1rem;
            font-size: 1.5rem;
            text-align: center;
            .dx-texteditor-container {
                font-size: 1.5rem;
            }
        }
    }
    .social-actions {
        display: flex;
        align-items: center;
        align-self: center;
        .do-share {
            margin: 0;
        }
        .social-network-share {
            margin: 0.25rem auto 0 1rem;
        }
    }
    article {
        flex-direction: column;
    }
    .ingress-image {
        width: 68vw;
        height: 40vw;
        margin: 1.5rem auto;
        figure {
            text-align: right; //- for select-image
        }
        .image {
            background-size: cover;
        }
        .select-image {
            position: relative;
            top: -116px;
            right: 0;
        }
    }
    .video-playlist-slides {
        min-width: 600px;
        margin: 1rem auto;
        @media screen and (max-width: $breakpoint-mobile - 1) {
            min-width: unset;
        }
        .social.do-save {
            display: none;
        }
    }
    .actions {
        display: flex;
        justify-content: center;
        .btn {
            margin: 1rem 0.5rem;
        }
    }
    .bottom-icons {
        margin: 2rem auto 1rem;
        .material-design-icon {
            margin: auto 0.5rem;
            cursor: not-allowed;
        }
    }
    .back {
        margin: 1rem auto;
        font-family: $helvetica-bold;
        font-size: 14px;
        a {
            color: inherit;
        }
    }
}
