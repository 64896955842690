@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

figure.image-box {
    z-index: 4000;
    flex: 1;
    width: inherit;
    height: 100%;
    min-width: inherit;
    max-width: inherit;
    min-height: 45px;
    max-height: inherit;
    user-select: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    .background-palette {
        position: absolute;
        z-index: 0;
        width: inherit;
        height: inherit;
        background-size: 200% !important;
        border: 0;
        animation: Gradient 15s ease infinite;
        .loading {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            font-family: $helvetica-light;
            font-size: 1rem;
            // the background gradient can be nearly black or white
            color: white;
            text-shadow: 1px 1px 0 rgba(5, 5, 0, 0.5);
        }
    }
    .image {
        z-index: inherit;
 //       width: inherit;
        height: 100%;
        min-width: inherit;
        max-width: inherit;
        min-height: inherit;
        max-height: inherit;
        margin: 0;
        padding: 0;
        background-repeat: inherit;
        background-size: inherit;
        background-position: inherit;
    }
    .image-not-available {
        overflow: hidden;
        // stylelint-disable-next-line color-no-hex
        background-color: #efefef;
        background-repeat: no-repeat;
        background-size: 140px;
        background-position: 50% 50%;
        background-blend-mode: color-burn;
        .image {
            background-repeat: no-repeat;
            background-size: 140px;
            background-position: 50% 50%;
            background-blend-mode: color;
            &:hover {
                background-color: unset;
            }
        }
    }
    .image-subtext {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        font-family: $helvetica-roman;
        cursor: auto;
        section.page-text {
            display: inline-flex;
            margin-bottom: 0;
        }
        p {
            margin: 0 !important;
        }
        .image-caption {
            flex-grow: 2;
            justify-self: flex-start;
            margin-right: 1rem;
            font-size: 1rem;
            text-align: left;
            &, .dx-texteditor-container {
                font-family: $helvetica-medium;
            }
        }
        .image-attribution {
            display: inline-flex;
            align-items: baseline;
            justify-self: flex-end;
            max-width: 100%;
            margin-top: 0.25rem;
            margin-left: auto;
            overflow: visible;
            line-height: auto;
            text-align: right;
            .image-credit-line,
            .image-copyright,
            .license-info {
                display: inline-flex;
                width: max-content;
                line-height: auto;
                &, .dx-texteditor-container {
                    justify-content: flex-end;
                    margin: 0 0 0 0.5rem;
                    font-family: $helvetica-roman;
                    font-size: 0.875rem;
                    text-align: right;
                }
                p {
                    margin-bottom: 0;
                }
            }
            .image-copyright {
                order: 1;
                @include nowrap;
            }
            .image-credit-line {
                order: 2;
                @include nowrap;
            }
            .license-info {
                order: 3;
                font-family: $helvetica-italic;
            }
        }
    }
    .social-button-wrapper {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        opacity: 0;
        transition: opacity 0.5s ease-out;
    }
    &:hover .social-button-wrapper {
        opacity: 1; // show
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
