@import "~assets/styles/mixins.scss";

@import '../FeatureArticle/index-cards';

.actor-articles {
    .feature-articles {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        @include feature-article-index-cards;
        .feature-article-card {
            display: inline-flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: flex-start;
        }
        .card-header {
            min-width: 25rem;
        }
        .card-image {
            width: auto !important;
            min-width: 50vw;
            .image {
                min-height: 480px !important;
            }
        }
    }
    .section-toolbar {
        margin-top: -4rem;
    }
}
