@import "~assets/styles/mixins.scss";

.modal-upload-batch-images {
    &.modal-dialog {
        .o-modal__content {
            display: inline-flex;
            flex-direction: column;
            width: 50rem;
            max-width: 900px;
            min-height: 650px;
        }
        .uppy-Dashboard-inner {
            background: transparent !important;
            border: 0 !important;
        }
        .modal-footer {
            display: inline-flex;
            justify-content: flex-end;
            margin: 1rem 0 0 auto;
        }
        .btn {
            margin: auto 1rem auto 0;
        }
    }
}
