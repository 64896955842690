@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.agile__list {
    margin-right: 0;
    margin-left: -1.1 * $main-content-margin;
}
.agile__track {
    height: auto; // this might be dangerous but important for some views!
    min-height: 360px;
    @media screen and (max-width: $breakpoint-mobile - 1) {
        min-height: 200px;
    }
}
.agile__slide {
    margin: 0;
    margin-right: 0.5rem;
    // hidden large slides should not take too much space
    transition: ease-out;
    transition-duration: 2750ms;
    transition-delay: 75ms;
    &.slick-active {
        height: auto;
        max-height: 200vh;
    }
    &.slick-active > div:last-child {
        margin-right: 0;
    }
    .card-image figure {
        height: inherit;
    }
    img {
        width: inherit;
        height: inherit;
        max-width: 100%;
        min-height: inherit;
        max-height: inherit;
    }
}
.agile__actions {
    position: absolute;
    width: 100%;
    margin-left: -1.1 * $main-content-margin;
    .agile__nav-button {
        position: absolute;
        bottom: 240px;
        z-index: 150;
        cursor: pointer;
        background: transparent;
        border: none;
    }
    .agile__nav-button--prev {
        left: 38px;
    }
    .agile__nav-button--next {
        right: -45px;
    }
    .agile__dots {
        justify-content: space-evenly;
        width: 145px;
        margin: auto;
    }
    .agile__dot {
        height: 12px;
    }
    .material-design-icon {
        width: 45px;
        height: 45px;
    }
}

/**
 * Responsive card with social button inside the header
 */
@media screen and (max-width: $breakpoint-desktop - 1) {
    .slick-track {
        .card.card-social-header {
            .card-header-title {
                max-width: unset;
            }
            .card-link {
                flex-direction: column !important;
                .social-button-wrapper {
                    margin: 0.5rem 0;
                    margin-left: auto;
                }
            }
        }
    }
}
