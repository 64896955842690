@import "~assets/styles/mixins.scss";

@import '../Event/index-cards';

.home-events {
    @include event-index-cards;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 25vh;
    background-color: $purple;

    // color override due to background color
    .show-all .label {
        color: white;
    }
    .segment-header {
        .segment-title {
            color: $light-gray !important;
            hr {
                background-color: white !important;
            }
        }
    }
    .plus-circle-outline-icon {
        color: $medium-gray-3 !important;
    }
    .slick-prev::before,
    .slick-next::before {
        color: white !important;
    }
    .card {
        .card-link, .card-header-title, .event-period {
            color: $light-gray !important;
        }
    }
    .card:hover {
        box-shadow: none;
    }

    .segment-events {
        .slick-slide {
            display: inline-block;
            float: none;
            vertical-align: top;
            .card-image .card-link,
            .card-image figure {
                min-height: inherit;
            }
        }
    }
}
