@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';
@import 'cropperjs/dist/cropper.min.css';

.image-cropper {
    display: block;
    max-height: 70vh;
    overflow: visible;
    .cropper {
        /* Ensure the size of the image fit the container perfectly */
        img {
            display: block;

            /* This rule is very important, please don't ignore this */
            max-width: 100%;
        }
    }

    &.loading {
        overflow: hidden;
        background: gray;
        .cropper {
            opacity: 0;
            img {
                opacity: 0;
            }
        }
    }
}
