@import "~assets/styles/mixins.scss";

.modal-dialog {
    z-index: 12500; // navbar < .modal-dialog <= .dx-overlay-wrapper
    .o-modal__overlay {
        background-color: rgba(10, 10, 10, 0.75);
    }
    .o-modal__content {
        min-width: 69vw;
        overflow: hidden; // no scroll
        box-shadow: 2px 2px 25px $dark-gray;
    }
    button.btn, .btn {
        margin: 0.5rem 1rem;
        border-color: black;
    }
    p {
        margin: 1rem 2rem;
        font-size: 1.2rem;
    }
    @media screen and (max-width: $breakpoint-mobile) {
        & {
            top: 20px;
            width: 95vw !important;
            height: 95vh !important;
        }
    }
}
