@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.panel-dock {
    z-index: 12250 !important; // has to fall between navbar (11999) < Z < modal (12500)
    box-shadow: none;
    section.panel.default {
        padding-right: 0.25em;
        padding-bottom: 4.75em; // adjust space for buttons
        .action-close, .action-extra {
            color: black;
        }
        & > div {
            height: 100%;
            // overflow is handled by dx-scroll-view
            overflow: hidden;
        }
    }
    .panel-title {
        display: inline-flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0.25em;
        padding: 0.5rem 1rem !important;
        color: white;
        user-select: none;
        background: black;
        .material-design-icon {
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            margin: auto 1rem auto 0;
            vertical-align: middle;
        }
        .label {
            margin: auto 0;
            font-family: $helvetica-medium;
            font-size: 1rem;
            line-height: 1.5;
            color: inherit;
            text-transform: uppercase;
        }
    }
}
