@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.social.do-heart {
    height: 38px;
    &.round {
        width: 40px;
        height: 40px;
        border-radius: 2rem;
        img {
            width: 40px;
            padding: 8px;
        }
        .material-design-icon {
            width: 24px;
            height: 24px;
        }
        &:not(.active) .material-design-icon {
            // stylelint-disable-next-line color-no-hex
            color: #141414;
        }
        .is-loading.button {
            width: auto;
            border-style: none;
        }
    }
    &.transparent {
        background: rgba(255, 251, 251, 0.15);
        &:hover {
            background: rgba(255, 251, 251, 0.25);
        }
    }
}
