@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-followed {
    margin-top: 1em;

    .followed-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .segment {
            width: auto;
        }
        .column {
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;
        }
        .column:first-child {
            margin-right: 2em;
        }
        .top-news {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 2;
            width: auto;
            min-width: 50vw;
            max-width: 60vw;
            margin: 0;
            .plus-more {
                margin: 2rem auto 1rem;
            }
        }
        .most-recently-followed {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            align-self: flex-end;
            justify-items: flex-start;
            .card {
                max-width: max-content; // NOTE: experimental width style, if good consider setting for all .cards
                margin: 0 2rem 2rem 0;
                .multimedia-title {
                    flex-direction: column-reverse;
                    .event-class-title-icon {
                        min-width: unset;
                        margin-left: 0;
                    }
                }
                &.actor-card {
                    width: 300px;
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-desktop - 1) {
        .followed-header {
            flex-direction: column;
            flex-wrap: wrap;
            .column:first-child {
                margin-right: 0;
            }
            .top-news {
                max-width: 90vw;
            }
            .most-recently-followed {
                flex-direction: row;
            }
        }
    }

    .followed-section {
        display: flex;
        flex-direction: column;
        .display-option.show-all {
            display: block;
            margin: 1rem auto 2rem;
            cursor: pointer;
            .material-design-icon {
                width: 1em !important;
                height: 1em !important;
            }
        }
    }

    .subset-container {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        .organizations,
        .actors,
        .collections,
        .events {
            display: flex;
            flex-wrap: wrap;
            align-content: space-evenly;
            margin: 0.5rem auto auto;
        }

        /* stylelint-disable no-descending-specificity */
        .card {
            display: flex;
            flex-basis: initial;
            flex-grow: 0;
            flex-shrink: 0;
            justify-content: center;
            width: auto !important; // wide cover images may warp the card, stemming via Base/Card and helper getMasonryWidthHeightStyle
            max-width: 400px;
            margin: 1rem;
            text-align: center;
            .card-image {
                display: inline-flex;
                width: 210px;
                height: auto;
                margin: 1rem auto !important;
            }
            .card-header {
                margin: 0 auto;
                header.tile,
                .card-link {
                    flex-direction: column !important;
                }
            }
            .card-header-title {
                align-items: center;
                text-align: center;
                .curator {
                    line-height: 2;
                }
            }
            .multimedia-title {
                flex-direction: column-reverse; // drop event icon
                .title {
                    margin: auto;
                }
                .event-class-title-icon {
                    min-width: unset;
                    margin: 1rem auto 0;
                }
            }
            .social-button-wrapper {
                margin: 0 auto;
                .social {
                    display: inline-flex;
                    flex-direction: row !important;
                    width: 100%;
                    margin: 0.75rem auto 1rem 0;
                    letter-spacing: 1px;
                }
            }
        }
        .actor-card {
            .card-image {
                width: 180px;
                height: 180px;
            }
            .ingress-text {
                display: none;
            }
        }
        .collection-card,
        .organization-card,
        .event-card {
            .event-organizer .organizer-name {
                margin: auto;
            }
        }
        .feature-article-card {
            .card-image {
                width: 500px;
                height: 340px;
                margin: 1rem !important;
            }
            .card-header {
                margin: 0;
                text-align: left;
            }
            .card-header-title {
                text-align: left;
            }
        }
    }
}
