@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-workspace {
    margin-bottom: 2rem;
    .workspace-artworks-and-artwork {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .accordion-title-toolbar {
        margin-bottom: 0;
    }
    .browse-files {
        margin-bottom: 1rem;
    }
    .add-files.section-toolbar-item {
        margin-top: -60px;
    }
    .user-image-archive-masonry {
        display: flex;
        width: 50%;
        .multi-stack {
            width: 100%;
        }
        .masonry-wrapper {
            width: 100%;
            .grid-sizer {
                width: 130px !important; // CanvasX + margin
            }
        }
        .artwork-card.card {
            .card-header, .card-contents {
                display: flex;
            }
        }
    }
    .selected-artwork {
        width: 50%;
    }
}
