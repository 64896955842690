@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-image-archive-slideout {
    div.panel-dock {
        .material-design-icon {
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            margin-left: 0;
            vertical-align: middle;
        }
    }
    .archive-header {
        font-family: $helvetica-bold;
        font-size: 1rem;
        color: $dark-gray-3;
        text-transform: uppercase;
    }
    .multi-stack {
        width: 100%;
        .social-button-wrapper {
            display: none;
        }
    }
    .masonry-wrapper {
        .grid-sizer {
            width: 130px !important; // CanvasX + margin
        }
    }
    .add-files { // FIXME: common class
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 1rem;
        margin-right: 0;
        margin-bottom: 1rem;
        margin-left: auto;
        cursor: pointer;
        .label {
            margin-left: 1rem;
            font-size: 1rem;
        }
    }
}
