@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.event-brochure-stack {
    .brochure-contents {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .card {
            flex-basis: auto;
            flex-direction: row;
            flex-grow: 1;
            width: 44vw;
            min-height: 25vh;
            min-height: min-content;
            margin: 0 0 10px;
            padding: 0;
            text-align: center;
            h4 {
                margin-bottom: 1rem;
                padding: 0 2rem;
                font-family: $helvetica-roman;
                font-size: 1.5rem;
                line-height: normal;
            }
            @media screen and (max-width: $breakpoint-tablet - 1) {
                flex-direction: column;
            }
            .card-contents {
                color: $light-gray;
                text-align: center;
                background: black;
            }
            p {
                margin: 1.5rem 0.75rem;
            }
            .dimensions, .owner, .price {
                display: none;
            }
            .event-period {
                color: $light-gray;
            }
            &:first-child {
                width: 100%;
                min-height: 300px;
                margin-top: 0;
                text-align: left;
            }
        }
        .card-image {
            display: flex;
            flex: 1;
            flex-direction: column;
            min-height: 25vh;
            figure {
                margin: 0;
            }
        }
        .multimedia-title.event-title {
            flex-direction: row;
            flex-grow: 1;
            padding: 0.25rem 0.5rem;
            .event-period {
                text-align: left;
            }
            .material-design-icon {
                width: 2rem;
                height: 2rem;
            }
        }
        .event-info {
            flex-grow: 2;
            margin: auto 3rem;
            padding: 0;
            @media screen and (max-width: $breakpoint-mobile - 1) {
                margin: auto 1em;
            }
            // stylelint-disable-next-line no-descending-specificity
            .event-period {
                margin: 0 auto 1.5rem;
                padding-left: 0.5rem;
                font-family: $helvetica-roman;
                font-family: $helvetica-light;
                font-size: 1.15rem;
                color: inherit;
                text-align: left;
            }
            .ingress-text {
                font-family: $helvetica-light;
                font-size: 1rem;
            }
            @media screen and (min-width: $breakpoint-widescreen) {
                h4 {
                    font-size: 2rem; // title
                }
                .ingress-text {
                    margin-top: 3rem;
                    font-size: 1.5rem; // ingress
                }
            }
        }
        .btn {
            margin-bottom: 2rem;
            padding: 9px 15px;
            font-family: $helvetica-bold;
            font-size: 13px;
            background: $light-gray;
            &:hover {
                background: $light-gray-2 !important;
            }
        }
    }
}
