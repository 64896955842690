@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.article-image-group {
    width: $main-content-max-width; // adjust width with scrollbar; 100vw does not include them
    @media screen and (max-width: $breakpoint-tablet - 1) {
        width: calc(100vw - 2em);
    }
    margin-bottom: 1.5rem !important;
    text-align: right;
    figure {
        cursor: pointer;
        .image-not-available {
            display: flex;
            width: auto !important;
            min-height: 240px;
            padding-top: 0;
            cursor: default;
            background: url('~assets/images/image_placeholder.jpg');
            background-size: cover;
            background-position: 50% 50%;
            .img {
                display: none;
            }
            .tip {
                margin: 1.5rem auto auto auto !important;
            }
        }
    }
    .big-image {
        display: block;
        width: 100%;
        max-width: 90vw;
        min-height: 75vh;
        margin: 1.5rem auto 1rem;
        figure {
            width: auto;
            height: 100%;
            margin: auto;
        }
        .image {
            height: 75vh; //- TODO: keep image fully visible, not cropped
            margin: auto;
            background-size: cover;
            background-position: 50%; // focus point will override
        }
        // big image has different layout
        .image-tool-icons { // FIXME
            height: 0;
        }
    }
    .small-images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        // justify-content: space-around;
        margin: 0 auto;
        figure {
            display: block;
            width: auto;
            min-width: 300px;
            min-height: 32vh; // FIXME: vertical images 50vh, horizontal 30vh ; should depend on innerWidth and innerHeight
            margin: 1rem 1rem 0;
            .image {
                width: auto;
                height: 320px !important; // FIXME: depends on window innerWidth, if it is a wide screen then this should be higher                background-size: cover;
                background-position: 50%;
            }
        }
    }
}
