@import './variables';

// fade out long contents, no wrapping
@mixin nowrap() {
    display: block;
    overflow-x: hidden;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin dowrap() {
    overflow-x: auto;
    text-overflow: unset;
    white-space: normal;
}

@mixin gradient-title-row($dark: false) {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    padding: 0 !important; // the bottom border is created with ::after, and padding in this container must be 0
    background-image: linear-gradient(to right, $light-gray, $light-gray-2 30%, rgba(5, 5, 0, 0));
    @if $dark {
        box-shadow: -5px 7px 24px $dark-gray;
    }
    &::after {
        display: block;
        width: 100%;
        height: 2px;
        content: "";
        @if $dark {
            background-image: linear-gradient(to right, $medium-gray, $dark-gray-4 42%, $dark-gray-3 64%, rgba(5, 5, 0, 0) 90%, $dark-gray-2);
        }
        @else {
            background-image: linear-gradient(to right, $dark-gray, $dark-gray 42%, $medium-gray-2 64%, rgba(5, 5, 0, 0) 90%);
        }
    }
    .title {
        margin: auto 0.5rem;
        font-family: $helvetica-bold;
        font-weight: normal;
        font-size: 1.15rem;
        line-height: 2.25;
        letter-spacing: 0.05rem;
        color: $dark-gray-2;
    }
}

@mixin transition-bezier($property: all) {
    transition: $property 800ms cubic-bezier(0.45, 1.01, 0.58, 1);
}

@mixin info-message-page() {
    position: relative;
    z-index: auto;
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 15vh 5vw;
    font-family: $main-serif;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    background: url('static/static/coverimages/background-1.jpg');
    background-color: #151c2c;
    background-repeat: no-repeat;
    background-size: cover;
    .infobox {
        h3 {
            margin-bottom: 2rem;
            font-size: 2.5rem;
        }
        div {
            margin: 1rem auto;
            font-size: 1.5rem;
            line-height: normal;
        }
        .btn {
            margin: 0.5rem auto;
            color: white;
            border-color: white;
        }
    }
    .error-message {
        font-size: 2rem;
        color: $error;
    }
}

@mixin actor-circle() {
    box-sizing: border-box;
    width: 180px;
    height: 180px;
    margin: 0 auto;
    overflow: hidden;
    // background-color: white; // default ingress image reacts to theme
    background-size: cover;
    border: 4px double $dark-gray-3;
    border-radius: 120px;
}

// fade-out gradient
@mixin fade-read-more($height, $dark: false, $padding: 2rem) {
    position: relative;
    max-height: $height;
    overflow: hidden;
    white-space: initial;
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: $padding 0;
        text-align: center;
        content: " ";
        @if $dark {
            background-image: linear-gradient(to bottom, rgba(5, 5, 0, 0), rgba(125, 95, 0, 1));
        }
        @else {
            background-image: linear-gradient(to bottom, rgba(255, 251, 251, 0), rgba(255, 251, 251, 1));
        }
    }
}

@mixin background-lighten($opacity: 0.5) {
    background-color: rgba(255, 251, 251, $opacity);
    background-blend-mode: lighten;
}

@mixin fonts-debug($serif: true, $sans-serif: true) {
    @if $sans-serif {
        font-family: "Helvetica Neue LT W01_65 Md", sans-serif;
        font-family: "Helvetica Neue LT W01_41488878", sans-serif;
        font-family: "Helvetica Neue LT W01_35 Thin", sans-serif;
        font-family: "Helvetica Neue LT W01_76 Bd It", sans-serif;
        font-family: "Helvetica Neue LT W01_71488914", sans-serif;
        font-family: "Helvetica Neue LT W01_56 It", sans-serif;
        font-family: "Helvetica Neue LT W01_55 Roman", sans-serif;
    }
    @if $serif {
        // font-family: "SabonNextW01-BoldItalic", serif;
        font-family: "Sabon Next W01 Bold", serif;
        font-family: "Sabon Next W01 Italic", serif;
        font-family: "Sabon Next W01 Regular", serif;
        // font-family: "Sabon LT W01 Bold Italic", serif;
        font-family: "Sabon LT W01 Bold", serif;
        font-family: "Sabon LT W01 Italic", serif;
        font-family: "Sabon LT W01 Regular", serif;
        font-family: "Sabon MT W01 Semibold Italic", serif;
        font-family: "Sabon MT W01 Semibold", serif;
        font-family: "Sabon MT W01 Italic", serif;
        font-family: "Sabon MT W01 Regular", serif;
    }
}
