@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.segment {
    width: 100%;
    margin: 1.5rem 0;
    .segment-header {
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 2rem;
        text-align: left;
        .display-options {
            position: relative;
            flex-grow: 0;
            align-self: center;
            justify-content: flex-end;
            min-height: 3rem;
        }
    }
    .segment-title {
        display: inline-flex;
        flex-direction: column;
        flex-grow: 1;
        flex-wrap: nowrap;
        width: auto;
        min-width: 55%;
        max-width: 95vw;
        padding-right: 1em;
        color: black;
        &, h2 {
            font-family: 'Sabon MT W01 Regular', serif;
        }
        h2 {
            display: block;
            margin: 0;
            font-weight: normal;
            font-size: clamp(28px, 3em, 45px);
            color: inherit;
            word-break: initial;
            &::first-letter {
                text-transform: capitalize;
            }
            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 1.5rem;
            }
        }
        hr {
            width: clamp(28px, 3em, 45px);
        }
        &::after {
            clear: both;
        }
    }

    // override title width when options are present
    .segment-header.with-options {
        .segment-title {
            max-width: calc(95vw - 190px); // display-options
            @media screen and (max-width: $breakpoint-mobile - 1) {
                max-width: 95vw;
            }
        }
    }

    &.sub-segment {
        // stylelint-disable-next-line no-descending-specificity
        .segment-title {
            h2 {
                font-size: 1.25rem;
            }
            hr {
                height: 2px;
            }
        }
    }
}
