@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.home-front-pages {
    position: relative;
    width: 100vw;
    height: 100vh;

    // common background gradient to show until the image loads
    background-image:
        linear-gradient(
            180deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(23, 30, 47, 1) 33%,
            rgba(45, 63, 105, 1) 57%,
            rgba(171, 160, 146, 1) 100%
        );

    // taide.art front page
    .taide-art {
        .infobox {
            position: relative;
            top: 0 !important;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // justify-content: flex-end; // with flex-direction: column-reverse
            height: auto;
            user-select: none;
            transform: translateX(0) translateY(0) !important;
            transition-property: transform;
            transition-duration: 500ms;
            transition-timing-function: ease-in-out;
            h4 {
                font-family: "Sabon LT W01 Bold", serif;
                font-size: 2em;
            }
            em {
                font-family: "Sabon LT W01 Bold", serif;
                font-size: 1.5em;
            }
            h2 { // join
                margin: 0.75rem auto 1.5rem;
                font-family: "Sabon LT W01 Regular", serif;
                font-size: 32px;
                letter-spacing: -1px;
                text-transform: uppercase;
            }
            .call-to-action {
                display: inline-block;
                padding: 1.25em 1.5em;
                background-color: rgba(5, 5, 0, 0.50);
                box-shadow: 2px 2px 8px rgba(5, 5, 0, 0.95);
            }
            .call-to-action h3 {
                width: 100%;
                margin: auto;
                padding: 0;
                font-size: 1.5rem;
                color: white;
                text-align: center;
            }
            .logo {
                width: auto;
                height: auto;
                margin-top: 7vh;
                margin-bottom: 4rem;
                background:
                    radial-gradient(
                        farthest-side at 50% 51%,
                        rgba(5, 5, 0, 0.70),
                        rgba(5, 5, 0, 0.40) 30%,
                        rgba(25, 25, 25, 0.50) 35%,
                        rgba(5, 5, 0, 0.40) 40%,
                        rgba(5, 5, 0, 0.40) 50%,
                        rgba(25, 25, 25, 0.50) 55%,
                        rgba(5, 5, 0, 0.40) 60%,
                        rgba(5, 5, 0, 0.20) 80%,
                        rgba(5, 5, 0, 0) 97%
                    );
                figure .image {
                    height: 220px !important; // taide.art svg logo
                    margin-right: auto;
                    margin-left: auto;
                }
            }
            .buttons {
                align-content: center;
                justify-content: center;
                margin-top: 1rem;
                button.btn {
                    align-self: center;
                    &.login {
                        padding: 8px 0.8rem !important;
                        font-family: $helvetica-medium !important;
                        color: white !important;
                        background: transparent !important;
                        border: 2px solid white !important;
                    }
                }
            }
            @media screen and (min-width: $breakpoint-widescreen) {
                width: 100%;
            }
            @media screen and (max-width: $breakpoint-tablet - 1) {
                width: 100% !important;
                min-width: unset;
                max-width: 90vw;
                margin: auto;
                margin-top: 7vh;
                padding-right: 0;
                padding-left: 0;
                .logo {
                    width: 100%;
                }
                .call-to-action {
                    max-width: 375px;
                    margin: auto;
                    font-size: smaller;
                }
            }
        }
        @media screen and (max-width: $breakpoint-mobile - 1) {
            padding-top: 11vh;
            .infobox {
                .logo {
                    margin-top: 4vh;
                    figure .image {
                        width: 50vw !important;
                        height: auto !important;
                    }
                }
                .call-to-action {
                    max-width: unset;
                }
                .buttons {
                    flex-direction: column-reverse;
                }
            }
            .card {
                max-width: unset;
            }
        }
        @media screen and (min-width: $breakpoint-desktop) {
            padding-top: 15vh;
        }
    }

    // initial infobox style
    .initial.taide-art .infobox {
        .call-to-action {
            background-color: unset;
            box-shadow: none;
        }
        .logo {
            background: none;
        }
    }

    .hero-page {

        &.infobox-visible .infobox {
            opacity: 1;
        }
        &.infobox-hidden .infobox {
            opacity: 0;
        }

        .card {
            width: auto !important;
            max-width: 80vw;
            padding: 1.25rem;
            color: $dark-gray !important;
            user-select: none;
            background: rgba(255, 251, 251, 0.5) !important;
            backdrop-filter: blur(5px);
            .card-link {
                text-decoration: none;
                .title {
                    text-decoration: none;
                }
            }
            .tagline {
                margin: 0 auto 1rem 2rem;
                font-family: 'Sabon MT W01 Italic', serif;
                font-weight: 600;
                font-size: 1rem;
            }
            .ingress-text {
                margin-bottom: 1rem;
            }
            .card-header-title {
                cursor: pointer;
                @include dowrap;
                h2 {
                    @include dowrap;
                }
            }
            .body-html {
                @include fade-read-more(350px);
                &::after {
                    background-image: none;
                }
            }
        }

        .artwork-card {
            padding-bottom: 0.5rem;
            background:
                linear-gradient(
                    to top,
                    rgba(255, 244, 241, 0.70),
                    rgba(255, 255, 255, 0.80) 5%,
                    rgba(255, 251, 251, 0.90) 95%,
                    rgba(255, 244, 241, 0.80)
                ) !important;
            backdrop-filter: blur(5px);
            border: 0;
            // box-shadow: -2px 1px 14px rgba(255, 251, 251, 0.45);
            .actor-card {
                margin-top: 1.25rem;
                padding: 0;
                background: unset;
                backdrop-filter: none;
            }
            .card-header {
                background: unset;
                a.card-link {
                    background: unset;
                }
                // hide certain artwork details
                .artworks-counted,
                .lifetime {
                    display: none;
                }
                .title {
                    text-decoration: none;
                }
                .card-header-title {
                    color: $dark-gray-2 !important;
                }
            }
            .card-contents {
                margin: 1.5rem 0 1rem !important;
                .owner {
                    color: $dark-gray-4 !important;
                }
            }
            &.card .card-header-title .makers {
                font-size: 1.5rem;
                color: $dark-gray-2 !important;
            }
            &.card .card-header-title .title {
                font-size: 1.5rem;
                color: $dark-gray-2 !important;
            }
            .details {
                display: none;
            }
            .details.production-timespan,
            .details.keeper {
                display: block;
            }
        }

        .collection-card {
            color: $light-gray !important;
            background: rgba(5, 5, 0, 0.75) !important;
            .card-header {
                margin-bottom: 1rem;
                background: transparent !important;
                .card-header-title {
                    color: $medium-gray-3 !important;
                }
                // stylelint-disable-next-line no-descending-specificity
                .title {
                    font-family: $main-sans-serif;
                    font-size: 1.5rem;
                    letter-spacing: 1px;
                    color: $medium-gray-3 !important;
                    text-shadow: 1px 1px 1px $dark-gray-2;
                }
                .curator {
                    margin-top: 1rem;
                    font-size: 1.25rem;
                }
            }
        }

        .feature-article-card {
            padding: 1.5rem 0 2.5rem;
            background:
                linear-gradient(
                    to top,
                    rgba(255, 244, 241, 0.05),
                    rgba(255, 255, 255, 0.50) 10%,
                    rgba(255, 251, 251, 0.85) 95%,
                    rgba(255, 244, 241, 0.64)
                ) !important;
            backdrop-filter: blur(5px);
            border-color: rgba(124, 124, 124, 0.04);
            &.even {
                border-color: rgba(251, 204, 241, 0.04);
            }
            border-style: groove;
            border-width: 0 1rem;
            .card-header {
                background: unset;
                // stylelint-disable-next-line no-descending-specificity
                .title {
                    font-size: 2rem;
                }
            }
            .card-contents {
                width: 100%;
                margin-left: 0 !important;
                color: $dark-gray-2 !important;
                background: unset !important;
            }
            .article-ingress {
                max-width: unset;
                h2 {
                    font-size: 1.75rem;
                    color: $dark-gray-2 !important;
                }
            }
            .publish-date {
                display: none !important;
            }
        }

        .event-card {
            color: $light-gray !important;
            background:
                linear-gradient(
                    to right top,
                    rgba(5, 5, 0, 0.95),
                    rgba(5, 5, 0, 0.90) 30%,
                    rgba(9, 9, 5, 0.85) 77%,
                    rgba(5, 5, 0, 0.94)
                ) !important;
            box-shadow: 2px 2px 8px rgba(5, 5, 0, 0.95);
            .card-header {
                margin-bottom: 1rem;
                background: transparent !important;
                h2 {
                    color: $light-gray !important;
                }
                // stylelint-disable-next-line no-descending-specificity
                .title {
                    font-family: $main-sans-serif;
                    font-size: 1.5rem;
                    line-height: 2.75rem;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 1px $medium-gray;
                }
                .organizer-name {
                    color: $light-gray !important;
                }
                .event-period {
                    margin-top: 0.5rem;
                    color: $light-gray !important;
                }
                .card-link:hover {
                    .event-period {
                        text-decoration: none;
                    }
                }
            }
            .ingress-text {
                font-size: 1.2rem;
            }
        }

        &.hero-Actor {
            .slick-slider {
                width: 100%;
                max-width: 98vw;
            }
            .actor-slides {
                width: 100%;
                min-height: 300px;
            }
            .infobox {
                width: 100%;
                max-width: 98vw;
                transform: unset !important;
                .columns {
                    display: inline-flex;
                    flex-direction: column;
                }
                .column {
                    width: auto;
                }
                .actor-card {
                    display: inline-flex !important;
                    flex-direction: column;
                    width: clamp(300px, 400px, 95vw) !important;
                    margin-right: auto;
                    background: unset !important;
                    transform: unset !important;
                    &.is-right {
                        margin-left: auto;
                    }
                    &.is-left {
                        margin-right: auto;
                    }
                }
                .actor-card,
                .card-header {
                    backdrop-filter: unset;
                }
                .card-header {
                    align-self: center;
                    margin: 0.5rem auto;
                    padding: 3rem 4rem !important;
                    background:
                        radial-gradient(
                            farthest-side at 50% 50%,
                            rgba(255, 251, 251, 0.85) 15%,
                            rgba(255, 251, 251, 0.5) 65%,
                            rgba(255, 251, 251, 0) 100%
                        ) !important;
                    .title {
                        color: $dark-gray-2 !important;
                        text-shadow: 1px 1px 1px $medium-gray-2 !important;
                    }
                    @media screen and (max-width: $breakpoint-mobile - 1) {
                        padding: 1rem;
                        figure {
                            margin: auto;
                        }
                    }
                }
                .card-social {
                    display: none;
                }
                .card-image {
                    display: inline-flex;
                    flex-grow: 2;
                    align-self: center;
                    margin: 0 auto !important;
                }
                .actor-body {
                    width: clamp(20rem, 30rem, 80vw);
                    padding: 1rem;
                    background:
                        linear-gradient(
                            to top,
                            rgba(255, 251, 251, 0.46),
                            rgba(255, 251, 251, 0.64) 10%,
                            rgba(255, 251, 251, 0.86) 95%,
                            rgba(255, 251, 251, 0.46)
                        ) !important;
                    backdrop-filter: blur(5px);
                    article {
                        // revert CSS fade
                        max-height: 70vh;
                        font-size: 1rem;
                        &::after {
                            content: unset;
                        }
                    }
                    .btn {
                        background: unset;
                        &:hover {
                            box-shadow: unset;
                        }
                    }
                }
                .do-follow {
                    background: rgba(255, 255, 255, 0.85);
                }
            }
        }

        @media screen and (max-width: $breakpoint-tablet - 1) {
            .card-header .title,
            .article-ingress h2 {
                font-size: 1.35rem !important;
            }
            .ingress-text {
                font-size: 1rem !important;
            }
        }
        @media screen and (max-width: $breakpoint-mobile - 1) {
            .card-contents {
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }
            .card-header .title,
            .article-ingress h2 {
                font-size: 1.25rem !important;
            }
            .ingress-text {
                font-size: 1rem !important;
            }
        }
    }

    .hero-navigation {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10000; // more than .hero-page, less than main .navbar
        display: flex;
        justify-items: space-between;
        width: 99vw; // adjust for browser scrollbar
        height: 100vh;
        .next-icon, .previous-icon {
            display: inline-flex;
            align-self: center;
            width: 64px;
            height: 64px;
            color: rgba(239, 239, 239, 0.92);
            cursor: pointer;
            transform: scaleY(1.5);
        }
        .next-icon {
            justify-self: right;
            margin-left: auto;
        }
        .previous-icon {
            justify-self: left;
        }
    }
}
