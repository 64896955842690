@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.slick-track {
    height: auto; // this might be dangerous but important for some views!
    min-height: 400px;
    @media screen and (max-width: $breakpoint-mobile - 1) {
        min-height: 200px;
    }
}
.slick-slide {
    max-height: 200px;
    margin: 0;
    margin-right: 0.5rem;
    // hidden large slides should not take too much space
    transition: ease-out;
    transition-duration: 2750ms;
    transition-delay: 75ms;
    &.slick-active {
        height: auto;
        max-height: 200vh;
    }
    &.slick-active > div:last-child {
        margin-right: 0;
    }
    .card-image figure {
        height: inherit;
    }
    img {
        width: inherit;
        height: inherit;
        max-width: 100%;
        min-height: inherit;
        max-height: inherit;
    }
}
.slick-prev, .slick-next {
    top: 42%;
}

/**
 * Responsive card with social button inside the header
 */
@media screen and (max-width: $breakpoint-desktop - 1) {
    .slick-track {
        .card.card-social-header {
            .card-header-title {
                max-width: unset;
            }
            .card-link {
                flex-direction: column !important;
                .social-button-wrapper {
                    margin: 0.5rem 0;
                    margin-left: auto;
                }
            }
        }
    }
}
