@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.entry-information-notes-panel {
    .entry-title.page-text {
        margin-bottom: 0;
        .segment {
            margin: 0;
        }
    }
    .notes-html.page-text {
        p {
            margin-top: 0;
        }
    }
}
