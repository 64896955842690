@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.stacking-modes-toolbar {
    position: absolute;
    right: 0;
    z-index: 4000; // below navbar drop-down
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
    margin-top: -1rem;
    margin-right: $main-content-margin;
    margin-bottom: 1rem;
    margin-left: auto;
    .stacking-option {
        display: table-cell;
        width: 40px;
        height: 40px;
        margin: 0;
        margin-left: 1rem;
        padding: 4px;
        color: inherit;
        a {
            padding: 0;
            color: inherit;
        }
        .material-design-icon {
            display: block;
            width: 32px !important;
            height: 32px !important;
            cursor: pointer;
        }
        .dx-checkbox-icon {
            width: 24px !important;
            height: 24px !important;
            margin: 2px;
        }
        span {
            display: inline-block;
        }
        .label {
            font-size: 0.875rem;
        }
        &.active {
            padding: 2px;
            border: 2px solid black;
            border-radius: 4px;
        }
        // &.view-imageless.unchecked {
        //     opacity: 0.5;
        // }
    }
    @media screen and (max-width: 960px) {
        position: initial;
        display: flex;
        margin-top: 0.25rem;
        margin-right: 0;
        margin-bottom: 0.75rem;
        .stacking-option {
            margin-left: 0.85rem;
        }
    }
    .disabled {
        a {
            cursor: not-allowed;
        }
    }
}
