@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';
@import 'viewerjs/dist/viewer.min.css';

figure {
    flex: 1;
    width: inherit;
    height: 100%;
    min-width: inherit;
    min-height: 90px;
    user-select: none;
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: 0 50%;
    & > div {
        overflow: hidden;
    }
    .viewer-container {
        width: inherit !important;
        height: inherit !important;
    }
    .image {
        width: 100%;
        height: inherit;
        min-width: inherit;
        max-width: stretch;
        min-height: inherit;
        margin: 0;
        padding: 0;
        filter: blur(8px);
        background-repeat: inherit;
        background-size: inherit;
        background-position: inherit;
        object-fit: cover; // not supported on IE
    }
    .image-not-available {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        height: inherit;
        padding: 1rem 4vw;
        font-family: 'Sabon Next W01 Regular', serif;
        font-style: italic;
        font-size: 1rem;
        text-align: center;
        vertical-align: middle;
        // stylelint-disable-next-line color-no-hex
        background: #efefef;
        img {
            align-self: center;
            width: auto;
            height: auto;
            max-width: 100px;
            margin: auto;
        }
    }
    .ingress-text {
        position: absolute;
        right: 4rem;
        bottom: 0.5rem;
        left: 0.5rem;
        width: auto;
        height: auto;
        max-height: 50px;
        padding: 0.5rem;
        overflow: hidden;
        font-family: "Sabon LT W01 Italic", serif;
        font-size: 0.8rem;
        color: white;
        text-overflow: ellipsis;
        word-break: break-all;
        word-wrap: none;
        white-space: nowrap;
        background: rgba(23, 23, 23, 0.85);
        opacity: 0; // hide
        transition: opacity 0.5s ease-out;
        .title {
            margin-bottom: 0.2rem;
            font-family: "Sabon LT W01 Bold", serif;
            font-size: 1rem;
            color: white;
        }
    }
    &:hover .ingress-text {
        opacity: 1; // show
    }
    .credits {
        font-size: 14px;
        text-align: right;
    }
    .zoom-lock {
        position: absolute;
        top: 0;
        right: 0;
        // triangle shape in upper right corner
        width: 0;
        height: 0;
        overflow: visible;
        background: transparent;
        border-top: 75px solid rgba(23, 23, 23, 0.75);
        border-left: 75px solid transparent;
        opacity: 1;
        transition: opacity 0.5s ease-out;
        span {
            position: relative;
            top: -68px;
            left: -33px;
            z-index: 5000;
            width: 1.6rem;
            height: 1.6rem;
            color: rgba(233, 227, 227, 0.75) !important;
        }
    }
    // &:hover .zoom-lock {
    //     // opacity: 0; // hide
    //     // &:hover {
    //     //     opacity: 1; // except if overing of it
    //     // }
    // }
    .social-button-wrapper {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        opacity: 0;
        transition: opacity 0.5s ease-out;
    }
    &:hover .social-button-wrapper {
        opacity: 1; // show
    }
}
