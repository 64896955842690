@import "~assets/styles/mixins.scss";

// https://www.benmarshall.me/css-aspect-ratio/
.tribe2-page {
    display: flex;
    width: 92vw;
    height: calc(100vh - 110px);
    margin-top: 2em;
    margin-right: auto;
    margin-left: auto;
    // background: #b4c6ef;
    border: 2px dotted #757575;
}
