@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.content-page {
    margin-bottom: 1.5rem;
    .header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        padding-top: 1rem;
        text-align: left;
        h1 {
            margin: 0.5rem 0;
            font-family: $helvetica-light;
            font-size: 2rem;
            line-height: 2.125rem;
        }
        h2 {
            font-family: $helvetica-light;
            font-size: 1.5rem;
            line-height: 1.75;
        }
        hr {
            display: block;
            width: 55px;
            margin: 1.5rem 0 2rem;
        }
        .outline {
            display: inline-block;
            margin: 0;
            padding: 0.5rem 0 1rem;
        }
        .title {
            border-right: 1px solid black;
        }
        // edit field titles
        .edit-access, .preview-ingress-image {
            h3 {
                font-family: $main-sans-serif;
                font-size: 1rem;
            }
        }
        .banner {
            margin-bottom: 0;
            padding: 0;
            padding-left: 1rem;
            text-align: right;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
            figure {
                width: 150px;
                height: auto;
                margin-left: auto;
            }
            .ingress-image {
                float: right;
            }
            .preview-cover-image {
                float: left;
            }
        }
        @media screen and (max-width: $breakpoint-mobile - 1) {
            .banner {
                min-height: 300px !important;
            }
        }
        @media screen and (max-width: $breakpoint-desktop - 1) {
            flex-direction: column-reverse;
            .column.outline {
                width: auto;
            }
            .banner {
                // marginless banner image
                width: 100vw;
                margin-top: -$main-content-margin;
                margin-right: -$main-content-margin;
                margin-bottom: 1em;
                margin-left: -$main-content-margin;
            }
            &.is-tablet-centered {
                // narrow screens have centered layout
                .column.outline {
                    margin: 0 auto;
                    padding-right: 0;
                    text-align: center;
                    .ingress-text, .tagline {
                        margin-right: auto !important;
                        text-align: center;
                    }
                    .ingress-image img {
                        margin: auto;
                    }
                    hr {
                        float: none;
                        margin-right: auto !important;
                        margin-left: auto !important;
                    }
                }
            }
        }
        @media screen and (max-width: $breakpoint-tablet - 1) {
            .banner {
                // marginless banner image
                margin-top: -1em;
                margin-right: -1em;
                margin-left: -1em;
            }
        }
        @media screen and (min-width: $breakpoint-fullhd-xl) {
            .banner {
                min-height: 320px !important;
            }
        }
    }
    .submenu.navbar {
        z-index: 5000;
        box-sizing: content-box;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
        width: auto;
        min-width: unset;
        min-height: unset;
        padding: 0;
        color: $dark-gray-3;
        text-align: center;
        background: transparent;
        border-color: black;
        border-style: solid;
        border-width: 1px 0;

        // set decreasing z-indices so that the menus are on top
        @for $i from 1 through 10 {
            & > .navbar-item:nth-child(#{$i}) {
                z-index: 5000 - $i;
            }
        }

        .navbar-item {
            justify-content: center;
            min-width: 9rem;
            margin: 0.5em 0;
            margin-left: 1.5rem;
            padding: 1rem 1.25em;
            padding-left: 0;
            font-family: $helvetica-light;
            font-weight: 400;
            font-size: 1.05em;
            letter-spacing: 0.03125em; // 0.5px
            color: inherit;
            text-transform: none;
            vertical-align: sub;
            border-color: inherit;
            border-style: solid;
            border-width: 0 1px 0 0;
            &:hover {
                text-decoration: underline;
                background: unset;
            }
            &.has-dropdown {
                padding-right: 2.75rem; // room for chevron
                &::after {
                    // Bulma chevron
                    border-color: $dark-gray-3 !important;
                    border-width: 2px !important;
                }
                &.router-link-active::after {
                    border-width: 3px !important;
                }

                &:hover {
                    text-decoration: none;
                    .navbar-dropdown {
                        opacity: 1;
                        transform: translateY(-5px);
                    }
                }
                .navbar-link {
                    padding: 0 1.5rem 0 0;
                    letter-spacing: 1px;
                    color: inherit;
                    text-transform: uppercase;
                    &.router-link-active {
                        font-weight: bold;
                    }
                    &:hover {
                        background-color: transparent !important;
                    }
                    &::after {
                        // bulma chevron
                        right: 0 !important;
                        border-color: inherit !important;
                        border-width: 2px !important;
                    }
                    &.disabled {
                        cursor: default;
                    }
                }
                .navbar-item,
                .navbar-item:last-child {
                    padding-left: 1rem;
                }
            }
            // stylelint-disable-next-line no-descending-specificity
            & > .navbar-dropdown {
                position: absolute;
                top: 100%;
                left: 0;
                display: block;
                min-width: 100%;
                font-size: 0.875rem;
                // fix automatic expansion, copy Bulma styles
                border-top: none;
                border-radius: 6px;
                box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
                opacity: 0;
                // pointer-events: none;
                // top: calc(100% + (-4px));
                transform: translateY(-5px) translateX(-2000px);
                transition-property: opacity;
                transition-duration: 286ms;
                // stylelint-disable-next-line no-descending-specificity
                &:hover {
                    pointer-events: auto;
                    opacity: 1 !important;
                    transform: translateY(-5px);
                }
            }
            &.router-link-active {
                font-family: $helvetica-bold;
                letter-spacing: normal;
                // font-weight: bold;
                color: black;
                border-bottom-width: 1px;
                &:hover {
                    text-decoration: none;
                }
            }
            // stylelint-disable-next-line no-descending-specificity
            &:last-child {
                margin-left: 0;
                padding-left: 0.77rem + 0.73rem;
                border-right: 0;
            }
            // mobile has alternative submenu border
            @media screen and (max-width: $breakpoint-mobile - 1) {
                border-width: 0 0 1px 0;
                &.router-link-active {
                    border-right-width: 1px;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
        // stylelint-disable-next-line no-descending-specificity
        .navbar-dropdown {
            color: $dark-gray-3;
            background-color: white;
            // stylelint-disable-next-line no-descending-specificity
            .navbar-item {
                display: block;
                margin: 0;
                border: 0;
                // stylelint-disable-next-line no-descending-specificity
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    section.columns {
        flex-direction: column;
    }
    .contents {
        margin-bottom: 3rem;
        .page-text {
            p {
                margin-top: 0.5rem;
                margin-bottom: 1rem;
            }
        }
    }
}
