@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.errors-message {
    display: inline-flex;
    margin: 2rem auto;
    font-family: $main-sans-serif;
    font-size: 1.1rem;
    color: $error;
    .silhouette-assistant .bubble-wrapper {
        flex-direction: row;
    }
    .material-design-icon {
        width: 32px;
        height: 32px;
        margin-right: 0.5rem;
    }
    .error-message {
        margin: auto 1rem;
        font-size: 1rem;
        color: $error;
    }
    .message-wrapper {
        display: flex;
    }
}
