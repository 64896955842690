@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.card {
    margin: 0;
    padding: 0;
    font-family: $main-sans-serif;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    .tile {
        width: 100%;
    }
    .card-header-title {
        font-family: $main-sans-serif;
    }
    .card-image {
        width: inherit;
    }
    h1, h2 {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: initial;
    }
    .makers, .card-header .card-header-title .makers {
        margin: 0.1rem 0;
        font-family: 'Sabon MT W01 Semibold', serif;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
    }
    .title, .card-header .card-header-title .title {
        display: block;
        margin: 0.1rem 0;
        font-family: 'Sabon MT W01 Semibold Italic', serif;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        letter-spacing: 0.025em;
    }
    .dimensions {
        margin: 0.75rem 0 1rem;
        font-size: 0.875rem;
    }
    .owner, .collection {
        display: inline-block;
        margin: 0.2rem 0 0.2rem;
        font-size: 0.875rem;
        color: $dark-gray-4;
    }
    .collection {
        margin-top: 0;
        a:hover {
            text-decoration: underline;
        }
    }
    .price {
        margin: 0.2rem 0;
        font-size: 0.875rem;
    }
}
