@import "~assets/styles/mixins.scss";

 .logo-area-container {
  background-color: white;
  padding: 20px 40px 40px 20px;
  text-align: center;

  &.single-logo {
    .logo-container {
      justify-content: center;
    }
  }

  .logo-container {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .logo-wrapper {
    min-width: 200px;
    max-width: 30vw;
    width: 25%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    @media (min-width: 1200px) {
      width: 49%;
    }

    &.single-logo {
      @media (min-width: 1200px) {
        width: 100%; // Jos vain yksi logo, käytä koko leveyttä
      }
    }
  }

  .logo-image {
    max-width: 100%;
    height: auto;
  }
}
