@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.organization-contact {
    display: flex;
    flex-direction: column;
    font-family: $helvetica-roman;
    font-size: 1rem;
    h3 {
        font-family: $helvetica-bold;
        font-size: 1.25rem;
    }
    .section-toolbar {
        position: relative;
        display: flex;
        justify-content: flex-start;
        margin: 0;
    }
    .departments {
        display: flex;
        flex-direction: column;
        .organization-department {
            margin-bottom: 1rem;
        }
        .organization-department:first-child {
            // hide the first department remove button
            .remove-department.section-toolbar-item {
                display: none;
            }
            .section-toolbar {
                justify-content: flex-end;
            }
        }
        .organization-personnel-contact:first-child {
            // also the first contact personnel remove button
            .remove-personnel-contact.section-toolbar-item {
                display: none;
            }
        }
    }
    .social-media-contacts {
        .icons {
            text-align: center;
            .material-design-icon {
                width: 35px;
                height: 35px;
            }
        }
        .row {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            margin-bottom: 0.5rem;
        }
        .page-text {
            margin: 0;
        }
        .dx-texteditor {
            width: 25rem;
            margin-left: 1rem;
        }
    }
    .submit-button {
        justify-self: center;
        margin: 2rem auto;
    }
}
