@import "~assets/styles/mixins.scss";

/* stylelint-disable selector-class-pattern */
.modal-upload-ingress-image {
    &.modal-dialog {
        .o-modal__content {
            top: 7vh !important;
            display: flex;
            flex-direction: column;
            align-content: space-between;
            width: 85vw !important;
            height: auto !important;
            min-height: 75vh;
            padding: 0.75em;
        }
        h2.title {
            text-align: left;
            border: 0;
        }
        .columns {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-wrap: none;
            align-content: space-between;
            height: auto;
            text-align: left;
        }
        .column {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 0;
            align-content: space-between;
            &:last-child {
                padding-left: 0.5em;
            }
            &.cropper-column {
                display: flex;
                flex-grow: 2;
                height: auto;
            }
        }
        .UppyDragDrop {
            &, &:focus {
                outline: none;
            }
        }
        .uppy-DragDrop-container {
            border: 0;
            .uppy-DragDrop-inner {
                margin-right: 1.5rem !important;
            }
            .uppy-DragDrop-label {
                font-size: 1.15rem !important;
            }
        }
        .UppyMobileFileInput {
            margin: 0 0.25rem 1rem;
        }
        .image-cropper {
            height: 100%;
        }
        .modal-footer {
            z-index: 9999;
            display: flex;
            align-self: left;
            margin: auto auto 0.5rem 0;
            .is-loading {
                border: 0;
            }
        }
        .btn {
            margin: auto 1rem auto 0;
        }
        .button.is-loading {
            width: 7.5rem;
            border: 2px solid black;
        }
        @media (max-width: 500px) {
            top: 0;
            .columns {
                flex-direction: column;
            }
            .column {
                display: inline-block;
            }
        }
    }
}
