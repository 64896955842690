@import "~assets/styles/mixins.scss";

/* stylelint-disable selector-class-pattern */
.pswp {
    z-index: 15000 !important; // must exceed navigation
    .pswp__caption {
        top: 0;
        bottom: unset;
        z-index: 2000;
        font-family: 'Sabon MT W01 Regular', serif;
        user-select: none;
        .pswp__caption__center {
            max-width: calc(100% - 88px);
            font-size: 1.25rem;
            color: white;
            text-align: center;
        }
    }
    .pswp__top-bar {
        z-index: 2001;
    }
}
