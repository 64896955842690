@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#index-search {
    .display-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-width: 52rem;
        margin: 1.25rem 0;
        .display-option {
            display: inline-flex;
            margin-right: 2rem;
            margin-left: 0;
        }
    }
    .results {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-left: 15vw;
        @media screen and (max-width: $breakpoint-mobile - 1) {
            margin-left: 0;
        }
        .result-title-row {
            @include gradient-title-row;
            font-size: 1.25rem;
        }
        .result-count {
            width: 100%;
            margin-bottom: 1rem;
            font-family: $main-sans-serif;
            font-size: 1rem;
            text-align: left;
        }
        .stacking-option {
            &.view-slideshow,
            &.view-imageless,
            &.view-print {
                display: none;
            }
        }
        .result-item {
            display: inline-flex;
            max-width: 700px;
            margin-bottom: 1rem;
        }
        .card {
            flex-grow: 0;
            .card-link {
                display: inline-flex;
                flex-grow: 1;
            }
            .card-image {
                flex-grow: 1;
                width: auto;
                height: unset;
                min-height: 280px;
                max-height: 35vh;
                overflow: hidden;
                .image-box {
                    min-height: unset;
                    .image {
                        max-height: 35vh;
                    }
                }
            }
        }
        .artwork-card {
            figure.image-box {
                width: inherit !important;
                height: 100%;
            }
        }
        .actor-card {
            flex-grow: 0;
            .card-image {
                min-height: unset;
            }
        }
        .organization-card {
            &:hover {
                box-shadow: none;
            }
        }
    }
}
