@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.social-tags {
    display: flex;
    flex-wrap: wrap;
    .community-tag {
        display: inline-flex;
        margin: 0 0.25rem 0.25rem 0;
        padding: 0.25rem 0.5rem;
        font-family: $main-sans-serif;
        font-size: 1rem;
        color: white;
        background-color: $medium-gray;
        box-shadow: 0px 2px 3px rgba(5, 5, 0, 0.16);
    }
}
