@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.hero {
    position: relative;
    z-index: 1000;
    width: 100%;
    height: auto;
    min-height: 50%;
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    .infobox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 35%;
        height: 100%;
        margin: 0 auto 0 $main-content-margin;
        padding: 2em;
        font-family: $helvetica-roman;
        font-size: 1em;
        color: $dark-gray;
        user-select: none;
        background-color: rgba(255, 251, 251, 0.80);
        box-shadow: 0px 0px 4px white;
        h2.title {
            font-family: $helvetica-medium;
            font-weight: normal;
            font-size: 1.75rem;
            line-height: 1.5;
            color: inherit;
            white-space: normal;
        }
        .ingress-text, .tagline {
            margin: auto;
            font-family: 'Sabon MT W01 Semibold Italic', serif;
            font-size: 1.15rem;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            width: 80%;
            min-width: unset;
            padding: 0.75rem;
            h2.title {
                font-size: 2.5rem;
                line-height: 3.5rem;
            }
            .ingress-text, .tagline {
                font-size: 1.75rem;
            }
        }

        b {
            display: block;
            width: 100%;
            font-family: $helvetica-bold;
            line-height: 2.5em;
        }
        em {
            display: block;
            width: 100%;
            margin-bottom: 1em;
            font-style: italic;
            line-height: normal;
        }
        p {
            font-style: normal;
        }
        .btn {
            z-index: 9999;
            align-self: center;
            margin: 2rem auto 1rem;
            padding: 0.45rem 1rem;
            font-family: $helvetica-bold;
            color: black;
            text-transform: uppercase;
            cursor: pointer;
            background: $light-gray;
            border: 0;
            box-shadow: 0px 0px 20px $medium-gray-2;
            &:hover {
                background: rgba(239, 239, 239, 0.35) !important;
            }
        }
    }

    &.is-left .infobox {
        align-self: left;
        margin-right: auto;
        margin-left: 3.5rem;
    }
    &.is-right .infobox {
        align-self: right;
        margin-right: 3.5rem;
        margin-left: auto;
    }
    &.is-fullheight .infobox {
        height: inherit; // requires parent to have fixed height!
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 5rem;
        padding-bottom: 2.5rem;
        @media screen and (max-width: $breakpoint-tablet - 1) {
            padding: 2rem 0.5rem 1rem;
        }
    }
    &.type1 .infobox {
        color: $light-gray;
        background: rgba(5, 5, 0, 0.6);
        border-width: 0;
        box-shadow: none;
    }
    &.type2 .infobox {
        color: $dark-gray;
        background: rgba(255, 251, 251, 0.39);
        .btn {
            box-shadow: 0px 0px 20px $light-gray;
        }
    }
    &.type3 .infobox {
        color: $light-gray;
        background: rgba(5, 5, 0, 0.65);
        .btn {
            margin: 2rem auto !important;
            padding: 0.25rem 2rem;
            color: $light-gray;
            background: transparent;
            border: 2px solid white;
            box-shadow: 0px 0px 20px $medium-gray;
        }
    }
    &.type4 .infobox {
        color: $dark-gray;
        background: rgba(255, 251, 251, 0.55);
    }
    &.type5 .infobox {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        max-width: 600px;
        margin: auto auto $main-content-margin $main-content-margin !important;
        padding: 0.75rem;
        text-align: center;
        & > * {
            align-self: center;
            margin: 1rem !important;
        }
        @media screen and (max-width: $breakpoint-desktop - 1) {
            max-width: 80vw;
            .btn {
                margin: 2rem auto 1rem !important;
            }
        }
        @media screen and (min-width: $breakpoint-desktop) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 62%;
            height: auto;
            min-width: 400px;
            max-width: unset;
            min-height: unset;
            margin: $main-content-margin $main-content-margin auto auto !important;
            & > * {
                align-self: center;
                margin: 0.5rem !important;
            }
        }
    }
    &.type6 .infobox {
        align-items: left;
        margin: 0 auto 0 $main-content-margin !important;
        font-family: $helvetica-light;
        background: rgba(255, 251, 251, 0.80) !important;
        box-shadow: 0px 0px 4px white;
        h2 {
            font-family: $helvetica-light;
            font-weight: 400;
        }
        b {
            text-transform: none;
        }
        .ingress-text, .tagline {
            margin: auto;
        }
        .btn {
            margin-top: 2rem;
            box-shadow: 0px 0px 20px $medium-gray-2;
        }
    }
    &.type7 {
        .infobox {
            min-height: 300px;
            padding: 4rem 4.5rem 4rem;
            .btn {
                margin: 2rem auto !important;
                padding: 0.25rem 2rem;
                color: $dark-gray;
                background: transparent;
                border: 2px solid $medium-gray-2;
            }
        }
        @media screen and (max-width: $breakpoint-mobile) {
            .infobox {
                padding: 1rem;
            }
        }
    }

    @media screen and (max-width: $breakpoint-tablet - 1) {
        .infobox {
            width: 50%;
        }
        &.type7 {
            .infobox {
                padding-right: 2.5rem;
                padding-left: 2.5rem;
                font-size: 2rem;
            }
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        background-position-x: 50%;
        .infobox {
            width: 75%;
            margin: 2rem auto;
        }
    }
}
