@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.article-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 1rem;
    a {
        display: block;
        margin: 0 auto 0 0;
        padding: 0.5rem 0 0.25rem 1rem;
        &, &:hover {
            color: $light-purple;
        }
        span.label {
            font-size: 20px;
            color: inherit !important;
        }
    }
    .category {
        font-family: $helvetica-bold;
        font-size: 1rem;
    }
    .link-category {
        margin-bottom: 1rem;
    }
}
