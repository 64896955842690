@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.hero-slides {
    right: 0;
    left: 0;
    flex-direction: column;
    width: 100vw;
    margin: 0;
    margin-right: - $main-content-margin;
    margin-left: - $main-content-margin;
    padding: 0;
    .segment-header {
        max-width: $main-content-max-width;
        margin-left: $main-content-margin;
    }
    .slick-next {
        right: 15px;
    }
    .slick-prev {
        left: 5px;
    }
    @media screen and (max-width: $breakpoint-tablet - 1) {
        margin-right: unset;
        margin-left: - $mobile-content-margin;
        .segment-header {
            max-width: $mobile-content-max-width;
            margin-left: $mobile-content-margin;
        }
    }
}
