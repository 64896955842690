@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.gdpr-notification {
    position: fixed;
    bottom: -200px;
    z-index: 99999;
    display: flex;
    width: 100%;
    height: 150px;
    margin: 0;
    padding: 0 $main-content-margin;
    padding-left: 20px;
    transition-property: bottom;
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    &.visible {
        bottom: -1px;
    }
    .banner {
        display: inline-flex;
        flex-grow: 1;
        align-items: center;
        align-self: center;
        width: 100%;
        height: 17vh;
        max-width: 950px;
        padding: 6% 6% 9% 20%;
        font-family: $main-sans-serif;
        font-size: 1rem;
        background-image: url('~assets/images/bubble.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        p {
            margin: auto 1.5rem;
            margin-left: auto;
            color: $dark-gray-2;
        }
        button.btn {
            align-self: center;
            justify-self: flex-end;
            margin: auto 0 auto auto;
            padding: 0.5rem 1rem;
            border-width: 2px;
        }
    }
    @media screen and (max-width: $breakpoint-desktop - 1) {
        .banner {
            height: 180px;
            margin-bottom: 50px;
        }
    }
    @media screen and (max-width: $breakpoint-tablet - 1) {
        .banner {
            height: 27vh;
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column-reverse;
        height: auto;
        .character {
            width: auto;
            height: 30vh;
            max-height: 300px;
        }
        .banner {
            flex-direction: column;
            width: 95vw;
            height: auto;
            max-width: unset;
            min-height: 350px;
            margin-bottom: 0;
            padding: 2% 25% 2%;
            background-image: url('~assets/images/bubble-vertical.png');
            p {
                margin: 3rem 1.5rem 1.5rem !important;
            }
            button.btn {
                justify-self: center;
                margin: 0 auto 1rem;
            }
        }
    }
    @media screen and (max-width: $breakpoint-mobile-xs) {
        .banner {
            min-height: 400px;
        }
    }
    @media screen and (max-width: 600px) {
        .character {
            display: none;
        }
        .bubble-wrapper {
            max-width: 96vw !important;
        }
        .banner p {
            width: 100%;
        }
    }
    @media screen and (max-height: 600px) and (max-width: 600px) {
        top: 1.5rem;
        bottom: unset;
        .banner {
            p {
                width: 80%;
                margin: 10vh auto;
            }
        }
    }
    @media screen and (max-height: 350px) and (max-width: 600px) {
        .banner {
            width: 100vw;
            height: 100vh;
            min-height: unset;
            padding: 0;
            background: white;
        }
    }
}
