@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.modal-login-expired {
    text-align: center;
    .o-modal__content {
        z-index: 15000; // override other .modal-dialogs
    }
    .errors-quote {
        min-height: unset;
        padding: 0;
        button {
            min-width: 9rem;
            margin: 1rem auto;
            padding: 0.2rem 1rem;
        }
        .columns {
            box-shadow: none;
        }
        .message-panel {
            .session-expired-options {
                display: inline-flex;
                flex-direction: column;
                .btn.login {
                    margin: 1rem auto;
                    font-size: 1.25rem;
                }
                .btn.close {
                    margin: 3rem auto 0;
                    padding: 0;
                    font-size: 0.9rem;
                    text-decoration: underline;
                    border: 0;
                }
            }
            .status {
                display: inline-flex;
                flex-direction: column;
                font-size: 0.75rem;
            }
        }
        .redirect-message {
            font-size: 1.75rem;
        }
        .flex-block {
            display: inline-flex;
            min-height: 3vh;
        }
    }
}
