@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.social.do-save {
    height: 38px;
    &.round {
        width: 40px;
        height: 40px;
        border-radius: 2rem;
        img {
            width: 40px;
            padding: 8px;
        }
        .material-design-icon {
            width: 24px;
            height: 24px;
        }
    }
    &.transparent {
        background: rgba(255, 251, 251, 0.15);
        &:hover {
            background: rgba(255, 251, 251, 0.75);
        }
    }
}
