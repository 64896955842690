@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.user-image-archive-masonry {
    display: flex;
    flex-wrap: wrap;
    figure {
        cursor: pointer;
    }
    .masonry-wrapper {
        .grid-sizer {
            width: 300px; // CanvasX + margin
        }
    }
    .artwork-card.card {
        .card-header, .card-contents {
            display: none;
        }
    }
    .social-button-wrapper {
        display: none;
    }
    .image-not-available {
        display: none;
    }
}
