@import "~assets/styles/mixins.scss";

.spinner {
    position: static;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: auto;
    height: auto;
    margin: 2rem auto;
    user-select: none;
    background-color: transparent;
    img {
        width: 120px;
        height: 120px;
        animation: spin 1s infinite linear;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}
