// @import '~assets/styles/mixins';

.video-card {
    display: flex;
    flex-grow: 1;
    margin: 0;
    font-family: $helvetica-roman;
    color: white !important;
    background: black !important;
    .card-header-title, .title {
        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        font-size: inherit;
        color: $medium-gray-3;
    }
    h1, h2 {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: initial;
    }
    .label {
        color: inherit;
    }
    .card-media {
        flex-grow: 1;
        overflow: hidden;
    }
    .card-image {
        z-index: 100;
        flex-grow: 0;
        width: 100%;
        height: 250px;
        figure {
            height: inherit !important;
        }
    }
    .card-header,
    .card-contents {
        z-index: 4000; // lower than submenu dropdown
        width: 100% !important;
        color: $medium-gray-3;
        background: black;
        .title {
            color: $medium-gray-3;
        }
    }
    .card-header {
        flex-grow: 0;
        padding: 0.375rem 0.5rem !important;
    }
    .card-contents {
        flex-grow: 2;
        justify-content: flex-start;
        padding: 0.5rem 1rem 1.5rem;
        color: inherit;
        background: black;
        & > *:first-child {
            margin-top: 0;
        }
    }
    .card-link {
        height: 100% !important; // override .card-image fixed height
    }
    .caption,
    .ingress-text,
    .article-links {
        margin: 1.5rem 4rem 1.5rem 1rem;
    }
    .caption,
    .ingress-text {
        color: $medium-gray-3;
    }
    .caption {
        font-family: $sabon-bold;
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 1;
        letter-spacing: 0px;
        color: $medium-gray-2;
    }
    .ingress-text {
        @include fade-read-more(280px, true);
        min-height: 80px;
        font-family: $helvetica-thin !important;
        font-size: 1.15rem;
        line-height: normal;
        letter-spacing: 1px;
        color: $light-gray-2;
        white-space: initial;
    }
    .social-button-wrapper {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        display: none;
        margin-left: auto;
        .social.do-heart {
            cursor: not-allowed;
        }
        .material-design-icon {
            width: 30px;
            height: 30px;
        }
    }
    .placeholder.material-design-icon {
        position: absolute;
        top: 75px;
        left: 44%;
        width: 64px;
        height: 64px;
        color: white;
        background: radial-gradient(rgba(255, 22, 34, 0.85) 0%, rgba(221, 128, 22, 0.85));
        border-radius: 32px;
    }
    .failure {
        width: 100%;
        text-align: center;
        .material-design-icon {
            width: 64px;
            height: 64px;
            margin: 2rem auto;
            color: white;
        }
    }
}
