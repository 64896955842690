@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.modal-terms-of-service {
    &.modal-dialog {
        top: 50px;
        height: 86vh !important;
        padding-right: 1rem;
        padding-bottom: 3.5rem;
        box-shadow: 0 2px 8px $dark-gray-4;
        .o-modal__content {
            overflow-y: scroll;
        }
    }
    .print-this-page {
        display: block;
        margin: 0 0 0 auto;
        text-align: right;
        .material-design-icon {
            width: 22px;
            height: 22px;
            margin: 0 0.5rem 0 0;
        }
        .lbl {
            font-family: $main-sans-serif;
            vertical-align: top;
        }
    }
    .contents {
        margin-right: 1rem;
        padding-left: 1rem;
        color: $dark-gray-2;
    }
    .title {
        margin: 0 3rem 2.5rem !important;
        font-family: $helvetica-bold;
        font-size: clamp(1.5vw, 2rem, 3.5vw);
        line-height: 1.5;
        color: inherit;
        text-align: center;
        word-break: break-word;
    }
    .para-title {
        margin: 2rem 0 0;
        font-family: $helvetica-bold;
        font-size: clamp(1.2vw, 1.25rem, 3vw);
        line-height: 1.5;
        color: inherit;
        text-align: left;
        word-break: break-word;
    }
    .para-text {
        margin: 0.5rem 0 0;
        font-family: $main-sans-serif;
        font-size: clamp(1vw, 1rem, 2.5vw);
        line-height: 1.75;
        color: inherit;
        text-align: left;
        word-break: break-word;
        white-space: pre-line;
    }
    .btn {
        display: block;
        margin: 0.5rem auto 1rem 1.5rem !important;
        &.button.is-loading::after {
            top: 8px !important; // spinner
        }
    }
    .dx-scrollview-scrollbottom-indicator {
        display: none; // for some reason the load indicator keeps spinning
    }
}
