// @import '~assets/styles/variables';

.artwork-sidebar {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 0;
    padding-bottom: 0.75rem;
    padding-left: 1rem !important;
    border-left: 1px solid black;
    .makers {
        flex-direction: column;
        .lifetime {
            text-align: left;
        }
        .artworks-counted {
            display: none;
        }
    }
    .card {
        align-self: flex-start !important;
        .card-header {
            text-align: left;
            .card-link {
                text-decoration: none;
            }
        }
        .card-header-title h2.title {
            // Actor name
            font-family: 'Sabon MT W01 Semibold', serif;
            font-weight: normal;
            font-size: 1.33em;
            line-height: normal;
        }
        .social {
            margin: 0.75rem auto 1rem 0;
            padding-right: 3em;
            padding-left: 3em;
            font-size: 0.8rem !important;
        }
        .ingress-text {
            display: none;
        }
        .maker-role {
            font-family: 'Sabon MT W01 Regular', serif;
            font-weight: normal;
            font-size: 1rem;
            color: $dark-gray-4;
        }
    }
    .actor-card .card-header {
        align-items: flex-start;
    }
    .collections, .title {
        h2 {
            font-size: 1.25em;
        }
    }
    .topic {
        flex-direction: column;
        margin: 0;
        clear: both;
        font-family: $main-sans-serif;
        font-weight: normal;
        font-size: 1.15rem;
        line-height: 1.4;
        letter-spacing: 0;
        text-align: left;
        .title {
            margin: 1rem 0 0.5rem;
            // Artwork title
            font-family: 'Sabon MT W01 Semibold', serif;
            font-weight: normal;
            font-size: 1.33rem;
            line-height: normal;
            color: $dark-gray-2;
            a, a:hover {
                color: inherit;
            }
        }
        .details, .credits, .owner {
            margin-bottom: 1rem;
            color: $dark-gray-3;
        }
        .technique {
            // some spacing for classification and technique
            line-height: 1.5;
        }
        .production-timespan {
            margin-bottom: 1rem;
        }
        .owner {
            margin-bottom: 0.5rem;
        }
        .keeper {
            margin: 1rem 0;
        }
    }
    .collections .title {
        margin-top: 0;
    }
    .purchasing {
        flex-direction: column;
        margin-top: 2rem;
        h4 {
            font-family: $helvetica-roman;
            font-size: 1rem;
            color: black;
        }
        .price {
            font-family: $helvetica-bold;
            font-size: 1.25rem;
        }
        .btn {
            width: 100%;
            max-width: 12rem;
            margin: 5px 0;
            padding-right: auto;
            padding-left: auto;
            text-transform: none;
            cursor: not-allowed;
            &.buy {
                font-family: $helvetica-bold;
                font-size: 1.125rem;
                color: white;
                background: black;
            }
            &.details {
                font-family: $helvetica-light;
                font-size: 0.875rem;
                color: white;
                background: $dark-gray-3;
            }
        }
        .seller {
            margin-top: 2rem;
            font-family: $main-serif;
            font-size: 1rem;
            color: #5c5b5b;
        }
    }
    .extras {
        flex-direction: column;
    }
    .attribution {
        .credit-line,
        .copyright {
            width: 100%;
            &, .dx-texteditor-container {
                margin: 0 auto 0 0;
                font-family: $helvetica-roman;
                font-size: 0.875rem;
                color: $dark-gray-4;
                text-align: left;
            }
        }
    }
}
