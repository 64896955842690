@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#organizations-index {
    .hero {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: 56vh;
        min-height: 56vh;
        margin-bottom: 1rem;
    }
    .query-options {
        margin: 0 $main-content-margin 1rem;
    }
    .card {
        .card-header header {
            display: inline-flex;
        }
        .social-button-wrapper {
            display: inline-block;
        }
        .social {
            display: flex;
            min-width: unset;
            margin: 0.25rem 0 auto;
            margin-left: auto !important;
        }
    }
    .slides-organizations {
        .card-image {
            height: 45vh;
        }
    }
    .slides-foundations {
        .slick-track {
            height: auto;
        }
        .card {
            margin-bottom: 1rem;
        }
        .card-image {
            height: 45vh;
        }
    }
    .slides-companies {
        .card-image {
            height: 45vh;
            @media screen and (max-width: $breakpoint-tablet - 1) {
                width: auto;
            }
        }
    }
    .slides-privates {
        .card-image {
            height: 50vh;
            @media screen and (max-width: $breakpoint-tablet - 1) {
                width: auto;
            }
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        .card {
            margin-bottom: 1rem;
        }
    }
}
