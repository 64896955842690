@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.site-footer {
    display: flex;
    flex-direction: column; // default for small screens, media rule for wide
    @media screen and (max-width: $breakpoint-tablet - 1) {
        flex-direction: row;
    }
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    width: $main-content-max-width;
    margin: 2rem 0 0 $main-content-margin;
    padding: 0;
    font-family: "Sabon MT W01 Regular", serif;
    font-weight: 400;
    color: black;
    user-select: none;
    background: transparent;

    section.is-parent {
        display: flex;
        flex-direction: row;
        margin: 2rem 0 auto;
        &.first-section {
            flex-direction: row;
            @media screen and (max-width: $breakpoint-mobile) {
                flex-direction: column;
            }
            flex-wrap: nowrap; // logo and copy flow
            margin-right: $main-content-margin;
            margin-bottom: 0; // logo tile has large margin
        }
        &.second-section {
            flex-grow: 0;
            margin-right: auto;
            margin-left: auto;
        }
        &.third-section {
            justify-content: flex-end;
            margin-left: auto;
        }
        .logo-tile {
            display: inline-flex;
            flex-grow: 1;
            margin-right: $main-content-margin;
            margin-bottom: $main-content-margin;
            .logo {
                width: 210px;
                height: 100%;
                background: transparent;
            }
        }
        .footer-info,
        .footer-service {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: baseline;
            text-align: left;
        }
        .associates {
            display: inline-flex;
            flex-wrap: wrap;
            margin-left: $main-content-margin;
            img {
                width: auto;
                height: 55px;
                margin: 1rem 21px 0;
                vertical-align: top;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    h3 {
        margin-bottom: 0.5rem;
        font-family: $helvetica-roman;
        font-size: 1rem;
        text-transform: uppercase;
    }
    a {
        font-size: 1rem;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }
    .copy {
        margin-bottom: 1rem;
        font-size: 1rem;
    }
}
