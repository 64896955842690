@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.social.do-promote {
    height: 38px;
    padding: 0;
    span {
        vertical-align: middle;
    }
}
