@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';
@import './Sidebar';

#artwork-wizard {
    .contents {
        display: grid;
        grid-template-areas:
            "figure sidebar"
            "descriptions sidebar";
        grid-template-rows: auto auto;
        grid-template-columns: 400px auto;
    }
    .mousetrigger {
        width: max-content;
        overflow: auto;
    }
    .artwork-details.main-image {
        grid-area: figure;
        width: 100%;
        height: auto;
        max-width: calc(90vw - 400px) !important;
        .artwork .image {
            max-width: 100% !important;
        }
    }
    .side-column {
        grid-area: sidebar;
        width: auto;
        margin-right: $main-content-margin;
    }
    .descriptions {
        grid-area: descriptions;
        max-width: 550px;
        margin-right: 2.5rem;
    }

    .makers {
        display: inline-flex;
        margin-bottom: 0.5rem;
    }
    .makers-select,
    .classification-select {
        &.dx-selectbox {
            width: max-content;
            margin-bottom: 1rem;
        }
    }
    .artwork-sidebar {
        .topic {
            margin-top: 1rem;
            .title {
                margin-top: 0 !important;
                input {
                    padding-right: 2rem;
                }
            }
        }
    }
    .dx-icon.flag {
        position: relative;
        left: 44px;
        width: 37px;
        height: 25px;
        margin: 2px;
    }
    .dx-texteditor-container {
        overflow: visible;
    }
    .dx-htmleditor {
        min-width: unset !important;
    }
    .dx-textbox,
    .dx-numberbox {
        margin-bottom: 0.15rem;
        font-size: 1.2rem;
    }
    .dx-button {
        margin-top: 0.5rem;
    }
    .production-timespan {
        margin-top: 1rem;
        .row {
            display: inline-flex;
        }
        .timespan-dash {
            margin: auto 1rem;
            span {
                vertical-align: super;
            }
        }
        .mousetrigger {
            overflow: initial;
        }
    }
    .geometry-select {
        margin: 1rem 0 0.75rem;
        .row {
            display: flex;
            align-items: baseline;
            .edit-field-label {
                margin: auto 1rem;
                font-weight: normal;
            }
            .dx-numberbox {
                margin-left: auto;
                .dx-texteditor-input {
                    text-align: left;
                }
            }
        }
    }
    .contributors {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin: 1.5rem 0 1rem;
        .row {
            display: flex;
            align-self: left;
            .dx-textbox {
                margin-right: 0.25rem;
            }
        }
        // stylelint-disable-next-line no-descending-specificity
        .edit-field-label {
            align-self: left;
            margin-bottom: 0 !important;
        }
    }
    .add-contributor {
        display: inline-flex;
        margin: 0.5rem 1rem 0;
        cursor: pointer;
        .material-design-icon {
            width: 18px;
            height: 18px;
        }
        .label {
            // similar label with last-modified "add editor"
            margin-left: 0.5rem;
            font-family: $helvetica-thin;
            font-weight: 700;
            font-size: 1rem;
            user-select: none;
        }
    }
    .credits, .on-sale {
        margin-top: 1rem;
    }

    .publish-actions {
        .dx-button {
            margin: 0 1rem 1rem 0;
        }
        .dx-validationsummary {
            margin-bottom: 1rem;
        }
    }

    @media (max-width: 925px) {
        .contents {
            grid-template-areas:
                "figure"
                "sidebar"
                "descriptions";
            grid-template-rows: auto auto auto;
            grid-template-columns: auto;
        }
        .artwork-details.main-image {
            width: calc(100vw - 90px);
            height: auto;
            max-width: unset !important;
        }
        .side-column {
            width: 80vw;
        }
        .artwork-sidebar {
            width: auto;
            margin-right: $main-content-margin;
            padding-left: 0 !important;
            border-left: 0;
        }
        .descriptions {
            margin-right: unset;
        }
    }

    @media (min-width: 1450px) {
        .contents {
            grid-template-columns: auto;
        }
        .side-column {
            width: auto;
        }
    }
}


.classification-preview {
    height: 40vh;
    .dx-scrollable {
        height: 40vh;
    }
    .dx-scrollview-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .classification-icon {
        display: inline-flex;
        flex-direction: column;
        width: 120px;
        margin: 1rem;
        text-align: center;
        .color-box {
            display: block;
            align-self: center;
            width: 64px;
            height: 64px;
            padding: 1rem;
            cursor: pointer;
            background: url('~assets/images/retroflowers.svg');
            background-size: cover;
            background-blend-mode: color-burn;
            border-radius: 10px;
        }
    }
}


.geometry-preview {
    padding: 0 1rem 1rem;
    text-align: center;
    .grid-container {
        display: inline-grid;
        grid-template-rows: auto;
        grid-template-columns: auto;
    }
    .item {
        grid-row-start: 2;
        grid-column-start: 1;
        width: 280px;
        height: 100%;
        margin: 1.5rem;
    }
    .width {
        grid-row-start: 1;
        grid-column-start: 1;
        justify-self: center;
    }
    .height {
        grid-row-start: 2;
        grid-column-start: 2;
        align-self: center;
    }
}

.dx-overlay-content {
    overflow: hidden;
}
