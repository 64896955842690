/**
 *  App styles base.
 *
 *  DevExtreme styles are imported at the plugin.
 *  Other 3rd party package styles should be imported at the component.
 *
 **/

// Bulma
@import 'bulma/css/bulma.css';

// fonts
@import '~assets/styles/fonts';

// themes
@import '~assets/styles/themes';


*, *::before, *::after {
    box-sizing: inherit;
}
* {
    outline-color: transparent;
    &::-moz-focus-inner {
        border: 0;
    }
}

@-ms-viewport {
    width: device-width;
}

html {
    box-sizing: border-box;
    width: 100% !important;
    height: initial !important;
    min-width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
    background-color: $light-gray-3;
}
body {
    height: initial !important;
    min-width: inherit;
    min-height: inherit;
    margin: 0;
    padding: 0;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    font-family: 'Sabon MT W01 Regular', serif;
    text-align: center;
    &.loading {
        display: none;
    }
}
.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    max-width: 100vw !important;
    // min-height: 100vh;
    margin: 0 auto;
    // margin-bottom: $main-content-margin;
    padding: 0;
    .content-page {
        max-width: $main-content-max-width;
        margin: 0 $main-content-margin;
        padding: 0;
        .image-not-available {
            width: 100%;
        }
    }
    // adjustment for mobile - narrow margin
    @media screen and (max-width: $breakpoint-tablet - 1) {
        .content-page {
            width: calc(100vw - 3em);
            max-width: unset;
            margin-right: $mobile-content-margin;
            margin-left: $mobile-content-margin;
            .slick-next {
                right: -5px;
            }
            .slick-prev {
                left: -5px;
            }
        }
    }
    .section {
        margin: 0;
        padding: 0;
    }
    .fullwidth-section {
        // show background over the margin
        margin-right: - $main-content-margin;
        margin-left: - $main-content-margin;
        padding-right: $main-content-margin;
        padding-bottom: 1rem;
        padding-left: $main-content-margin;
    }
}

// main segment titles
.content-page > .segment,
.query-options > .segment {
    .segment-title {
        h2 {
            font-size: 3em;
            font-size: clamp(28px, 3em, 45px);
        }
    }
}

/** elementals */
/* stylelint-disable no-descending-specificity */
a, a:hover {
    color: $light-purple;
    text-decoration: none;
    background: transparent;
    h1:hover, h2:hover, h3:hover, h4:hover {
        color: inherit;
    }
    &.disabled {
        pointer-events: none;
        cursor: default;
    }
}
.button.is-loading {
    display: inline-flex;
    align-content: center;
    height: auto;
    background: transparent;
    border: 0;
    border-style: none;
    outline-color: transparent;
    &::after {
        position: relative;
        top: unset !important;
        margin: auto;
        vertical-align: center;
        border-color: $blue;
        border-top-color: transparent;
        border-right-color: transparent;
        outline-color: transparent;
    }
}
.btn, button.btn {
    display: inline-block;
    align-content: center;
    align-self: baseline;
    min-height: 36px;
    margin: auto;
    padding: 0 1.5rem;
    font-family: $helvetica-roman;
    font-weight: normal;
    font-size: 1rem;
    line-height: unset;
    text-align: middle;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background: transparent;
    border: 2px solid black;
    border-radius: 0;
    outline-color: transparent;
    span {
        align-self: center;
    }
    a {
        color: inherit;
    }
    & > div {
        margin: 0.25rem auto;
        &.button.is-loading::after {
            top: calc(50% - (1.65em / 2));
        }
    }
    &:hover {
        text-decoration: underline;
        background: rgba(255, 251, 251, 0.1) !important;
        box-shadow: 0 2px 0.75rem $light-gray-2;
    }
    &:focus {
        outline: none;
    }
    &::-moz-focus-inner {
        border: 0;
    }
    &.disabled, &.not-allowed {
        cursor: not-allowed;
    }
    &.busy {
        cursor: wait !important;
    }
    &.gray {
        color: black;
        &:hover {
            background: $medium-gray-3 !important;
        }
    }
    &.black {
        background: black;
        &, .label {
            color: white;
        }
        &:hover {
            background: $dark-black !important;
        }
    }
    &.transparent {
        color: white;
        background: transparent;
        border: 2px solid white;
    }
    &.save, &.cancel {
        min-width: 150px;
    }
    &.login-button, &.register-button {
        display: inline-flex;
        min-height: unset;
        margin: auto 0;
        padding: 0.2em 1em;
        font-family: $helvetica-bold !important;
        font-weight: normal;
        font-size: 1rem;
        line-height: normal;
        color: $light-gray;
        cursor: pointer;
        background-color: transparent;
        border-color: $medium-gray-3 $medium-gray-3 $light-gray $light-gray;
        border-style: solid;
        border-width: 1px;
    }
}
.login-button-wrapper,
.register-button-wrapper {
    &.inverse-colors {
        button {
            margin: 1.5rem auto 0.75rem !important;
            padding: 0.37rem 1.7rem !important;
            font-size: 1.25rem !important;
            color: $dark-gray !important;
            background-color: $light-gray !important;
            border-color: $dark-gray-2 $dark-gray-2 $dark-gray $dark-gray !important;
            border-style: solid;
            border-width: 1px;
            transition: box-shadow, background-color 0.6s ease-out;
            &:hover {
                color: $medium-gray-3;
                background-color: #c2bfbf !important;
                border-style: solid;
                border-width: 1 px;
                box-shadow: 2px 2px 3px #6e6e6e;
            }
        }
    }
}
section {
    font-size: 1rem;
    text-align: left;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
}
hr {
    display: block;
    width: 40px;
    height: 3px;
    margin: 5px auto 10px 0;
    overflow: hidden;
    background-color: black;
    border: 0;
}
.hr-break {
    display: inline-flex;
    width: 100%;
}
ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
}
.row, .columns {
    margin: 0;
    padding: 0;
}
$option-icon-size: 20px;
$option-padding: 10px;
figure {
    margin: 0;
    .hover-only {
        opacity: 0.05;
        transition: opacity 800ms cubic-bezier(0.45, 1.01, 0.58, 1);
    }
    &:hover .hover-only {
        opacity: 1;
        transition: opacity 1800ms cubic-bezier(0.45, 1.01, 0.58, 1);
    }
}
pre {
    text-align: left;
}
.column {
    margin: 0;
    padding: 0;
    &.is-left {
        margin-right: auto;
        .option:first-child {
            margin-left: 0;
        }
        .option {
            margin-right: $option-padding;
        }
    }
    &.is-right {
        margin-left: auto;
        .option:last-child {
            margin-right: 0;
        }
        .option {
            margin-bottom: $option-padding;
            margin-left: $option-padding;
            @media screen and (max-width: $breakpoint-mobile) {
                margin-left: 0;
            }
        }
    }
}
.tile {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: stretch;
    min-height: fit-content;
    &.is-ancestor {
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.is-parent {
        padding: unset;
    }
}
.card {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    a {
        width: inherit;
        height: inherit;
    }
    .card-header {
        margin: 0;
        padding: 0;
        background: transparent;
        border: 0;
        box-shadow: none;
    }
    .card-content {
        margin: 0;
        padding: 0;
        border: 0;
    }
    .card-contents {
        flex-direction: column;
    }
    .card-image .image {
        background-size: cover;
    }
}
.social-button-wrapper {
    display: inline-flex;
    border: 0;
}
.social {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 0;
    font-family: $helvetica-roman;
    font-weight: normal;
    font-size: 1rem;
    line-height: unset;
    letter-spacing: 0;
    color: $dark-gray;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 0px solid $medium-gray-2;
    @include transition-bezier(all);
    &:hover {
        background-color: white;
    }
    & > div {
        display: inline-flex;
        align-self: center;
        margin: 0.25rem auto;
    }
    img {
        top: 1px;
        box-sizing: border-box;
        width: 48px;
        height: auto;
        margin: 2px;
        padding: 4px 12px 0;
    }
    .material-design-icon {
        width: 48px;
        height: 30px;
        padding: 0;
    }
    .is-loading.button {
        display: flex;
        width: 48px;
        height: 30px;
        margin: 0 auto;
        padding: 0;
    }
    &.help-cursor {
        cursor: help;
    }
    &.borderless {
        border-width: 0;
    }
    &.thin {
        min-width: unset;
        font-family: $helvetica-light;
        font-size: 0.6rem !important;
        letter-spacing: 1px;
        border-width: 1px !important;
    }
    &.transparent {
        transition: background-color 0.5s ease-out;
        transition: border-color 0.5s ease-out;
        transition: color 0.5s ease-out;
    }
    &.disabled, &.not-allowed {
        cursor: not-allowed;
    }
    &.busy {
        cursor: wait;
    }
    .orange {
        color: #ff702a;
    }
    .purple {
        color: $light-purple;
    }
    .red {
        color: #ce2631;
    }
    .blue {
        color: #176fc8;
    }
    .green {
        color: #6a9f46;
    }
    .yellow {
        color: #ffbf40;
    }
    .black {
        color: #272524;
    }
}
.social-tooltip {
    &.error {
        margin: 0 0.5rem 0 0;
    }
}
span.label {
    display: inline;
}
.edit-field-label {
    font-family: $helvetica-medium;
    font-weight: normal;
    font-size: 1rem;
    color: $dark-gray-2;
}
.page-text {
    max-width: 90vw;
}
.query-options {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    .column {
        display: inline-flex;
        flex-wrap: wrap;
    }
    .query-option {
        height: min-content;
        & > * {
            align-self: flex-start;
        }
        & > span:nth-child(2) {
            align-self: center;
        }
        .magnify-icon {
            align-self: auto;
        }
    }
}
.display-options,
.section-toolbar {
    position: relative;
    display: inline-flex;
    align-self: center;
    justify-content: flex-end;
    margin: 0 0 0 auto;
    & > div {
        align-self: center;
    }
    & > :last-child {
        margin-right: 3px; // fine tune
    }
    @media screen and (max-width: $breakpoint-tablet - 1) {
        position: initial;
        margin-right: 0;
    }
}
.column .option, // aka query option
.query-options .option,
.query-option,
.sort-option {
    display: inline-flex;
    align-items: center;
    min-width: 205px;
    margin: 0 1rem 1rem 0;
    padding: 0.4rem $option-padding;
    font-family: $helvetica-bold;
    font-size: 0.875rem;
    line-height: 1;
    color: black;
    text-align: left;
    cursor: pointer;
    user-select: none;
    background: white;
    border: 1px solid $medium-gray;
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: ease-in;
    &.disabled {
        cursor: not-allowed;
    }
    &.inactive {
        color: black;
        background: white;
        &:hover {
            background: $light-gray-2;
        }
    }
    &.by-name {
        min-width: $mobile-absolute-max-width;
    }
    .material-design-icon {
        width: $option-icon-size;
        height: $option-icon-size;
        margin-right: $option-padding;
        padding: 0;
        &:last-child {
            margin-right: 0;
            margin-left: auto; // align drop-down arrow right
        }
    }
    .fa-icon.icon.is-small {
        width: $option-icon-size;
        height: $option-icon-size;
        margin-right: $option-padding;
        padding: 2px;
    }
}
.sort-option {
    color: white;
    background: black;
}
.image-tool-icons {
    position: relative;
    top: -36px;
    right: -36px;
    z-index: 5000;
    display: inline-flex;
    flex-direction: column;
    float: right;
    .material-design-icon {
        width: 24px;
        height: 24px;
    }
    .disabled {
        display: none;
    }
}
.section-toolbar-item {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 180px;
    margin-left: 1.5rem;
    font-family: $main-sans-serif;
    color: $dark-gray-2;
    cursor: pointer;
    & > a {
        display: inline-flex;
        align-items: center;
    }
    &:first-child {
        margin-left: 0;
    }
    .material-design-icon {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        color: $dark-gray-2;
    }
}
.display-option {
    align-self: center;
    margin-right: 2rem;
    margin-left: auto;
    font-size: 1rem;
    letter-spacing: -1px;
    color: inherit;
    text-align: right;
    a {
        color: inherit;
    }
    svg {
        width: 18px !important;
        height: 18px !important;
        margin: 4px;
    }
    span {
        display: inline-flex;
        vertical-align: top;
    }
    span.label {
        margin-left: 8px;
        text-decoration: none;
    }
}

/**
** title bar for videos and podcasts with icon and duration
*/
.multimedia-title {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 1rem 0;

    h2.title {
        font-weight: normal;
        line-height: 1;
        color: inherit;
        white-space: normal !important;
    }

    &.event-title {
        flex-direction: row-reverse;
    }
    &.podcast-title {
        flex-direction: row-reverse;
        color: $dark-gray-2;
    }
    &.video-title {
        flex-direction: row-reverse;
        color: $medium-gray-3;
        h2.title {
            font-family: $helvetica-bold;
            font-weight: normal;
            // font-family: $helvetica-thin;
            font-size: 1.25rem;
            // letter-spacing: 1px;
            color: $light-gray;
        }
    }
    .podcast-title-icon,
    .video-title-icon,
    .event-class-title-icon {
        display: inline-flex;
        flex-direction: column;
        align-self: flex-start;
        min-width: 3rem;
        margin-left: 1rem;
        .material-design-icon {
            align-self: flex-end;
            width: 22px;
            height: 22px;
            color: inherit;
        }
        .duration {
            font-family: $helvetica-roman;
            font-size: 12px;
            color: inherit;
            text-align: right;
        }
    }
}

.credit-line, .copyright {
    font-family: $helvetica-roman;
    font-size: 0.875rem;
    color: $dark-gray-2;
}

.image-not-available {
    display: inline-flex;
    flex-direction: column;
    width: auto;
    height: inherit;
    padding: 4rem 2rem;
    font-family: $main-serif;
    font-style: italic;
    font-size: 1rem;
    color: black;
    text-align: center;
    vertical-align: middle;
    background: $light-gray;
    img {
        align-self: center !important;
        width: auto !important;
        height: auto !important;
        max-width: 100px !important;
        margin: auto auto 0 auto !important;
    }
    div {
        margin: auto auto 0 auto !important;
    }
}
.disabled {
    opacity: 0.25;
    &, input, li, a, .material-design-icon {
        cursor: not-allowed !important;
    }
}
.error {
    margin: 2rem $main-content-margin;
    color: $error;
}
.content-page.search-page {
    .page-images-select {
        display: none !important;
    }
}

/** inputs */

input[type="range"] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 251, 251, 0);
    width: 100%;
    height: 20px;
    margin: 0;
    padding: 1px 2px;
    background: transparent;
    border: none;
    border-radius: 14px;
    outline: none; /* no focus outline */
}
input[type="range"]::-webkit-slider-runnable-track {
    background: $dark-gray-2;
    border: inherit;
}
input[type="range"]::-moz-range-track {
    background: $dark-gray-2;
    border: inherit;
}
input[type="range"]::-ms-track {
    color: transparent; /* don't drawn vertical reference line */
    background: $dark-gray-2;
    border: inherit;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: transparent;
}
input[type="range"]::-ms-tooltip {
    display: none;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 40px;
    height: 18px;
    background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);
    border: none;
    border-radius: 12px;
}
input[type="range"]::-moz-range-thumb {
    width: 40px;
    height: 18px;
    background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);
    border: none;
    border-radius: 12px;
}
input[type="range"]::-ms-thumb {
    width: 40px;
    height: 18px;
    background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);
    border: 0;
    border-radius: 12px;
}

/** Material icons */

.material-design-icon {
    & > .material-design-icon__svg {
        bottom: unset !important;
        width: inherit !important;
        height: inherit !important;
    }
    &:focus-visible {
        border: none;
        outline: none;
    }
}

/** WYSIWYG */

.no-toolbar > .editr--toolbar {
    display: none;
}

/** Draggable */

.draggable-ghost {
    border: 2px dashed $blue;
    opacity: 0.25;
}

/** develop feature */

$dev-gradient: repeating-linear-gradient(
    -14deg,
    rgba(255, 193, 7, 0.9),
    rgba(255, 193, 7, 0.5) 4px,
    rgba(255, 193, 7, 0) 8px,
    rgba(0, 0, 0, 0) 12px,
    rgba(0, 0, 0, 0) 16px
);

.develop-feature {
    display: none !important;
    // striped background effect with translucent yellow
    //background: $dev-gradient !important;
}
.main-navigation .navbar-item.develop-feature {
    color: $yellow !important;
    background: transparent !important;
}
.develop-feature-section {
    display: none !important;
    border-style: solid;
    border-width: 5px;
    border-image-source: $dev-gradient;
    border-image-slice: 1;
}

// Mouseflow
#mouseflow .mf-widget-powered {
    visibility: hidden !important;
}
