@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.article-para-part {
    display: inline-block;
    width: 75vw;
    margin: 1rem auto 0.5rem;
    padding: 0 1rem;
    vertical-align: text-top;
    .page-text {
        font-size: 1.4rem;
        text-align: left;
    }
    @media screen and (max-width: $breakpoint-mobile) {
        padding: 0;
    }
}
