@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.search-input-text {
    display: flex;
    flex-grow: 1;
    margin: 0;
    margin-bottom: 20px;
    .dx-texteditor-input-container {
        align-items: baseline;
        font-family: $sabon-roman;
        font-size: 1.5rem;
        line-height: 2;
    }
    input, .dx-texteditor-input {
        display: inline-flex;
        height: auto;
        padding: 0 3rem 0 1rem !important;
        line-height: 2 !important;
        border: 1px solid $medium-gray-3;
        border-color: $medium-gray-3;
        &:focus {
            border-color: $medium-gray;
            outline: none;
            box-shadow: none;
        }
    }
    .dx-textbox {
        width: 100%;
        height: 50px; // for IE
        height: min-content;
    }
    .dx-placeholder {
        display: inline-flex;
        align-self: flex-start;
        padding: 0 3rem 0 1rem;
        font-family: $sabon-roman;
        font-size: 1.5rem;
        line-height: 2;
        color: $medium-gray-2;
        &::before {
            padding: 0 !important;
        }
    }
    .material-design-icon {
        position: relative;
        z-index: 10;
        display: inline-flex;
        align-self: center;
        width: 32px;
        height: 32px;
        margin-top: 0.5rem;
        color: $dark-gray-2;
        cursor: pointer;
        transform: translateX(-45px);
        &.magnify-icon {
            align-self: baseline;
            margin-top: 10px;
        }
    }
}
