@import "~assets/styles/mixins.scss";

/** .login-button style set in app.scss */

// transparent login-popover dx-overlay
// (requires devextreme v21.2 to set unique class via v-bind:wrapper-attr)
.dx-popover-wrapper.dx-overlay-wrapper.login-popover-wrapper {

    // z-index: 7000 !important; // should not overlap navbar language dropdown
    //                              although the tooltip popover SHOULD overlap navbar

    .dx-overlay-content {
        background: transparent;
        box-shadow: none;
        .dx-popover-arrow {
            display: none !important;
        }
    }
    .popover-message {
        display: inline-flex;
        flex-direction: column;
        max-width: 400px;
        font-size: 1rem;
        text-align: center;
        .silhouette-assistant {
            margin: -10px 0 -10px -10px; // dx-popover adds padding
            .bubble-wrapper {
                align-self: flex-start;
                min-height: 0;
                margin-left: 1rem;
                padding-top: 11%;
                padding-bottom: 11%;
                div {
                    margin-bottom: 1rem;
                }
            }
        }
        button {
            margin-top: 1rem;
        }
    }
}
