@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.organization-collections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .card {
        flex-basis: auto;
        flex-direction: row;
        flex-grow: 1;
        width: 44vw;
        min-height: 25vh;
        min-height: min-content;
        margin: 10px 10px !important;
        padding: 0;
        text-align: center;
        h4 {
            margin-bottom: 1rem;
            font-family: $helvetica-roman;
            font-size: 1.575rem;
            line-height: 2.2rem;
            letter-spacing: 0.05em;
            color: $medium-gray-3;
        }
        @media screen and (max-width: $breakpoint-tablet - 1) {
            flex-direction: column;
        }
        .card-contents {
            justify-content: center;
            color: white;
            text-align: center;
            background: black;
        }
        p {
            margin: 1rem auto 2rem;
        }
        .dimensions, .owner, .price {
            display: none;
        }
        &:first-child {
            width: 100%;
            min-height: 300px;
            margin-top: 0;
            text-align: left;
            h4 {
                font-family: $helvetica-bold;
                font-size: 2rem;
                line-height: 2.44rem;
            }
        }
        &:nth-child(1), &:nth-child(4n) {
            flex-direction: row-reverse;
        }
    }
    .card-image {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-height: 500px;
        figure {
            margin: 0;
        }
        .image {
            width: 100%;
            height: 100%;
            background-size: cover;
        }
    }
    .collection-info {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        padding: 3rem 1.5rem;
        p {
            font-family: $helvetica-light;
            font-size: 1rem;
            letter-spacing: 1px;
            color: $light-gray;
        }
    }
    .btn {
        padding: 9px 15px;
        font-family: $helvetica-bold;
        font-size: 13px;
        letter-spacing: 0.15em;
        background: $light-gray;
    }
}
