@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.artwork-table-row {
    margin-bottom: 1rem;
    &.columns {
        flex-wrap: nowrap;
        &:last-child {
            margin-bottom: 0;
        }
    }
    article {
        margin-bottom: 0 !important;
        figure.artwork {
            height: auto;
            max-height: inherit;
            .image {
                width: auto;
                max-height: 50vh;
                margin-left: auto;
            }
        }
    }
    .artwork-details {
        text-align: right;
        article {
            margin-right: 1rem;
        }
        section, .last-modified {
            display: none; // article body
        }
        .image-subtext {
            // align license and copyright to the right
            margin-left: auto;
        }
        .social-actions {
            justify-content: flex-end;
        }
        .heart-count {
            margin: 1rem 1rem 1rem 0;
        }
        .social-timestamp {
            // timestamp is hidden by default!
            display: none;
            margin-right: 1rem;
        }
    }
    .artwork-sidebar {
        margin-top: 0 !important;
        .actor-card {
            .card-header-title {
                align-items: flex-start;
            }
            .ingress-text {
                display: none;
            }
            .nbsp-block {
                display: none;
            }
        }
        .topic {
            .title h1 {
                margin-bottom: 0 !important;
            }
        }
    }
    @media (max-width: 950px) {
        // the max-width is locked with max-height of .image
        // background-color: #aeaeff;
        flex-direction: column !important;
        .column.is-8 {
            width: auto;
        }
        .column.is-4 {
            width: auto;
        }
        article {
            max-height: unset;
            margin-right: 0;
        }
        .artwork-details {
            text-align: left;
            figure.artwork {
                margin-right: 0;
                // max-height: unset;
                .image {
                    // width: 100%;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 64vh;
                    margin-left: 0;
                }
            }
            .social-actions {
                justify-content: flex-start;
            }
        }
        .artwork-sidebar {
            margin-top: 0.75rem !important;
            margin-bottom: 1.25rem;
        }
    }
    .image-not-available {
        display: block;
        width: 240px !important;
        margin-right: 0;
        margin-left: auto;
    }
}
