@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.search-overlay {
    position: fixed;
    top: 100px;
    right: 0;
    left: 0;
    z-index: 15000; // above .dx-overlay-wrapper
    display: flex;
    width: auto;
    height: auto; // will be dynamically adjusted for desktop
    min-height: 5em;
    background-color: rgba(0, 0, 0, 0.90);
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: ease-in;

    .search-input-text {
        position: relative;
        top: 1rem;
        max-width: 85vw;
        margin: 0 auto 0 1rem;
        .dx-textbox, input {
            background-color: $light-gray;
        }
    }

    .close-icon {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        width: 2rem;
        height: 2rem;
        color: $light-gray;
        cursor: pointer;
    }

    // dynamically adjust for tablet
    @media screen and (min-width: $breakpoint-tablet) {
        .search-input-text {
            max-width: 90vw;
            margin-left: 2rem;
        }
    }

    // dynamically adjust for desktop
    @media screen and (min-width: $breakpoint-desktop) {
        top: 0;
        left: calc(137px + 45px + 2rem); // logo width 137px + 45px margin
        min-height: unset;
        .search-input-text {
            top: 35px;
            max-width: 70vw;
        }
        .close-icon {
            top: 2rem;
            right: 3rem;
        }
    }
}
