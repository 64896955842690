@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-subscription-offers {
    .offer {
        padding: 0.5rem 2rem;
        h3 {
            font-family: $helvetica-bold;
            font-size: 1rem;
        }
        .btn {
            margin: 1rem 0;
        }
    }
    .offer-bubble-info {
        display: inline-block;
        padding: 1.5rem 2.5rem 1rem 11%;
    }
    .offer-request-form {
        display: flex;
        flex-direction: column;
        width: auto;
        min-width: 25rem;
        max-width: 80rem;
        font-family: $main-sans-serif;
        font-size: 1rem;
        color: $dark-gray-3;
        h4 {
            margin: 1rem 0 2rem;
            font-size: 1.35rem;
        }
        .field-label {
            display: inline-flex;
            margin: 0 0 1rem;
            font-size: 1.2rem;
            // transform for appearance
            transform: scale(1, 1.1) translateY(2px);
        }
        .field-description {
            display: flex;
            align-self: flex-end;
            margin-bottom: 2rem;
            font-size: 1rem;
        }
        .field-value {
            display: inline-flex;
            float: none !important;
            width: 2rem !important;
        }
        button.btn {
            align-self: self-start !important;
            margin: 0.5rem 0 !important;
        }
    }

    // silhouette assistant breakpoint is different from main
    .silhouette-assistant {
        @media screen and (max-width: $breakpoint-tablet) {
            .character {
                display: none;
                width: auto;
            }
            .bubble-wrapper {
                max-width: 96vw !important;
                margin-top: 1rem !important;
                padding: 0 !important;
                background-image: unset !important;
            }
        }
    }
}
