@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.osm-external-links {
    display: inline-flex;
    align-self: normal;
    width: auto;
    .directions,
    .street-view {
        display: inline-flex;
        flex-direction: column;
        flex-grow: 1;
        align-self: center;
        justify-content: center;
        justify-self: center;
        margin-left: 2rem;
        text-align: center;
        text-transform: uppercase;
        @media screen and (max-width: $breakpoint-mobile - 1) {
            flex-grow: 0;
        }
        h4 {
            flex-grow: 2;
            order: 2;
            font-family: $helvetica-medium;
            font-weight: normal;
            font-size: 0.875rem;
        }
        .links {
            display: inline-flex;
            flex-grow: 1;
            justify-content: space-around;
            order: 1;
            height: 42px;
            a {
                display: inline-flex;
                justify-content: center;
                color: $light-purple;
            }
        }
        .material-design-icon {
            width: 32px;
            height: 32px;
            margin: 0 1rem;
        }
    }
    .directions {
        // NOTE: this depends on the number of icons there are
        flex-grow: 1;
    }
}
