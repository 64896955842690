@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.entry-information-map-location {
    height: auto;
    .address-details {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        margin: 0 0 2rem;
        font-size: 1.25rem;
        @media screen and (max-width: $breakpoint-mobile - 1) {
            flex-direction: column;
            .street-address {
                width: 100%;
            }
        }
        .display-name {
            margin-bottom: 0.5rem;
            font-family: $helvetica-bold;
            font-size: 1.25rem;
            .dx-widget,
            .dx-texteditor-container {
                font-family: inherit;
                font-weight: inherit;
                font-size: inherit;
            }
        }
        .street-address {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 2;
        }
        .osm-external-links {
            align-self: flex-end;
            margin: 5px 1rem 0 auto;
        }
    }
    .geo-address-header {
        .label {
            margin-left: 1rem;
        }
    }
    // stylelint-disable-next-line no-descending-specificity
    .dx-texteditor-container {
        margin-bottom: 2rem;
    }
    .map-location-edit-wrapper {
        margin-left: 2rem;
        .material-design-icon {
            width: 28px;
            height: 28px;
            margin: 0.5rem 0.5rem auto 1rem;
        }
    }
    .osm-info-panel {
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        .results-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: center;
            margin: 0.5rem 1rem;
            font-size: 0.875rem;
            h4 {
                font-size: 1rem;
            }
        }
        .partial-results {
            margin: 0 1rem;
        }
        .material-design-icon {
            align-self: flex-start;
        }
        .btn {
            margin-top: 0.5rem;
            padding-top: 0.25rem;
        }
    }
    .osm-search-results {
        margin: 1rem 0;
        .osm-forward-search-result {
            margin: 1rem 0;
            border-bottom: 1px solid gray;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .unverified-notice {
        display: flex;
        align-items: center;
        margin: 1rem 0 1.5rem;
        font-family: 'Helvetica Neue LT W01_76 Bd It', sans-serif;
        font-size: 1.15rem;
        color: $red;
        .material-design-icon {
            width: 28px;
            height: 28px;
            margin: 0.5rem 1.5rem auto 1rem;
        }
    }
    .errors-message {
        margin: 0;
    }
    .geo-map {
        width: 100%;
        height: 50vh;
        min-height: 250px;
        max-height: 550px;
        margin-top: 1.5rem;
        .map-control {
            .btn, .btn:hover {
                font-size: 0.875rem;
                letter-spacing: 0.5px;
                background: rgba(255, 251, 251, 0.95) !important;
                // imitate other leaflet control button border colors
                border: 2px solid rgba(0, 0, 0, 0.2);
                box-shadow: none;
            }
        }
    }
    .marker-popup-contents {
        margin: 1.5rem 1.25rem;
        .osm-place-details {
            font-size: 1.15rem;
            h4 {
                margin-bottom: 0.5rem;
                font-family: $helvetica-bold;
                font-size: 1.15rem;
            }
            .address-data-street {
                margin: 0.5rem 0 0.5rem;
            }
        }
        .osm-external-links {
            width: 100%;
            margin-top: 0.5rem;
            .street-view,
            .directions {
                min-height: 55px;
                margin-left: 0;
                h4 {
                    display: none;
                    // font-size: 0.875rem;
                }
            }
        }
        // stylelint-disable-next-line no-descending-specificity
        .btn {
            display: block;
            margin: 1rem auto;
        }
        .marker-moved-title {
            font-family: $helvetica-italic;
            font-size: 1.25rem;
        }
        .marker-moved.osm-place-details {
            cursor: default;
        }
        .verify-location {
            font-size: larger;
            &, &:hover {
                background-color: $green !important;
            }
        }
    }
}
