@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.actor-card {
    display: inline-block !important;
    &.card .card-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        margin-top: 0.5rem;
        text-align: center;
        align-items: center; /* Keskittää lapset pystysuunnassa */
        align-self: center; /* Keskittää itsensä ylemmän kontainerin sisällä */
    }
    &.card .card-header .card-header-title {
        min-width: unset;
        margin: 0.25rem 0;
        font-family: $helvetica-roman;
        font-weight: normal;
        text-align: center;
    }
    .artwork-sidebar &.card .card-header .card-header-title {
        text-align: left;
    }
    .card-link {
        text-decoration: none;
        cursor: pointer;
        .card-header-title {
            cursor: pointer;
        }
    }
    .nationality, .lifetime, .actor-type, .artworks-counted {
        font-family: $helvetica-roman;
        font-size: 1rem;
    }
    .card-image {
        flex-grow: 0;
    }
    .card-image .image {
        @include actor-circle;
        transform: height;
        transition-duration: 500ms;
    }
    .social {
        right: 0;
        display: flex;
        margin: 0 0 auto auto;
        margin-left: auto;
    }
}
