@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.promotion-no-results-call-to-action {
    .vpn-lock-icon {
        width: 48px;
        height: 48px;
        color: $dark-gray;
    }
}
.dx-overlay-content {
    .register-info-popover-message {
        padding: 0.5rem;
        background: $light-gray-2;
        border: 2px solid $dark-gray-2;
        h2, h4 {
            margin-bottom: 0.5rem;
            font-size: 1.25rem;
        }
        & > div {
            max-width: 60rem;
            margin-left: 1rem;
            font-size: 1rem;
        }
        .btn {
            margin-top: 1rem;
        }

        // fix margin on widescreen
        @media screen and (min-width: $breakpoint-desktop) {
            margin-top: -24px;
            margin-bottom: -24px;
        }
    }
}
