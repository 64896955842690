@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.register-page {
    @include info-message-page;

    .register-code {
        display: flex;
        flex-direction: column;
        max-width: 40em;
        margin: 0 auto;
        font-size: 2rem;
        div {
            margin: 2rem auto 0.5rem;
        }
        input {
            width: 300px;
            margin: 2rem auto;
            font-size: 3rem;
            text-align: center;
        }
    }
}
