@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.actor-exhibitions-and-events {
    display: flex;
    flex: 1 0 10px;
    flex-wrap: wrap;
    justify-content: space-between;

    .event-info {
        min-width: 20rem;
        padding: 0;
        .event-period {
            margin: 0 auto 1.5rem;
            padding-left: 0.5rem;
            font-family: $helvetica-roman;
            font-family: $helvetica-light;
            font-size: 1.15rem;
            color: inherit;
            text-align: left;
        }
        .ingress-text {
            font-family: $helvetica-light;
            font-size: 1rem;
        }
    }

    .latest-event {
        .tile.is-child {
            flex-basis: auto; // Tai voit määrittää konkreettisen arvon, esim. 100%
            width: 100%; // Varmista, että tile täyttää tarvittavan tilan
        }
        .event-card {
            flex-direction: row;
            width: auto !important;
            margin-bottom: 3rem;
            .card-header-title h2.title {
                font-size: 1.75rem;
            }
            .event-period {
                text-align: right;
            }
            .card-contents {
                color: $dark-gray-3;
                text-align: left;
                background: unset;
            }
            .card-image {
                width: 100vw !important; // NOTE
                height: 60vh;
                max-height: 700px;
            }
            .event-ingress .title {
                line-height: 1.35;
            }
            .material-design-icon { // EventClass icon
                width: 2rem;
                height: 2rem;
            }
        }
    }
    .actor-exhibitions,
    .actor-events {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .card {
            width: auto !important;
            margin: 0 0.5rem 1.75rem !important;
            .card-image {
                flex-grow: 1;
                width: auto !important;
                min-width: 400px;
                min-height: 400px;
            }
            .card-contents {
                flex-grow: 1;
                justify-content: flex-start;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &:nth-child(2n) {
                .card-image {
                    flex-grow: 1;
                }
                .card-contents {
                    flex-grow: 3;
                }
            }
        }
        //- Events have alternate row flow
        &.actor-events {
            .card {
                flex-direction: row-reverse;
            }
            &:nth-child(3n) {
                .card-image {
                    flex-grow: 3;
                    // order: 2;
                }
                .card-contents {
                    flex-grow: 1;
                    // order: 1;
                }
            }
            &:nth-child(5n) {
                .card-image {
                    flex-grow: 2;
                    order: 2;
                }
                .card-contents {
                    flex-grow: 3;
                    order: 1;
                }
            }
        }
    }
}
