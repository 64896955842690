@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.card {
    display: flex;
    flex-basis: auto !important;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    width: auto;
    height: auto;
    min-width: 280px;
    min-height: min-content;
    margin: 0;
    margin-top: auto;
    overflow: hidden;
    font-family: $helvetica-bold;
    color: black;
    text-align: left;
    background-color: transparent;
    box-shadow: none;
    h3, h4 {
        font-family: $helvetica-roman;
        font-size: 1.5rem;
        line-height: 1;
    }
    a, .card-link {
        display: inline-block;
        flex: 1;
        width: 100%;
        height: inherit; // NOTE: height: 100% breaks images on Firefox!
        color: inherit;
        &:hover {
            color: inherit;
            h1, h2, h3, h4 {
                color: inherit;
            }
        }
    }
    .tile {
        width: 100%;
    }
    .card-header {
        z-index: 4500; // below navbar dropdown
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        width: inherit;
        color: inherit;
        .card-link {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            &:hover {
                color: inherit !important;
            }
        }
        .card-header-title {
            display: inline-flex;
            flex-direction: column;
            align-items: left;
            width: 100%;
            min-width: unset;
            margin: 0;
            padding: 0.125rem;
            line-height: normal;
            text-align: left;
            cursor: default;
            @include nowrap;
            h1, h2 {
                @include nowrap;
                margin: 0;
                margin-right: auto;
                font-size: 1em;
                letter-spacing: 0;
                white-space: nowrap;
            }
        }
    }
    .card-contents {
        z-index: 4500; // below navbar dropdown
        flex-grow: 1;
        height: auto;
    }
    .card-attribution {
        margin: 1rem 0 0;
        padding: 0.75rem 0.25rem 0.75rem 0.75rem;
        text-align: left;
        .credit-line {
            display: inline-flex;
            align-items: center;
            .icon-taide-art {
                width: 22px;
                height: 22px;
                margin: auto 12px auto 0;
            }
        }
        .credit-line,
        .copyright {
            font-family: $helvetica-medium;
            font-weight: normal;
            font-size: 1rem;
            line-height: 0.95;
            color: $dark-gray-3;
            span {
                color: inherit;
            }
        }
    }
    .social-button-wrapper {
        z-index: 4500; // below navbar dropdown
        display: inline-block;
        align-self: center;
        justify-self: right;
        margin-left: auto;
    }
    &.no-link {
        cursor: default;
    }
    div.ingress-text {
        display: block;
        flex-direction: column;
        font-family: $helvetica-roman;
        font-size: 1rem;
    }
    .card-image {
        display: flex;
        flex-grow: 1;
        align-items: stretch;
        align-self: stretch;
        width: auto;
        height: 100%;
    }
    .image-tool-icons {
        height: 0;
    }
    .card-hero {
        width: auto;
    }
    .collection {
        // hide collection by default
        display: none;
    }
    .duration {
        font-family: $helvetica-roman;
        font-size: 12px;
    }

    &:hover {
        .social-button-wrapper {
            opacity: 1;
        }
    }

    // slightly different layout when social block is in header
    &.card-social-header {
        .card-header-title {
            // max-width: 71%; // room for social button with nowrap
            font-family: 'Sabon LT W01 Bold', serif;
            font-weight: normal;
            @include nowrap;
            h2.title {
                font-size: 1.1rem;
                line-height: 1.25;
            }
            // all child elements
            & > * {
                display: block !important;
                @include nowrap;
            }
        }
        .card-header {
            width: 100%;
        }
        .card-header .card-link {
            flex-direction: row !important;
            // .card-header-title {
            //     max-width: 100%;
            // }
            .social-button-wrapper {
                align-self: auto;
                margin-top: 0.25rem;
            }
        }
        @media screen and (min-width: $breakpoint-desktop) {
            .card-header .card-link {
                flex-wrap: nowrap;
            }
        }
    }

    // hover box-shadow and image lighten effect
    &.collection-card,
    &.organization-card,
    &.event-card,
    &.artwork-card {
        margin: 0.375rem; // margin for box-shadow
        &:hover {
            box-shadow: 0 0 1.15rem $medium-gray;
            figure .image {
                background-color: rgba(100, 100, 100, 1);
                background-blend-mode: hard-light;
            }
        }
    }

    // first and last (even) elements
    // &:first-child, &:last-child:nth-child(even) {}
    @media screen and (max-width: $breakpoint-tablet - 1) {
        flex-direction: column;
    }
}
