@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.color-flex {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
    .color-flex-bubble {
        display: inline-flex;
        width: 24px;
        height: 24px;
        color: black;
        cursor: pointer;
        border-radius: 12px;
    }
}
