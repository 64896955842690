@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.input-time-span {
    .widget-head {
        display: flex;
    }
    .dx-selectbox {
        width: 100%;
        margin: 0 0.75rem;
    }
    .material-design-icon {
        width: 24px !important;
        height: 24px !important;
        margin: 0 0.77rem;
        cursor: pointer;
    }
}
