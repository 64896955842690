@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.organization-department {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    .department-name {
        margin: 0.2rem 0 0.3rem;
        h2, .dx-texteditor-container {
            font-family: 'Sabon MT W01 Regular', serif;
            font-size: clamp(1.75rem, 3vh, 45px);
            line-height: normal;
            color: inherit;
            word-break: initial;
            &::first-letter {
                text-transform: capitalize;
            }
            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 1.5rem;
            }
        }
    }
    hr {
        height: 2px;
    }
    .section-toolbar {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: auto;
        // .remove-department.section-toolbar-item {
        //     order: 1;
        // }
        // .add-personnel.section-toolbar-item {
        //     order: 2;
        // }
    }
    .department-personnel {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .map img {
        max-width: 400px;
    }
}
