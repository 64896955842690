@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#video-page {
    text-align: left;
    background: black;
    .video-details {
        justify-content: center;
        width: 100vw;
        margin-right: 0 - $main-content-margin;
        margin-left: 0 - $main-content-margin;
        padding-right: 16px; // adjust for scrollbar
        background: black;
        h2.title {
            font-size: 2.5rem;
        }
        .caption {
            font-size: 1.75rem;
        }
    }
    .video-player {
        width: auto;
        min-height: 75vh;
        .card-media {
            // min-height: 75vh;
        }
        // .card-media .azuremediaplayer {
        //     width: inherit; // NOTE: unset the stretch that works for standalone players and playlist
        // }
    }
}
