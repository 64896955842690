@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.plus-more-infinite {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto 4rem;
    .material-design-icon {
        width: 48px;
        height: 48px;
        color: $dark-gray-3;
        cursor: pointer;
    }
}
