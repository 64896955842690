@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.organization-personnel-contact {
    width: 21em;
    margin: 1rem auto 4rem;
    font-family: $main-sans-serif;
    color: black;
    &,
    .dx-texteditor-container,
    section.page-text {
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: normal;
        text-align: center;
    }
    .contact-phone,
    .contact-email {
        &, .dx-texteditor-container {
            margin-top: 0.5rem;
            font-family: $helvetica-light;
        }
    }
    .img-wrapper {
        @include actor-circle;
        margin-bottom: 1rem;
    }
    img {
        display: block;
        width: 180px;
        height: auto;
        margin: 0 auto;
        background: white;
    }
}
