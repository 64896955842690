@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.errors-quote {
    padding: 3rem 10vw;
    font-family: $main-sans-serif;
    line-height: normal;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .columns {
        display: flex;
        // going back from auth0 login screen breaks the layout and vh units cannot be used
        height: 700px;
        // min-height: 70vh;
        max-height: 70vh;
        overflow: hidden;
        box-shadow: 2px 2px 25px $dark-gray;
    }
    .message-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: auto;
        padding: 10vh 3rem 2rem;
        color: $dark-gray-2;
        text-align: center;
        background: white;
        p {
            font-size: 2rem;
            &.error {
                margin: 1rem auto;
                font-size: 1rem;
            }
        }
        .quote {
            align-self: flex-start;
            margin: 0 0 3rem auto;
            font-family: "Sabon Next W01 Regular", serif;
            font-style: italic;
            text-align: right;
            p {
                margin: 0 0 0.5rem auto;
                font-size: 1.5rem;
            }
            div {
                font-size: 0.7rem;
            }
        }
    }
    .image-panel {
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 50%;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        background-image: unset !important;
    }
    @media screen and (max-width: $breakpoint-tablet - 1) {
        height: 100%;
        .message-panel {
            width: 100%;
            background: rgba(255, 251, 251, 0.85);
        }
        .image-panel {
            display: none;
        }
    }
}
