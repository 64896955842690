// @import '~assets/styles/variables';

@mixin feature-article-index-cards() {
    .feature-article-card {
        flex-direction: row;
        width: auto !important;
        max-width: $main-content-max-width;
        margin-bottom: 3rem;
        background: unset;
        .article-ingress .title {
            line-height: 1.35;
        }
        .card-image {
            flex-grow: 2;
            justify-self: right;
            width: auto;
            min-width: 400px;
            figure {
                display: block;
            }
            .image {
                width: auto;
                height: auto;
                max-width: 60vw;
                min-height: unset; // no distortion!
                max-height: 75vh;
                margin-left: auto;
                background-size: cover !important;
                background-position-x: 50%;
            }
            @media screen and (max-width: $breakpoint-mobile - 1) {
                width: 100% !important;
                min-width: unset;
                .image {
                    max-width: unset;
                }
            }
        }
        .card-contents {
            // stylelint-disable-next-line no-descending-specificity
            .article-ingress {
                max-width: 58rem;
            }
        }

        //- every 3rd card switch text and image positions
        &:nth-child(3n) {
            flex-direction: row-reverse !important;
            justify-content: right !important;
            .card-link {
                text-align: right;
            }
            .card-image {
                justify-items: flex-start;
                justify-self: left;
                .image {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
        }

        // limit portrait image widths
        &.card-portrait {
            .card-image {
                max-width: 80vw;
            }
        }
    }

    @media screen and (min-width: $breakpoint-fullhd-xxl) {
        .feature-article-card {
            .article-ingress {
                max-width: 80rem;
            }
        }
    }

    @media screen and (min-width: $breakpoint-widescreen) {
        .feature-article-card {
            .article-ingress {
                .title {
                    font-size: 2.85rem;
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-desktop) {
        .feature-article-card {
            //- every 5th is fullwidth transparent image (with position: absolute for overlay)
            &:nth-child(5n) {
                flex-direction: column-reverse !important;
                justify-content: right !important;
                min-height: 520px;
                .card-image {
                    flex-grow: 4;
                    width: 100% !important;
                    height: auto !important;
                    margin-bottom: 11em !important;
                    .image {
                        width: 100% !important;
                        height: auto !important;
                        max-width: unset !important;
                        max-height: unset !important;
                    }
                }
                .card-contents {
                    position: absolute;
                    flex-grow: 2;
                    width: 100% !important;
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-desktop - 1) {
        .feature-article-card {
            flex-direction: row !important;
            justify-content: left !important;
            width: 100%;
            .article-ingress .title {
                font-size: 1.85rem;
            }
            .card-image {
                min-width: unset;
                max-width: 50vw;
                .image {
                    max-width: unset;
                    max-height: 80vh;
                    // &.landscape {}
                    // &.portrait {}
                }
            }
            &.card-portrait {
                flex-direction: row;
            }
            // &.card-landscape {
            //     flex-direction: column;
            // }
        }
    }

    @media screen and (max-width: $breakpoint-tablet - 1) {
        .feature-article-card {
            flex-direction: column !important;
            max-width: unset;
            .card-image {
                width: 100%;
                max-width: unset;
                margin-right: 1rem;
                .image {
                    margin-left: 0;
                    background-position-x: center;
                }
            }
            .card-contents {
                width: auto;
                margin-left: 0;
                padding-left: 0;
            }
            .article-ingress .title {
                font-size: 1.5rem;
            }
            .card-hero {
                margin-left: 0;
            }
            // stylelint-disable-next-line no-duplicate-selectors
            .card-image .image {
                max-width: unset;
                max-height: 45vh;
            }
        }
    }
}
