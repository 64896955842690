@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.actor-page-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: $main-sans-serif;
    text-align: left;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 50%;
    @media screen and (max-width: $breakpoint-mobile - 1) {
        flex-direction: column-reverse;
        .outline {
            text-align: center;
            .dx-textbox {
                margin: 0 auto;
            }
        }
    }
    h1 {
        font-family: $helvetica-roman;
        font-size: 2rem;
    }
    hr {
        display: block;
        margin: 10px 0 2rem;
    }
    .ingress-image {
        .image {
            @include actor-circle;
            margin-left: 0;
            cursor: pointer;
            opacity: 1;
        }
        .select-image {
            position: relative;
            top: -180px;
            right: 0;
        }
    }
    .nationality, .lifetime, .actor-type {
        &, .dx-texteditor-container {
            font-family: $main-sans-serif;
            font-size: 1.5rem;
            text-align: inherit;
        }
    }
    .lifetime {
        display: inline-flex;
        align-items: center;
        span {
            margin: auto 1rem;
        }
        .dx-texteditor-container {
            overflow: visible;
        }
        .dx-invalid-message {
            text-align: right;
            background: transparent !important; // or else the input will be hidden
        }
    }
    .outline {
        flex-grow: 0;
        width: auto;
        min-width: $mobile-absolute-max-width;
        padding-right: 1rem !important;
        .tagline {
            &, .dx-texteditor-container {
                margin-bottom: 2rem;
                font-family: 'Sabon MT W01 Italic', serif;
                font-size: 1.25rem !important;
            }
        }
        @media screen and (max-width: $breakpoint-desktop - 1) {
            width: auto;
            margin-top: 1rem !important;
            padding: 1.5em !important;
        }
    }
    .image-banner {
        width: 100%;
        margin-left: auto;
        figure {
            width: 180px;
            margin: 1rem 1.25rem 0.25rem auto;
        }
        .image {
            max-width: 200px;
            opacity: 0.5;
        }
        .image-not-available {
            display: none;
        }
        .silhouette-assistant .bubble-wrapper {
            text-align: center;
        }
    }
    .social-actions {
        display: flex;
        align-items: center;
        .do-follow {
            margin-right: 1rem;
        }
    }
    .spinner {
        position: absolute;
        top: 100px;
        right: 300px;
        display: block;
    }
}
