/* stylelint-disable no-descending-specificity */

/* default */
html.theme-light {
    background-color: $light-gray-3;
}

/* dark theme */
html.theme-dark {
    background-color: $dark-black;
}
html.theme-dark #app {
    color: $medium-gray-3;
    .navbar-dropdown {
        @media (min-width: 1087px) {
            box-shadow: 0 8px 8px rgba(245, 245, 245, 0.1), 0 0 0 1px rgba(245, 245, 245, 0.1) !important;
        }
    }
    .segment .segment-title {
        color: $medium-gray-2;
    }
    hr {
        background-color: $medium-gray-2;
    }
    strong {
        color: $medium-gray;
    }
    .btn {
        color: $light-gray-2;
        background: $dark-black;
        border-color: $light-gray;
        &.gray {
            color: $dark-black;
        }
        &:hover {
            background: $dark-gray !important;
            box-shadow: 0 2px 0.75rem $dark-gray-3;
        }
    }
    .display-options .display-option {
        color: $light-gray;
    }
    .sort-option {
        color: $dark-black;
        background: $light-gray;
        &.inactive {
            color: $light-gray;
            background: $dark-black;
            &:hover {
                background: rgba(23, 23, 23, 0.85);
            }
        }
    }
    .query-options {
        .option {
            color: $light-gray;
            background: $dark-gray-2;
            border-color: $light-gray;
        }
    }
    .stacking-modes-toolbar .stacking-option {
        color: $light-gray;
        &.active {
            border-color: $light-gray;
        }
    }
    .label, .title, .card-header-title {
        color: $medium-gray-2;
    }
    .social {
        color: $light-gray;
        border-color: $light-gray;
        &:hover {
            background-color: $dark-black;
        }
        &.transparent {
            background: rgba(5, 5, 0, 0.15);
            &:hover {
                color: $dark-black;
                background: rgba(255, 251, 251, 0.85);
                border-color: $dark-black;
            }
        }
        &.do-follow.active {
            color: $dark-black;
            background-color: $light-gray;
        }
        &.do-heart.round {
            background: rgba(255, 251, 251, 0.15);
            border-color: transparent;
            &:hover {
                background: rgba(255, 251, 251, 0.25);
                border-color: transparent;
            }
        }
    }

    /* overall card theme */
    .card {
        color: $light-gray;
        background: transparent;
        &:hover {
            box-shadow: none;
        }
    }

    /* card hover shadow */
    .event-card,
    .video-card {
        box-shadow: -3px -2px 4px $dark-gray-2;
        figure .image {
            background-color: rgba(150, 150, 150, 0.25);
            background-blend-mode: hard-light;
        }
        .card-link {
            color: $medium-gray-2;
        }
    }

    .artwork-card {
        .owner {
            color: $medium-gray-2;
        }
    }
    .audio-card {
        color: $medium-gray-2;
        background: transparent;
        box-shadow: none;
        .card-header-title h2.title {
            color: $medium-gray-2;
        }
    }
    .audio-player {
        audio::-webkit-media-controls-panel { // HACK for chrome
            background: $dark-gray-3;
            // stylelint-disable-next-line property-no-vendor-prefix
            -webkit-filter: saturate(0);
        }
    }
    .audio-card,
    .video-card {
        .multimedia-title {
            color: $dark-gray-2;
            .material-design-icon {
                color: $dark-gray-3;
            }
            .duration {
                color: $medium-gray-2;
            }
        }
    }
    .event-card {
        .event-period {
            color: $medium-gray-3;
        }
    }
    .feature-article-card {
        .card-header {
            color: $medium-gray-3;
            background: $dark-gray;
        }
        .card-contents {
            &, .card h2.title {
                color: $light-gray;
            }
            background: rgba(23, 23, 23, 0.85);
            background: linear-gradient(110deg, rgba(70, 70, 70, 0.5), rgba(20, 21, 22, 0.65) 60%, rgba(5, 5, 0, 0));
            .btn {
                color: $medium-gray-3;
                border-color: $light-gray;
            }
        }
        //- every 3rd card switch text and image positions
        &:nth-child(3n) {
            .card-contents {
                background: linear-gradient(-110deg, rgba(70, 70, 70, 0.5), rgba(20, 21, 22, 0.65) 60%, rgba(5, 5, 0, 0));
            }
        }
    }

    .plus-more,
    .section-toolbar-item {
        .material-design-icon {
            color: $medium-gray-2;
        }
    }
    .slick-prev::before,
    .slick-next::before {
        color: $medium-gray-3;
    }
    .vue-back-to-top {
        color: $medium-gray-3;
        background: $dark-black;
        border-color: $medium-gray-3;
    }
    .modal-dialog {
        color: $light-gray-2;
        background: $dark-black;
        border: 1px solid $light-gray;
        button.btn, .btn {
            color: $medium-gray-3;
            border-color: $medium-gray-3;
        }
    }
    .uppy-DragDrop-container {
        background-color: $dark-black !important;
    }
    .uppy-Dashboard-AddFiles-title {
        color: $medium-gray;
    }
    .promotion-no-results-call-to-action .vpn-lock-icon {
        color: $medium-gray-3;
    }
    .pagination-wrapper .pagination .page-item.active .page-link {
        border-color: $light-gray;
    }
    .pagination-wrapper .VuePagination nav .VuePagination__pagination-item-next-page a {
        color: $light-gray-2 !important;
    }
    .dx-widget {
        color: $medium-gray-2;
    }
    .dx-htmleditor-content {
        blockquote {
            color: $medium-gray-2;
        }
    }
    .dx-htmleditor-toolbar-wrapper {
        .dx-button .dx-icon {
            color: $medium-gray !important;
        }
    }
    .dx-texteditor-container {
        color: $medium-gray-2;
    }
    .dx-treelist-container {
        color: $medium-gray-3;
        background-color: $dark-black;
    }
    .dx-scrollable-scroll-content {
        background-color: rgba(255, 251, 251, 0.8);
    }
    .dx-scheduler {
        .dx-scheduler-header {
            color: $light-gray;
            background: $dark-gray-2;
        }
    }
    .dx-switch-on-value {
        .dx-switch-container::before {
            background-color: $dark-gray-4 !important;
        }
        .dx-switch-handle::before {
            background-color: $medium-gray-3 !important;
        }
    }
    .search-input-text {
        .material-design-icon {
            color: $medium-gray;
        }
    }
    .input-time-span {
        svg {
            filter: brightness(0.75);
        }
    }
    input {
        color: $medium-gray-3;
        background-color: $dark-black;
        &::placeholder {
            color: $medium-gray;
        }
    }
    input[type="range"]::-webkit-slider-runnable-track {
        background: $dark-gray-4;
    }
    input[type="range"]::-moz-range-track {
        background: $dark-gray-4;
    }
    input[type="range"]::-ms-track {
        background: $dark-gray-4;
    }
    .site-footer {
        color: $light-gray-2;
    }
    .artwork-sidebar {
        background: transparent;
        border-color: $light-gray;
        .card-header-title, .topic .title {
            color: $medium-gray-3 !important;
        }
        .lifetime {
            color: $medium-gray-2;
        }
        .collection a, .collection a:hover {
            color: $dark-gray-4;
        }
    }
    .dock.panel-dock {
        // slideout panel
        box-shadow: 7px 0px 16px 0px $light-gray;
    }
    section.panel.default {
        color: $light-gray-2;
        background-color: $dark-black;
        box-shadow: inset 3px 0px 8px 0px #2d2d2d;
        .panel-title {
            color: $light-gray-2;
            background-color: #484848;
            box-shadow: inset -5px 7px 20px 20px $dark-black;
            .label {
                color: $light-gray-2;
            }
        }
        a {
            border-color: $light-gray;
        }
        a .btn {
            color: $medium-gray-3;
            background: transparent;
        }
    }
    .image-tool-icons {
        & > div {
            background: $dark-black;
            .material-design-icon {
                color: $light-gray;
            }
        }
        // .image-tool-folders {}
        .image-tool-remove {
            .material-design-icon {
                color: $red;
            }
        }
        // .image-tool-add {}
    }
    .content-page {
        color: $light-gray-2;
        .header {
            .title {
                border-color: $light-gray;
            }
        }
        .submenu.navbar {
            color: $medium-gray-2;
            border-color: $light-gray;
            .navbar-item {
                & > .navbar-dropdown {
                    box-shadow: 0 8px 8px rgba(245, 245, 245, 0.1), 0 0 0 1px rgba(245, 245, 245, 0.1);
                }
                &.has-dropdown::after {
                    // Bulma chevron
                    border-color: $medium-gray-2 !important;
                }
            }
            .navbar-dropdown {
                color: inherit;
                background-color: $dark-black;
            }
            .router-link-active {
                border-color: $light-gray;
            }
        }
        .sidebar {
            color: $light-gray-2;
            border-color: $light-gray;
        }
    }
    .modal-terms-of-service {
        .contents {
            color: $light-gray;
        }
    }
    .actor-highlights {
        .actor-slides {
            .actor-body {
                article {
                    @include fade-read-more(500px, true);
                }
            }
        }
    }

    #home {
        .home-articles {
            background-color: $dark-black;
        }
        .home-collections {
            background-image: linear-gradient(to top, $dark-black);
        }
    }

    #user-profile {
        button.btn {
            color: $light-gray;
            border-color: $light-gray;
        }
        .subset-title-row {
            @include gradient-title-row($dark: true);
        }
        .offer-request-form {
            color: $medium-gray-3;
        }
    }
    #organization-page.content-page {
        .entry-information-opening-hours table {
            color: $light-gray;
        }
    }

    #index-search {
        .results .result-title-row {
            @include gradient-title-row($dark: true);
        }
        .card {
            box-shadow: none;
        }
    }

    // first content page titles on articles, videos, podcasts, ...
    #articles-index,
    #audios-index,
    #videos-index {
        &.content-page .segment-title:first-child h2 {
            color: $medium-gray-3;
        }
    }

    #article-page.content-page {
        h1 {
            color: $light-gray-2;
        }
    }
    #articles-index .ingress-text::after,
    #actors-index .columns .actor-body article::after {
        // alter mixin fade-read-more
        background-image: linear-gradient(to bottom, rgba(5, 5, 0, 0), rgba(5, 5, 0, 1));
    }
    #actor-page {
        #actor-articles,
        #actor-videos {
            .card {
                box-shadow: 0 0 5px $light-gray;
            }
        }
    }
    #videos-index .video-card {
        box-shadow: -1px 2px 12px $dark-gray-2;
        &:nth-child(2n) {
            box-shadow: 1px -2px 12px $dark-gray-2;
        }
        &:nth-child(3n) {
            box-shadow: -1px -2px 12px $dark-gray-4;
        }
        &:nth-child(4n) {
            box-shadow: 1px 2px 12px $dark-gray-3;
        }
    }
    #events-index {
        .card {
            box-shadow: none;
        }
        .event-brochure-stack .brochure-contents .card {
            box-shadow: 0px 0px 50px $dark-gray;
        }
    }
    #event-page {
        .event-page-header .header {
            color: $medium-gray-3;
            background-color: $dark-black;
        }
    }
    .error-page .message-panel,
    .errors-quote .message-panel {
        color: $light-gray-2;
        background: $dark-black;
    }
}
