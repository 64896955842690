@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.geometry {
    .columns {
        margin-bottom: 2rem;
    }
    .unit.label {
        /** cm label is positioned carefully */
        position: relative;
        top: -11px;
        right: 10px;
        display: flex;
        justify-content: flex-end;
        height: 0;
        margin: 0;
        font-family: 'Sabon MT W01 Regular', serif;
        font-size: 14px;
        // stylelint-disable-next-line color-no-hex
        color: #5f5f5f; // $dark-gray-3
        text-align: right;
    }
    .dx-rangeslider {
        width: 100%;
    }
    .dx-slider-handle .dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
        font-size: 12px;
    }
}
