@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#audios-search {
    .results {
        .pagination-wrapper {
            width: 100%;
        }
        .masonry-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            text-align: center;
            .item {
                margin: 0 auto 1.5rem;
            }
        }
        .VuePagination__count {
            display: none;
        }
    }
}
