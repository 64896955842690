@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.organization-visitor {
    margin-top: 1rem;
    font-family: $helvetica-roman;
    font-size: 0.875rem;
    .draggable-wrapper {
        cursor: move;
    }
    .entry-descriptions {
        .btn.save {
            display: block;
        }
        .organization-visitor-info:nth-child(1) {
            .btn.remove {
                display: none;
            }
        }
    }
    .add-info-part {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        .material-design-icon {
            width: 32px;
            height: 32px;
            cursor: pointer;
        }
    }
}
