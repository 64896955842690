@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.organization-feature-articles {
    display: flex;
    flex: 1 0 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    .section-toolbar {
        margin-top: 0;
    }
    & > .segment {
        margin-top: 0;
    }
    .feature-article-card {
        width: 350px;
        &.card .card-image {
            height: 250px;
            figure {
                height: inherit;
            }
        }
    }
    .latest-article {
        margin-top: 2rem;
        .feature-article-card {
            flex-direction: row;
            width: auto !important;
            max-width: 1400px;
            margin-bottom: 3rem;
            background: unset;
            &.card .card-image {
                height: 400px;
            }
            .article-ingress .title {
                line-height: 1.35;
            }
        }
    }
    // enlarge every 3rd article with flex-grow
    .articles-main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .card {
            width: inherit !important;
            max-width: 1200px;
            // stylelint-disable-next-line no-descending-specificity
            .card-image {
                height: 400px;
            }
        }
        &.tile.is-ancestor {
            flex-wrap: wrap;
            min-height: initial;
        }
        .article-parent {
            flex-grow: 1;
            align-self: flex-start;
            width: auto;
            min-width: 400px;
            max-width: 45%;
            margin: 0 0.5rem 1rem;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &:nth-child(2) {
                .card .card-image {
                    height: 700px;
                }
            }
            &:nth-child(3n),
            &:last-child {
                flex-grow: 2;
                width: auto;
                max-width: unset;
                .card .card-image {
                    height: 500px;
                }
            }
            &:nth-child(7n) {
                flex-grow: 3;
                width: auto;
                max-width: unset;
                .card .card-image {
                    height: 700px;
                }
            }
        }
    }
}
