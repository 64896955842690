@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.silhouette-assistant {
    display: flex;
    flex-direction: row;
    margin: 0 0 1.5rem;
    .character {
        position: relative;
        width: 200px;
        height: auto;
        margin: 0;
    }
    &.flip-row {
        flex-direction: row-reverse;
    }
    & > .character,
    & > .bubble-wrapper {
        z-index: 20000;
    }
    .bubble-wrapper {
        display: inline-flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        align-self: center;
        justify-content: center;
        width: 100%;
        max-width: calc(100vw - 45px - 200px);
        min-height: 120px;
        padding: 1rem;
        font-family: $main-sans-serif;
        font-size: 1rem;
        color: $dark-gray;
        & > p, & > div {
            margin-bottom: 1.5rem;
        }

        /* svg background images do not behave well across browsers, a hi-res png should be used instead */
        &.speech-bubble {
            width: 100%;
            height: 100%;
            margin: 1em 0 auto;
            padding-top: 9%;
            padding-right: 6%;
            padding-bottom: 9%;
            padding-left: 20%;
            // background-image: url('~assets/images/bubble2.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            @media screen and (max-width: $breakpoint-tablet) {
                padding-top: 17%;
                padding-bottom: 17%;
            }
            &.flip-row {
                padding-right: 20%;
                padding-left: 8%;
                // when image and bubble order is switched, mirror the bubble image horizontally
                // filter: FlipH;
                // transform: scaleX(-1);
                // NOTE: this reverses the text too! needs another layer for the image...
            }
            .btn {
                align-self: center;
                margin-top: 2rem;
            }
            .bubble-title {
                margin-bottom: 1rem;
                font-size: 1.25rem;
            }
        }
        &.inverse-colors {
            color: $medium-gray-3;
            h2, h4 {
                color: $medium-gray-3;
            }
            button.btn {
                color: $medium-gray-3;
                border-color: $medium-gray-3;
            }
        }
        &.speech-bubble-1 {
            background-image: url('~assets/images/bubble.png');
            &.inverse-colors {
                background-image: url('~assets/images/bubble-inverse.png');
            }
        }
        &.speech-bubble-1-vertical {
            padding-top: 4%;
            padding-right: 20%;
            padding-bottom: 12%;
            background-image: url('~assets/images/bubble-vertical.png');
            &.inverse-colors {
                background-image: url('~assets/images/bubble-vertical-inverse.png');
            }
        }
        &.speech-bubble-2 {
            background-image: url('~assets/images/bubble2.png');
            &.inverse-colors {
                background-image: url('~assets/images/bubble2-inverse.png');
            }
            &.flip-row {
                background-image: url('~assets/images/bubble2-reverse.png');
            }
            &.flip-row.inverse-colors {
                background-image: url('~assets/images/bubble2-reverse-inverse.png');
            }
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        .character {
            display: none;
            width: auto;
        }
        .bubble-wrapper {
            max-width: 96vw !important;
            margin-top: 1rem !important;
            padding: 0 !important;
            background-image: unset !important;
        }
    }

    // inverse in class name stands for color inversion
    &.silhouette-1,
    &.silhouette-1-inverse,
    &.silhouette-3,
    &.silhouette-3-inverse {
        img {
            transform: scaleX(-1); // reverse image x axis
        }
        &.flip-row {
            img {
                transform: scaleX(1); // initial image x axis
            }
        }
    }
    &.silhouette-1,
    &.silhouette-1-inverse {
        .speech-bubble {
            margin-top: 2em;
        }
    }
    &.silhouette-2,
    &.silhouette-2-inverse {
        &.flip-row {
            img {
                transform: scaleX(-1);
            }
        }
    }
    &.silhouette-3,
    &.silhouette-3-inverse {
        .character {
            margin-right: -2em;
        }
        .speech-bubble {
            margin-top: 2.5em;
        }
    }

    &.fade-in {
        opacity: 0;
        transform: opacity;
        transition-duration: 1s;
        transition-timing-function: ease-in;
        &.visible {
            opacity: 1;
        }
    }
}
