@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.social.do-share {
    width: 38px;
    height: 38px;
    .material-design-icon {
        width: 28px;
        height: 28px;
    }
}
