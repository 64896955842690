@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

// $gradientAngle: random(6) - 14deg;

.modal-error-message {
    text-align: center;
    .o-modal__overlay {
        // striped background effect with translucent alizari crimson red (error color)
        // background:
        //     repeating-linear-gradient(
        //         $gradientAngle,
        //         rgba(235, 36, 36, 0.9),
        //         rgba(235, 36, 36, 0.5) 22px,
        //         rgba(235, 36, 36, 0) 45px,
        //         rgba(0, 0, 0, 0) 45px,
        //         rgba(0, 0, 0, 0) 67px
        //     );
    }
    .modal-dialog {
        padding: 0;
        overflow: hidden;
        box-shadow: 2px 2px 25px $dark-gray;
    }
    .errors-quote {
        min-height: unset;
        padding: 0;
        button {
            min-width: 9rem;
            margin: 1rem auto;
            padding: 0.2rem 1rem;
        }
        .columns {
            box-shadow: none;
        }
        .message-panel {
            .status {
                margin-bottom: 1rem;
                font-family: "Sabon Next W01 Regular", serif;
                font-size: 3rem;
            }
            .status-text {
                margin: 1rem auto;
                font-size: 1.5rem;
                color: $error;
            }
            .details {
                margin: 1rem auto;
                font-family: $helvetica-roman;
                font-size: 1rem;
                color: $error;
            }
            .support {
                margin: 12% auto 2.5rem;
                font-size: 1rem;
                em {
                    display: inline-block;
                    margin: 1.22em 0.88em 0.24em;
                    font-family: $helvetica-italic;
                    font-style: normal;
                    font-size: larger;
                }
            }
            .silhouette-assistant {
                margin: auto 0;
                .bubble-wrapper {
                    .material-design-icon {
                        width: 32px;
                        height: 32px;
                        margin-top: 1rem;
                        color: $dark-gray;
                    }
                }
            }
        }
    }
}
