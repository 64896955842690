@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#collections-index {
    .collection-card {
        width: auto !important;
    }
    .card-header {
        margin-bottom: 10px;
    }
    .slides-museums {
        .card-image {
            height: 450px;
        }
    }
    .slides-foundations {
        .card-image {
            height: 450px;
        }
    }
    .slides-privates {
        .card-image {
            height: 450px;
        }
    }
}
