@import "~assets/styles/mixins.scss";

@import '../components/Event/index-cards';

#events-index {
    @include event-index-cards;

    .slides-prominent {
        .slick-slide {
            display: inline-block;
            float: none;
            vertical-align: top;
            .card-image .card-link,
            .card-image figure {
                min-height: inherit; // fix image heights (TODO: set this in image-box)
            }
        }
    }

    .schedule {
        padding: 5px;

        .dx-scheduler-work-space-month .dx-scheduler-date-table-scrollable .dx-scrollable-content {
            min-height: unset;
        }

        .dx-scheduler-work-space.dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
            height: 40px !important;
        }

        td {
            cursor: pointer;
        }
    }
}
