@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#actor-page {
    font-size: 1rem;

    .submenu.navbar {
        .navbar-item.has-dropdown .navbar-link {
            text-transform: none;
        }
        .navbar-dropdown {
            min-width: 30rem;
            max-width: 80vw;
            max-height: 70vh;
            a {
                text-decoration: none;
                white-space: normal;
            }
            .item-title {
                font-family: $helvetica-bold;
                font-size: 1.25rem;
            }
            .publish-date {
                font-family: $helvetica-light;
                font-size: 1rem;
            }
        }
    }

    .hero {
        height: auto;
    }

    .segment-title {
        font-size: 1.5rem;
    }
    .page-text {
        margin-bottom: 2rem;
    }

    .actor-news-articles {
        .card-header {
            min-width: 25rem;
        }
        .card .card-header a {
            color: $dark-gray-2;
        }
        .card-contents {
            display: none;
        }
        .title {
            font-family: $helvetica-thin !important;
            font-size: 1.75rem !important;
            letter-spacing: 0.75px;
        }
        .ingress-text {
            font-size: 1.25rem;
            span {
                color: $medium-gray-3 !important;
            }
        }
        // stylelint-disable-next-line no-descending-specificity
        .publish-date {
            font-size: 1.25rem;
        }
    }

    .actor-info {
        font-family: $main-sans-serif;
        p {
            font-size: 1rem;
            line-height: inherit;
            text-align: justify;
        }
        .artist-statement {
            max-width: 550px;
            margin: 2.5rem auto;
            p {
                font-size: 1.25rem;
                text-align: center;
            }
        }
        .quote {
            width: 80%;
            font-family: $helvetica-italic;
            font-size: 1.2rem;
            text-align: center;
        }
        .dx-htmleditor {
            min-width: auto !important;
        }
    }

    .actor-info-sidebar {
        padding-left: 3rem;
        .card {
            overflow: hidden;
            .card-image {
                height: 240px;
            }
        }
        .feature-article-card .card-header {
            color: $dark-gray;
            background: $light-gray;
            .card-header-title {
                padding-left: 0.5rem;
                border: 0;
            }
            header {
                padding: 0 0 0.5rem !important;
            }
            .ingress-text {
                font-size: 1.15rem;
            }
            .more {
                padding: 0 0 1rem 1rem;
                font-size: 0.85rem;
            }
        }
        .audio-player {
            .card-contents {
                display: none;
            }
        }
        @media screen and (max-width: $breakpoint-tablet - 1) {
            width: 100%;
            margin-left: 0;
            hr {
                display: block;
            }
            .slick-slider {
                max-width: unset !important;
                margin-right: 0;
            }
        }
    }

    // SubjectItems Actors at the bottom, Articles at the top
    .subject-actors {
        order: 10;
    }
    .subject-articles {
        order: 1;
    }

    #actor-artworks {
        flex-grow: 0;
        h2.total-count {
            display: block;
            margin: 0 0 2rem;
            font-family: $main-serif;
            font-size: 1.5rem;
        }
        .item {
            margin: 0 10px 15px 0;
            &:last-child {
                margin-right: 0;
            }
        }
        .card {
            align-self: flex-end;
            .card-header .tile {
                width: 100%;
            }
            figure {
                margin: 0;
            }
        }
        .stacking-modes-toolbar {
            position: relative;
            justify-content: flex-end;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
        .artwork-sidebar {
            // hide the actor follow button
            .actor-card .social-button-wrapper {
                display: none;
            }
        }
    }
    #actor-contact {
        font-family: $main-sans-serif;
        font-size: 14px;
        h1 {
            font-family: $helvetica-bold;
        }
    }
}
