// @import '~assets/styles/variables';

@mixin event-index-cards() {
    .card {
        width: auto !important;
        margin: 0 0.5rem 8px 0 !important;
        .card-image {
            flex-grow: 1;
            width: auto !important;
            min-width: 400px;
            min-height: 400px;
            @media screen and (max-width: $breakpoint-mobile) {
                min-width: unset;
                min-height: 240px;
            }
        }
        .card-contents {
            flex-grow: 1;
            justify-content: flex-start;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        &:nth-child(2n) {
            .card-image {
                flex-grow: 1;
            }
            .card-contents {
                flex-grow: 3;
            }
        }
        &:nth-child(3n) {
            .card-image {
                flex-grow: 2;
                order: 2;
            }
            .card-contents {
                flex-grow: 1;
                order: 1;
            }
        }
        &:nth-child(5n) {
            .card-image {
                flex-grow: 2;
                order: 2;
            }
            .card-contents {
                flex-grow: 3;
                order: 1;
            }
        }
    }
}
