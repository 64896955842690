@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#info-message-page {
    @include info-message-page;
    .silhouette-assistant {
        .btn {
            margin: 1.5rem auto 0.5rem;
        }
    }

    &.RegistrationInfo {
        // bigger margins for more text in speech bubble
        .info-message {
            margin-top: 1.5rem;
            h3 {
                display: inline-block;
                font-size: 1.25rem;
            }
        }
        .register-link {
            margin-bottom: 2.5rem;
        }
    }
}
