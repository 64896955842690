@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.license-info {
    z-index: 5000; // more than .card-header
    &, .license {
        display: inline-flex;
        align-items: center;
        width: max-content;
    }
    img, .material-design-icon, .icon-taide-art {
        width: 10px !important;
        height: 10px !important;
        margin-left: 2px;
        opacity: 0.9; // decrease color contrast
    }
}
