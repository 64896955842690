@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.map-canvas {
    min-height: 700px;
    height: 35vh;
    max-height: 1000px;
    min-width: 75vw;
    width: calc(100vw - 105px);
    max-width: calc(100vw - 105px);
    margin: 0px;
    padding: 0px
}


#collection-page {
    font-size: 1rem;
    .header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 1rem;
        font-family: $main-sans-serif;
        @media screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;
        }
        h1 {
            font-size: 26px;
        }
        hr {
            margin-top: 10px;
        }
        .outline {
            .curator-name {
                font-family: $helvetica-light;
                font-size: 24px;
                line-height: 1.75;
            }
            .collection-name {
                &, .dx-texteditor-container {
                    font-family: "Sabon LT W01 Bold", serif;
                    font-weight: normal;
                    font-size: 1.625rem;
                }
            }
            .ingress-text {
                &, .dx-texteditor-container {
                    height: auto;
                    margin: 0 2rem 1rem 0;
                    font-family: 'Sabon LT W01 Regular', serif;
                    font-weight: normal;
                    font-size: 1.05rem !important;
                }
            }
        }
        .social-actions {
            display: flex;
            align-items: center;
            .do-follow {
                margin-right: 1rem;
            }
        }
        .contact {
            z-index: 1000;
            font-size: 1rem;
        }
        @media screen and (min-width: $breakpoint-fullhd-xl) {
            .banner {
                min-height: unset !important;
            }
        }
    }
    .page-text .dx-htmleditor {
        min-width: unset;
        max-width: unset;
    }
    .contents {
        display: flex;
        flex-direction: row;
        article {
            word-break: break-word;
        }
        h3 {
            font-family: $helvetica-bold;
            font-size: 18px;
        }
        p {
            font-family: 'Sabon LT W01 Regular', serif;
            font-weight: normal;
            font-size: 1.15rem;
            h1, h2, h3, h4, h5, h6 {
                font-family: inherit;
                font-weight: inherit;
                font-size: inherit;
            }
        }
        #collection-info {
            section {
                text-align: justify;
            }
        }
        .sidebar {
            margin-left: auto;
            padding: 0;
            // stylelint-disable-next-line no-descending-specificity
            section {
                text-align: justify;
            }
            .sidebar-container {
                padding-left: 1rem;
                border-left: 1px solid black;
                @media screen and (max-width: $breakpoint-desktop - 1) {
                    padding-left: 0;
                    border-left: 0;
                }
            }
            article {
                width: auto;
            }
            .editr--toolbar {
                overflow: hidden;
            }
            h3 {
                margin-bottom: 0;
            }
            .number-of-works {
                margin-bottom: 1rem;
            }
            .edit-toolbar {
                display: none;
            }
            .submit-button {
                display: none;
            }
        }
        // collapse to column layout on narrow displays
        @media screen and (max-width: $breakpoint-desktop - 1) {
            flex-direction: column;
            .column {
                width: 100%;
            }
            .submit-button {
                display: none;
            }
            #collection-info .edit-toolbar {
                display: none;
            }
            // stylelint-disable-next-line no-descending-specificity
            .sidebar .edit-toolbar {
                display: block;
            }
            .sidebar .submit-button {
                display: block;
            }
        }
    }
    // .slides-other-collections {
    //     background-color: $light-gray;
    //     .card {
    //         background-color: transparent;
    //     }
    //     .card-image {
    //         height: 45vh;
    //         .image {
    //             // background-size: auto 100% !important;
    //             background-position-x: 50%;
    //         }
    //     }
    // }
    #collection-artworks {
        flex-grow: 1;
        h2.total-count {
            display: block;
            margin: 0 0 2rem;
            font-family: $main-serif;
            font-size: 1.5rem;
        }
        .item {
            margin: 0 10px 10px 0;
            &:last-child {
                margin-right: 0;
            }
        }
        .card {
            align-self: flex-end;
            .card-header .tile {
                width: 100%;
            }
            .card-contents {
                .owner, .collection {
                    // hide collection on the collection page
                    display: none;
                }
            }
            figure {
                margin: 0;
            }
        }
        .artwork-sidebar {
            // hide collection on the collection page
            .details .collections {
                display: none;
            }
        }
    }
}
