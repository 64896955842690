@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#event-page {
    font-size: 1rem;
    .contents {
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        @media screen and (min-width: $breakpoint-mobile) {
            justify-content: center;
        }
        article {
            display: inline-block;
            //width: 75vw;
            margin: 1rem auto 0.5rem;
            padding: 0 1rem;
            font-size: 1.4rem;
            text-align: left;
            word-break: break-word;
            vertical-align: text-top;
            @media screen and (max-width: $breakpoint-mobile) {
                padding: 0;
            }
        }
        h3 {
            font-family: $helvetica-bold;
            font-size: 18px;
        }
        // stylelint-disable-next-line no-descending-specificity
        p {
            // stylelint-disable-next-line no-descending-specificity
            h1, h2, h3, h4, h5, h6 {
                font-family: inherit;
                font-weight: inherit;
                font-size: inherit;
            }
        }
        .event-info {
            max-width: 67em;
            //margin-right: 4rem;
            section {
                text-align: justify;
            }
            .entry-description {
                padding: 0;
                background: unset;
            }
            @media screen and (min-width: $breakpoint-mobile) {
                margin: 0 4vw;
            }
            @media screen and (min-width: $breakpoint-fullhd) {
                margin: 0 12em;
            }
        }
        .sidebar {
            margin-left: auto;
            padding: 0;
            // stylelint-disable-next-line no-descending-specificity
            section {
                text-align: justify;
            }
            .sidebar-container {
                padding-left: 1rem;
                border-left: 1px solid black;
                @media (max-width: 900px) {
                    padding-left: 0;
                    border-left: 0;
                }
            }
            article {
                width: auto;
            }
            .editr--toolbar {
                overflow: hidden;
            }
            h3 {
                margin-bottom: 0;
            }
            .number-of-works {
                margin-bottom: 1rem;
            }
            .edit-toolbar {
                display: none;
            }
        }
        @media (max-width: 900px) {
            flex-direction: column;
            .column {
                width: 100%;
            }
            .event-info .edit-toolbar {
                display: none;
            }
            // stylelint-disable-next-line no-descending-specificity
            .sidebar .edit-toolbar {
                display: block;
            }
        }
    }
    .event-artworks {
        flex-grow: 0;
        h2.total-count {
            display: block;
            margin: 0 0 2rem;
            font-family: $main-serif;
            font-size: 1.5rem;
        }
        .item {
            margin: 0 10px 10px 0;
            &:last-child {
                margin-right: 0;
            }
        }
        // stylelint-disable-next-line no-descending-specificity
        .card {
            align-self: flex-end;
            .card-header .tile {
                width: 100%;
            }
            // .card-contents {
            //     .owner, .event {
            //         display: none;
            //     }
            // }
            figure {
                margin: 0;
            }
        }
        // .artwork-sidebar {
        //     .details .event {
        //         display: none;
        //     }
        // }

        .stacking-modes-toolbar {
            @media (min-width: 950px) {
                margin-top: -4rem;
            }
        }
    }
    .submit-button.save {
        display: block;
    }

    .subject-items {
        .subject-artworks {
            order: 1;
        }
        .subject-organizations {
            order: 2;
        }
        .subject-collections {
            order: 2;
        }
    }
    .adjusttocol {
        max-width: 67em;
        margin-right: auto;
        margin-left: auto;
    }
    // .somecontent {
    // }
    .somecontent-if {
        width: 100%;
    }
}
