@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.entry-information-opening-hours {
    table {
        color: $dark-gray-2;
        background-color: transparent;
    }
    input {
        width: auto;
    }
    .b-table {
        margin-bottom: 1rem;
        thead {
            display: none;
        }
    }
    .opening-closing-week-row {
        align-content: center;
        td {
            vertical-align: middle;
        }
        td:first-child {
            width: 5rem;
        }
    }
    .exceptions-header {
        display: flex;
        .material-design-icon {
            width: 22px;
            height: 22px;
        }
        .label {
            margin-left: 1rem;
        }
    }
    .editing h3 {
        font-family: $helvetica-medium;
        font-weight: normal;
        font-size: 1rem;
    }
    .submit-button {
        justify-self: center;
        margin: 2rem auto;
    }
    .dx-datebox {
        .dx-texteditor-container {
            font-size: 1.25rem;
            text-align: center;
        }
    }
    .closed-checkbox-column {
        border: 0;
        & > span {
            display: inline-flex;
            align-items: baseline;
        }
        .dx-checkbox {
            margin-right: 0.25rem;
        }
        .label {
            font-family: $helvetica-thin;
            font-size: 0.9rem;
            letter-spacing: 1px;
            text-transform: lowercase;
        }
    }
}
