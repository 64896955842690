@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#artwork-page {
    text-align: left;
    .columns {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0;
    }
    .column {
        flex-direction: column;
        &.side-column {
            // align artwork details sidebar to the right
            margin-left: auto;
        }
    }
    .artwork-column {
        // some bulma styles, or their overrides may interfere, and these are very sensitive values
        flex-basis: auto !important;
        flex-grow: 3 !important;
        flex-shrink: 0 !important;
    }
    // artwork image
    .artwork-details.main-image {
        // margin: 22px 12px 0 0;
        margin-top: 1rem;
        padding: 0;
        .artwork .image {
            width: auto;
            height: auto;
            min-width: unset;
            // max-width is set in artwork-details
            min-height: unset;
            max-height: 80vh;
        }
        .image-not-available {
            width: auto;
        }
        article {
            font-size: larger;
        }
    }
    .social-content {
        max-width: 40em;
        opacity: 0.5;
    }
    .social-comments {
        max-width: 90vw;
    }
    @media screen and (max-width: $breakpoint-mobile - 1) {
        .artwork-details.main-image {
            margin-right: 0;
        }
        article {
            margin-right: 0;
        }
    }
    @media (min-width: 1100px) {
        #artwork-wizard {
            .contents {
                grid-template-columns: auto;
            }
            .side-column {
                width: auto;
            }
        }
    }

    .artwork-sidebar {
        margin-top: 1rem;
        .card-header-title .artworks-counted {
            display: none;
        }
        .pages {
            margin-top: 2rem;
            .prev, .next {
                display: inline-table !important;
                width: 2.25rem;
                height: 2.25rem;
                margin: 0.1rem 0.5rem 0 0;
                padding: 0.6rem 0 0;
                font-size: 0.8rem !important;
                color: $medium-gray;
                text-align: center;
                vertical-align: bottom;
                cursor: pointer;
                border-color: $medium-gray;
                border-style: solid;
                border-width: 2px;
                border-radius: 1.25rem !important;
                &:hover {
                    color: $dark-gray-2;
                    background: white;
                    border-color: $dark-gray-2;
                }
                &.disabled {
                    cursor: default;
                    border-color: white;
                }
            }
        }
    }

    .other-artworks {
        flex-grow: 0;
        h2.total-count {
            display: block;
            margin: 0 0 2rem;
            font-family: $main-serif;
            font-size: 1.5rem;
        }
        .item {
            margin: 0 10px 15px 0;
            &:last-child {
                margin-right: 0;
            }
        }
        .card {
            align-self: flex-end;
            .card-header .tile {
                width: 100%;
            }
            figure {
                margin: 0;
            }
        }
        // table mode image list
        .artwork-sidebar {
            // hide the actor follow button
            .actor-card .social-button-wrapper {
                display: none;
            }
        }

        .stacking-modes-toolbar {
            position: relative;
            justify-content: flex-end;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            @media screen and (max-width: $breakpoint-mobile) {
                position: initial;
                display: flex;
                margin: 0 auto 1rem 0;
            }
        }
    }

    .slides-from-same-era,
    .slides-with-similar-colors {
        .slick-slide {
            display: inline-block;
            float: none;
            vertical-align: middle;
            & > div {
                margin: 0.375rem; // margin for box-shadow
            }
        }
        .credits {
            display: none;
        }
    }
}
