@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.artwork-details {
    .tile.is-parent {
        flex-direction: column;
    }
    .tile.is-child {
        flex-direction: column;
    }
    article {
        margin-right: 2rem;
    }
    h1, h2, h3, h4 {
        // font for BodyHtml titles
        font-family: 'Sabon MT W01 Regular', serif;
        font-weight: bold;
    }
    h2, h3 {
        font-size: 1.35rem;
    }
    .edit-access {
        margin-right: 1rem;
    }
    .slick-track {
        margin-left: 0;
    }
    .slick-initialized .slick-slide {
        // hide images not in view
        // (on edge causes some flickering)
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
        &.slick-active {
            opacity: 1;
            transition-duration: 0;
        }
    }
    figure {
        width: auto !important;
        height: auto !important;
        max-height: 77vh;
    }
    figure.artwork {
        flex: 1;
        width: 100%;
        height: 100%;
        min-width: inherit;
        max-width: unset;
        min-height: 20px;
        max-height: 80vh;
        margin: 0;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: 0 50%;
        .image {
            width: inherit;
            height: inherit;
            min-width: inherit;
            max-width: stretch;
            min-height: inherit;
            margin: 0;
            padding: 0;
            background-repeat: inherit;
            background-size: inherit;
            background-position: inherit;
        }
        .image-not-available {
            cursor: not-allowed;
        }
        .background-palette {
            .loading {
                display: none; // FIXME: loading-spinner in table row mode
            }
        }
    }
    .image-attribution {
        .credit-line,
        .copyright,
        .license {
            font-size: 0.625rem;
        }
    }
    .social-actions {
        display: inline-flex;
        flex-direction: row !important;
        width: 100%;
        margin: 0.5rem 0 1rem;
        .social-button-wrapper {
            margin: 0 2rem 0 0;
            &.is-right {
                margin-right: 1.5rem;
                margin-left: auto;
            }
            .social {
                height: 100%;
                margin: 0 auto;
            }
        }
    }
    .heart-count {
        font-family: $helvetica-bold;
        font-size: 1rem;
        transform: scaleY(1.2);
    }
    .audio-playlist {
        margin: 1rem 0;
        .podcast-title {
            align-items: flex-start;
        }
        .audio-player {
            flex-grow: 1;
            width: 100%;
            .duration {
                margin-bottom: 0 !important;
                font-size: 12px !important;
            }
        }
        .card-header-title, .card-contents {
            display: none;
        }
    }
    .page-text {
        font-size: 1rem;
        text-align: justify;
    }
    .slick-prev, .slick-next {
        top: 44%;
    }
    .last-modified {
        margin-left: auto;
    }
}
