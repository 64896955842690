@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

/* stylelint-disable selector-class-pattern */
.pagination-wrapper {
    display: block;
    text-align: center;
    .pagination-header {
        display: flex;
        align-items: baseline;
        justify-content: center;
        width: auto;
        min-height: 4rem;
        margin-bottom: 1rem;
        user-select: none;
        .VuePagination {
            display: inline-block;
            order: 1;
        }
    }
    .VuePagination nav {
        display: flex;
        flex-direction: column-reverse;
        ul {
            display: inline-flex;
            margin: 0 auto;
        }
        .VuePagination__pagination-item.page-item.disabled {
            opacity: 0.75;
        }
        .VuePagination__pagination-item-prev-page,
        .VuePagination__pagination-item-next-page,
        .VuePagination__pagination-item-prev-chunk,
        .VuePagination__pagination-item-next-chunk {
            a {
                color: $dark-black !important;
                border: 0 !important;
            }
            &.disabled a {
                color: $medium-gray !important;
            }
        }
        .VuePagination__pagination-item-prev-page {
            margin-right: 1rem;
        }
        .VuePagination__pagination-item-next-page {
            margin-left: 1rem;
        }
    }
    .item {
        margin: 0 10px 10px 0;
        &:last-child {
            margin-right: 0;
        }
        .card {
            // prevent very wide images from crossing page margins
            max-width: 92vw;
            @media (max-width: 710px) {
                max-width: 80vw;
            }
        }
    }
    .pagination .page-item {
        margin: 0.1rem;
        vertical-align: bottom;
        // stylelint-disable-next-line
        & > a, & > span {
            display: inline-table !important;
            width: 2.25rem;
            height: 2.25rem;
            margin: 0 auto;
            padding: 0.6rem 0 0;
            font-size: 0.8rem !important;
            color: $medium-gray-2;
            text-align: center;
            vertical-align: bottom;
            border-color: $medium-gray-2;
            border-style: solid;
            border-width: 2px;
            border-radius: 1.25rem !important;
            outline: none;
            box-shadow: none;
            &[role='button'] {
                color: $dark-gray-2;
                border-color: $dark-gray-2;
                &:hover {
                    color: $dark-gray-2;
                    border-color: $dark-gray-2;
                }
            }
            // stylelint-disable-next-line no-descending-specificity
            &:hover {
                color: $dark-gray-3;
                background: $light-gray;
                border-color: $dark-gray-3;
            }
        }
        &.active .page-link {
            color: $medium-gray-3;
            background-color: $dark-black !important;
            border-color: $dark-black;
            &:hover {
                color: $light-gray;
            }
        }
    }
    .pagination-masonry-wrapper {
        margin: 0 auto;
        @media (max-width: 710px) {
            margin: 0;
        }
        .grid-sizer {
            width: 300px;
        }
        .artwork-card {
            .social-button-wrapper {
                right: 0.25rem;
                bottom: 0.25rem;
            }
        }
    }
    .infinite-loading-container {
        .spinner {
            height: 40px;
        }
    }
    .plain-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        text-align: center;
        .columns {
            flex-wrap: nowrap;
        }
        .item {
            width: auto; // important for table mode
            margin: 0 0 1.5rem 0;
        }
    }
}
