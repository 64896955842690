@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.video-details {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    .columns {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0;
    }
    .column {
        flex-direction: column;
        &.side-column {
            // align artwork details sidebar to the right
            margin-left: auto;
        }
    }
    .video-contents-info {
        padding: 0.5rem;
        color: $medium-gray-3;
        background: black;
        .caption,
        .ingress-text,
        .article-links {
            margin: 1.5rem 4rem 1.5rem 1rem;
        }
        .caption,
        .ingress-text {
            color: $medium-gray-3;
        }
        .caption {
            font-family: $sabon-bold;
            font-weight: normal;
            font-size: 1.25rem;
            line-height: 1;
            letter-spacing: 0px;
            color: $medium-gray-2;
        }
        .ingress-text {
            font-family: $helvetica-thin !important;
            font-size: 1.15rem;
            line-height: normal;
            letter-spacing: 1px;
            color: $light-gray-2;
            white-space: initial;
        }
    }
    .card-attribution {
        margin: 1rem 0 0;
        padding: 0 0.5rem 0.75rem 0.75rem;
        text-align: left;
        .credit-line {
            display: inline-flex;
            align-items: center;
            .icon-taide-art {
                width: 22px;
                height: 22px;
                margin: auto 16px auto 0;
            }
        }
        .credit-line,
        .copyright {
            color: $medium-gray-3;
        }
    }
    .social-button-wrapper {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        width: 30px;
        height: 0;
        margin-left: auto;
        color: $medium-gray-2;
        .social .is-loading.button,
        .material-design-icon.bookmark-icon,
        .material-design-icon.bookmark-outline-icon {
            width: 30px;
            margin-top: 8px;
        }
    }
    .video-player {
        overflow: hidden;
        .card-media {
            flex-grow: 0;
            min-height: unset;
        }
        .social-button-wrapper {
            margin-left: auto;
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        .columns {
            flex-direction: column-reverse;
        }
    }
}
