@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.social-network-share {
    display: inline-flex;
    margin-left: 1rem;
    vertical-align: text-top;
    .material-design-icon {
        display: inline-flex;
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;
        cursor: pointer;
    }
}

// styling for this specific popover instance (specific class via :wrapper-attr requires devextreme v21.2)
.dx-popover-wrapper.dx-overlay-wrapper.sharing-popover-wrapper {
    .dx-popup-content {
        padding: 1rem;
    }
    .networks-popover {
        a {
            display: flex;
            margin-top: 0.4rem;
            padding-bottom: 0.4rem;
            font-family: $main-sans-serif;
            font-size: 1.15rem;
            color: $dark-gray-2;
            vertical-align: middle;
            .material-design-icon {
                margin-right: 0.75rem;
            }
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
    }
}
