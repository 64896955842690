@import "~assets/styles/mixins.scss";

.vue-back-to-top {
    z-index: 9000 !important;
    width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: black;
    text-align: center;
    background: white;
    border: 2px solid black;
    border-radius: 1.25rem;
    .material-design-icon {
        // display required to override possible adblockers
        display: inline-flex !important;
    }
}
