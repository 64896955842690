@import "~assets/styles/mixins.scss";

body {
    margin: 0;
    padding: 0;
}

$main-content-margin: 45px;

#splash {
    position: absolute;
    z-index: 15000; // .dx-overlay-wrapper is 12000, this needs to exceed
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-size: 5rem;
    color: white;
    text-align: center;
    pointer-events: none;
    user-select: none;
    background: url('static/static/coverimages/background-1.jpg');
    background-color: #151c2c; // stylelint-disable color-no-hex
    // background: linear-gradient(to top, #151c2c, #2d3f69 15%, #d8aa6e 90%, #ce8236);
    // background: linear-gradient(to top, #d214ec, #419);
    // 210, 20, 246  ;  68, 17, 153
    // hero #151c2c 21, 28, 44  ;  #d8aa6e 216, 170, 110  ;  #ce8236 206, 130, 54
    background-size: cover;
    transition: opacity 1000ms ease-out;
    // class "done" set in main App
    &.done {
        opacity: 0;
    }
    // mimick Home page that is displayed after loading
    .loading-splash-screen {
        position: absolute;
        top: 0;
        left: 0;
        z-index: inherit;
        display: block;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 7vh 0 0;
        text-align: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        // animation: load-pulse 6s infinite linear;
        .loading-infobox {
            width: 50% !important;
            margin: auto;
            margin-top: 10rem !important;
            .loading-logo {
                width: auto;
                height: auto;
                figure {
                    width: auto !important;
                    height: auto !important;
                    margin: 0;
                    .loading-image {
                        width: inherit;
                        height: 220px; // taide.art svg logo
                        max-width: stretch;
                    }
                }
            }
            img {
                margin: 0 auto;
            }
            @media (min-width: 1216px) {
                width: 100%;
                margin: auto;
            }
            @media (max-width: 768px) {
                width: 75vw !important;
                max-width: 375px;
                margin: auto;
                padding-right: 0;
                padding-left: 0;
            }
        }
        @media (max-width: 400px) {
            padding-right: $main-content-margin;
            padding-left: $main-content-margin;
        }
    }
}

@keyframes load-pulse {
    0% {
        background: rgba(21, 28, 44, 0);
    }
    50% {
        background: rgba(21, 28, 44, 0.25);
    }
    100% {
        background: rgba(21, 28, 44, 0);
    }
}
