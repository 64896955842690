@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.modal-map {
    &.modal-dialog {
        img {
            width: 100%;
        }
        .entry-information-map-location {
            .address-details {
                margin-bottom: 0;
            }
            .geo-map {
                margin-top: 1rem;
            }
        }
    }
}
