@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.file-manager-wrapper {
    .upload-files {
        margin: 0.25rem 1rem;
        text-align: left;
    }
}
