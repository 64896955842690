@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

$logo-height: 70px;

.navbar {
    display: flex;
    flex-wrap: nowrap;
    width: 100vw;
    height: auto;
    min-width: 100vw;
    max-width: 100vw;
    padding: 10px $main-content-margin;
    font-family: $helvetica-medium;
    font-size: 1.15rem;
    user-select: none;
    .navbar-brand {
        z-index: 8001;
        min-height: unset;
        margin: 10px 1rem 0 0;
        padding: 0;
        a {
            margin-right: 10px;
        }
        .logo {
            width: auto;
            height: $logo-height;
            margin: 0;
            @include transition-bezier(height);
        }
        // development tag just next to the logo
        .dev-tag {
            position: absolute;
            margin: ($logo-height - 10) auto auto 0;
            font-family: $helvetica-bold;
            font-size: 9px;
            color: $dark-gray-4;
        }
        // secondary login for narrow displays
        .login, .register {
            display: none;
            button {
                margin-left: auto;
            }
            @media screen and (max-width: $breakpoint-desktop - 1) { // bulma breakpoint!
                display: inline-flex;
                flex-grow: 1;
                justify-content: flex-end;
                &.login {
                    margin-right: 45px !important;
                }
            }
            @media screen and (max-width: $breakpoint-mobile - 1) {
                // drop register on mobile --
                // NOTE: in the script, login button shows the reg.info tooltip because of this!
                // TODO: place the logic somewhere
                &.register {
                    display: none;
                }
            }
        }
    }

    .navbar-burger {
        width: $main-content-margin - 7;
        height: 3em;
        color: $light-gray-2;
        transform: scale(2.25);
        @include transition-bezier(color);
        span {
            height: 1px; // burger line thickness
        }
        &:hover {
            background-color: transparent;
        }
    }

    .navbar-menu {
        display: flex !important;
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: wrap;
        background-color: inherit;
        transform-origin: top;
        @include transition-bezier(all);
    }

    .navbar-start {
        z-index: inherit;
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
        margin: 0 0 0 auto;
        vertical-align: middle;
    }

    .navbar-item {
        display: inline-flex;
        margin: auto 0;
        font-family: $helvetica-medium;
        font-weight: normal;
        color: inherit;
        text-decoration: none;
        text-transform: uppercase;
        background-color: transparent;
        border-bottom: 1px solid transparent;
        outline: none !important;
        &:focus, &:not(:focus) {
            color: inherit !important;
            background-color: transparent !important;
        }
        &.navbar-icon {
            border-bottom: 0;
        }
        &.router-link-active {
            border-bottom: 1px solid white;
        }
        // the dropdown should overlay other menu elements
        &.has-dropdown {
            z-index: 5000;
        }
        &.collections {
            z-index: 5010;
        }
        &.index-search {
            width: 2rem;
            height: 2rem;
            margin-right: 1.5rem;
            cursor: pointer;
        }
        &.more {
            z-index: 5005;
        }
        .control.navbar-icon {
            margin: auto 0.75rem;
            font-size: inherit;
            &:not(:last-child) {
                margin-right: 1.25rem;
            }
        }
        .toggle-mode-edit,
        .select-use-profile {
            margin: 0.25rem auto;
            text-align: center;
        }
        .material-design-icon {
            width: 2rem;
            height: 2rem;
            margin: 0;
            margin-right: 0.5rem;
            color: inherit;
            vertical-align: middle;
            // transform: translateY(0px);
        }
    }

    .navbar-link {
        display: inline-flex;
        align-items: center;
        color: inherit;
        &:hover, &:focus, &:not(:focus) {
            color: inherit !important;
            background-color: transparent !important;
        }
        &::after {
            // bulma chevron
            border-color: white !important;
        }
    }

    // main navbar item font and transform
    .navbar-start > .navbar-item {
        text-shadow: 1px 1px 1px $dark-gray-3;
        &.navbar-item > span,
        .navbar-link {
            font-family: $helvetica-medium;
            font-weight: normal;
            letter-spacing: 1px;
        }
    }

    // stylelint-disable-next-line
    a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:hover, .navbar-link.is-active {
        color: inherit;
        background-color: transparent;
        // transition-property: all;
        // transition-duration: 750ms;
        // transition-timing-function: ease-out;
    }
    // stylelint-disable-next-line
    .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
        color: inherit;
        background-color: transparent;
    }

    .navbar-dropdown {
        display: flex;
        flex-direction: column;
        color: white;
        text-align: left;
        background-color: $dark-black;
        .navbar-item.router-link-active {
            text-decoration: underline;
            border-bottom: 0;
        }
        a.navbar-item:hover {
            color: inherit;
            background-color: transparent !important;
        }
    }
}

.navbar.main-navigation {
    z-index: 11999; // dropdowns should be visible, but tooltips (like register info) should overlap
    color: white;
    background-color: $dark-black;

    /*
     * slide-out / burger menu
     */
    @media screen and (max-width: $breakpoint-desktop - 1) {
        flex-wrap: wrap;
        .navbar-brand {
            width: calc(100vw - 90px);
        }
        .navbar-burger {
            // ensure navbar-burger visibility (bulma would hide it in between 1024 - 1088px width)
            display: flex;
        }
        .register-button-wrapper, .login-button-wrapper {
            margin-top: 6px !important;
        }
        .navbar-menu {
            $transition-duration: 0.5s;
            display: table;
            flex-direction: column;
            padding: 0;
            text-align: left;
            box-shadow: none;

            .navbar-link {
                display: flex;
            }

            .navbar-start {
                display: block;
                margin: 0 auto 0 0;
                // move this overlay out of view,
                // otherwise it will prevent interacting with the page underneath
                transform: translateX(-1000px);
                transition-duration: 0s;
                transition-delay: 2s; // exit effects transit during this time
                // transition-delay: 0s; // TODO: this needs some tinkering
            }

            .navbar-start > * {
                // slide nav-items to the left
                transform: translateX(-1000px);
                transition-property: all;
                transition-duration: 500ms;
                transition-timing-function: ease-in;
            }

            .navbar-item {
                display: table;
                height: auto;
                line-height: inherit;
                .control.navbar-icon {
                    margin-right: 2.5rem;
                }
                &.index-search {
                    margin-bottom: 1.5rem;
                }
            }

            //- some dropdown items are hidden with bulma .is-hidden-touch class,
            //- but they contained items need "reset" padding and font-size
            .navbar-dropdown {
                display: inline-flex;
                flex-wrap: wrap;
                padding: 0 0 1px;
                font-size: inherit;
                color: inherit;
                background-color: transparent;
                border: 0;
                box-shadow: none !important;
                .navbar-item {
                    display: inline-block;
                    padding-left: 0.75rem;
                }
            }

            .navbar-end {
                .field.is-grouped {
                    flex-direction: row;
                    flex-wrap: wrap;
                    .navbar-item.login {
                        margin-left: 0;
                    }
                }
            }

            .login-button-wrapper.navbar-item,
            .register-button-wrapper.navbar-item {
                // login button is visible outside the burger menu, hide it here
                display: none;
                margin-left: 0;
            }
            .logged-in img {
                margin-right: 1rem;
            }

            .language-select {
                margin: 3px auto auto 0.75rem;
                transform: none; // no scaling
                .navbar-dropdown {
                    flex-direction: row;
                    margin-left: 0.75rem;
                }
                .navbar-link {
                    display: none;
                }
            }

            .user-tool {
                //- hide tools in burger menu mode
                display: none !important;
            }

            // when the burger menu is open on mobile
            &.is-active {
                padding-top: 0.5rem;
                background: rgba(0, 0, 0, 0.95);
                .navbar-start {
                    line-height: inherit;
                    transform: translateX(0);
                    transition-duration: 0s;
                    transition-delay: 0s;
                }
                .navbar-start > * {
                    transform: translateX(0) translateY(0);
                    transition-timing-function: ease-out;
                }
            }
        }

        $items: 12;
        $transition-delay: 0.05s;

        /* stylelint-disable no-duplicate-selectors, no-descending-specificity */

        // Setting delays for the nav items in open transition
        .navbar-menu.is-active {
            .navbar-start {
                @for $i from 1 through $items {
                    & > *:nth-child(#{$i}) {
                        $delay: ($i - 1) * $transition-delay;
                        transition-delay: $delay;
                        // &::after {
                        //     content: "#{$i}"; // debug
                        // }
                    }
                }
            }
        }

        // Setting delays for the nav items in close transition
        .navbar-start {
            @for $i from 1 through $items {
                & > *:nth-child(#{$i}) {
                    $delay: ($items - $i) * $transition-delay;
                    transition-delay: $delay;
                }
            }
        }

        /* stylelint-enable no-duplicate-selectors, no-descending-specificity */
    } // end @media

    .navbar-menu.no-transition > * {
        transition-property: none;
        transition-delay: 0s;
    }

    // important to set default menu height
    @media screen and (min-width: $breakpoint-desktop) {
        .navbar-menu {
            height: auto !important;
        }
    }

    // tiny screen adjustments
    @media screen and (max-width: $breakpoint-mobile - 1) {
        padding-right: 16px;
        padding-left: 16px;
        .navbar-brand {
            width: 95vw;
        }
        .navbar-burger {
            right: 1.5em;
        }
        .navbar-menu.is-active {
            margin-right: 0.5em;
        }
    }

    .login, .register {
        margin: auto 0;
        margin-left: auto;
        &.logged-in {
            z-index: 5000;
            display: flex;
            flex-direction: column;
            padding: 0 0.75rem;
            line-height: normal;
            vertical-align: middle;
            cursor: pointer;
            border: 0px solid white;
            border-radius: 0;
            .material-design-icon {
                margin-right: 0.5rem;
                vertical-align: middle;
            }
            svg {
                color: inherit;
            }
            img.avatar {
                width: auto;
                height: auto;
                max-width: 2rem;
                max-height: 2rem;
                border-radius: 1.25rem;
                object-fit: cover; // NOTE: profile image may be any aspect ratio!
            }
            .navbar-link {
                padding-left: 0;
            }
        }
        // login-button, register-button styles in app.scss
        button {
            // transition to dark background
            @include transition-bezier(all);
            // text-transform: uppercase;
        }
    }
    button.logout {
        cursor: pointer;
        border: 0 !important;
    }
    .language-select {
        display: inline-flex;
        padding-right: 0;
        font-family: $helvetica-bold;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        text-transform: uppercase;
        cursor: pointer;
        button {
            cursor: pointer;
            border: 0 !important;
        }
    }
    .user-tool {
        display: flex;
        padding: 0.375rem 0 !important;
        .tool-head {
            border-bottom: 0;
            img {
                max-width: unset;
                max-height: 20px;
                margin-left: 1rem;
            }
            h3 {
                font-size: 12px;
            }
        }
        .tool-body {
            width: 100%;
            border-bottom: 2px solid $dark-gray-3;
            a {
                padding: 2px 1rem 0;
                font-family: $helvetica-medium;
                font-size: inherit;
                color: white;
            }
            .material-design-icon {
                width: 18px !important;
                height: 18px !important;
            }
        }
    }
    .invite {
        width: 100%;
    }

    // hide navbar items with 'no-items' classname
    &.no-items {
        .navbar-start > .navbar-item {
            display: none;
        }
    }

    &.no-login {
        .login {
            display: none;
        }
    }

    &.no-register {
        .register {
            display: none;
        }
    }

    &.no-logo {
        .navbar-brand {
            display: none;
        }
    }

    &.fixed {
        position: fixed;
    }
    &.absolute {
        position: absolute;
    }

    &.transparent {
        background: transparent;
        background-color: transparent !important;
        transition: background-color 800ms cubic-bezier(0.08, 0.38, 0.03, 0.63) !important;
        .navbar-link::after {
            transition: border-color 800ms cubic-bezier(0.08, 0.38, 0.03, 0.63) !important;
        }
        .login {
            svg {
                color: inherit !important;
            }
        }
        /* stylelint-disable no-descending-specificity */
        &.light {
            .navbar-item {
                color: $dark-black;
                &:hover {
                    color: $dark-gray-2 !important;
                }
            }
            .navbar-brand, .navbar-burger {
                color: $dark-black !important;
            }
            .navbar-link::after {
                border-color: $dark-black !important;
            }
            .login {
                svg {
                    color: $dark-black !important;
                }
            }
            .language-select {
                color: $dark-black !important;
                &:hover {
                    color: $dark-black !important;
                }
            }
        }
        &.dark {
            .navbar-item {
                color: white;
                &:hover {
                    color: $light-gray !important;
                }
            }
            .navbar-brand, .navbar-burger {
                color: white !important;
            }
            .navbar-link::after {
                border-color: white !important;
            }
            .login > button, .register-button {
                text-shadow: 1px 1px $dark-gray-3;
                border: 0;
                svg {
                    color: white !important;
                }
            }
            .language-select {
                color: white !important;
                text-shadow: 1px 1px $dark-gray-3;
                &:hover {
                    color: white !important;
                }
            }
        }
        .navbar-dropdown .navbar-item {
            color: white !important;
            &:hover {
                color: unset !important;
            }
        }
        /* stylelint-enable no-descending-specificity */
    }
}
