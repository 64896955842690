@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-saved {
    .saved-artworks {
        .stacking-modes-toolbar {
            margin-top: -0.5rem;
        }
    }
    .artwork-tools {
        width: 45px;
        height: 45px;
    }
    .image-tool.unsave {
        margin: 2.5rem;
        .material-design-icon {
            width: 26px;
            height: 26px;
        }
    }

    .saved-audios,
    .saved-videos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 1rem;
        .audio-card,
        .video-card {
            display: inline-flex;
            // flex-grow: 0; // margin or not!
            flex-grow: 1;
            justify-content: space-between;
            width: 25vw;
            min-width: $mobile-absolute-max-width;
            max-width: 450px;
            margin: 0 1em 1em 0;
            box-shadow: 0 0 5px black;
        }
    }
}
