@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#artworks-search {
    .artwork-query-options {
        .search-input-text {
            margin-right: 1.5rem;
        }
    }
    .page-images-select {
        display: inline-block !important;
    }
    .artworks-search-results {
        flex-grow: 0;
        .chart-container {
            position: relative;
            width: auto;
            height: auto;
            margin-bottom: 2rem;
        }
        h2.total-count {
            display: block;
            margin: 0 0 2rem;
            font-family: $main-serif;
            font-size: 1.5rem;
        }
        .item {
            margin: 0 10px 15px 0;
            &:last-child {
                margin-right: 0;
            }
        }
        .card {
            align-self: flex-end;
            figure {
                margin: 0;
            }
        }
        .stacking-modes-toolbar {
            position: relative;
            top: 0;
            justify-content: flex-end;
            width: 100%;
            margin-top: 0;
            margin-bottom: 1rem;
        }
        .artwork-sidebar {
            // hide the actor follow button
            .actor-card .social-button-wrapper {
                display: none;
            }
        }
        .pagination-header {
            align-items: center;
            .page-images-select {
                display: none;
            }
        }
    }
}
