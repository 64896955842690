@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.hero-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    align-items: left;
    width: 100vw;
    // height: calc(100vh - 108px); // navbar height ~108px
    height: 100vh; // with transparent navbar position: absolute
    margin: 0;
    padding: 110px $main-content-margin 0;
    text-align: center;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    // background image transition effect
    transition-property: background-image, background-position;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    &.is-fullheight {
        position: absolute;
        background-attachment: fixed;
        background-size: cover;
        background-position: center center;
    }
    .contents {
        justify-content: space-between;
        width: 100%;
    }
    .segment {
        max-width: $main-content-max-width;
    }
    .card {
        .card-image {
            // hide card image
            display: none;
        }
        .card-header {
            background: rgba(239, 239, 239, 0.75);
        }
    }
    .btn {
        display: inline-flex;
        align-content: center;
        text-align: middle;
        span {
            align-self: center;
        }
    }
    .infobox {
        position: static;
        top: 0;
        left: 0;
        width: auto;
        min-width: 460px;
        max-width: 75vw;
        margin-right: auto;
        margin-left: auto;
        transform: translateX(120vw) translateY(50vh); // initially position outside the screen
        transition-property: transform, opacity;
        transition-duration: 240ms; // will be reset in script, this is for initial infobox
        transition-timing-function: ease-in-out;
        h4 {
            margin: 1.5rem auto 2rem;
            font-size: 1.5rem;
        }
        em {
            font-size: 1.5rem;
        }
        .buttons {
            display: inline-flex;
            align-items: center;
            justify-items: center;
        }
        button.btn {
            margin: 2rem 0.5rem 0.5rem !important;
            padding: 10px 0.8rem !important;
            font-family: $helvetica-bold;
            color: $dark-gray-2;
            text-transform: uppercase;
            background: $light-gray;
        }
    }
    .preload-image {
        z-index: -1;
        display: none;
    }

    @media screen and (max-width: $breakpoint-mobile - 1) {
        padding-right: 0;
        padding-left: 0;
        .segment {
            max-width: $mobile-content-max-width;
            margin-top: 0;
        }
        .infobox {
            min-width: unset;
            max-width: 98vw;
        }
    }
}
