@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.facet-categories {
    z-index: 100;
    .plus-more {
        margin: 1rem auto 0;
    }
    td:nth-child(2) {
        padding-right: 1rem;
    }
    .dx-treelist-content {
        font-family: $main-serif;
    }
    .dx-treelist-rowsview {
        border: 0;
    }
    .dx-treelist .dx-column-lines > td {
        border: 0;
    }
    .dx-treelist-text-content {
        cursor: pointer;
    }
    .count-line {
        position: absolute;
        display: block;
        width: 87%;
        height: 3px;
        // margin-left: 35px; //- generic dx theme
        margin-left: 55px; //- material dx theme
    }
}
