@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-liked {
    margin-top: 1.5rem;
    .stacking-modes-toolbar {
        margin-top: 1.5rem;
    }
    // show hidden timestamp
    .artwork-table-row .artwork-details .social-timestamp {
        display: block;
    }
}
