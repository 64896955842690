@import "~assets/styles/mixins.scss";

.tribe-widget {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 110px);
    padding-top: 56.25%;
    overflow: hidden;
    scrollbar-width: none;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: content-box;
        width: 500px;
        height: 100%;
        overflow: hidden;
        border: 0;
        scrollbar-width: none;
    }
}
