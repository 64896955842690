@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.collection-card {
    .curator {
        font-family: $helvetica-light;
    }
    .body-html {
        font-family: $helvetica-light;
        font-weight: normal;
        letter-spacing: 1px;
    }
}
