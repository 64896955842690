@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#actors-search {
    .results {
        .pagination-wrapper {
            width: 100%;
        }
        .masonry-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            text-align: center;
            .item {
                margin: 0 auto 1.5rem;
            }
        }
        .VuePagination__count {
            display: none;
        }
        .actor-card {
            width: 280px;
            .actor-type {
                font-family: "Sabon LT W01 Italic", serif;
                font-size: 1rem;
                line-height: 1rem;
            }
            .card-image {
                width: 100%;
                height: auto;
                max-height: 280px;
                .image {
                    margin: 0 auto;
                }
            }
            .card-header-title, .card-contents {
                align-items: center;
                text-align: center;
            }
            .ingress-text {
                display: none;
            }
        }
    }
}
