@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.tribe1-page {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.tribe1-page-if {
    width: 100%;
    height: calc(100vh - 110px);
}
