@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.select-use-profile {
    margin: auto;
    line-height: 0;
    .material-design-icon {
        width: 28px !important;
        height: 28px !important;
        cursor: pointer;
    }
    &.active {
        .material-design-icon {
            color: $pastel-green; // earth color
        }
    }
}
