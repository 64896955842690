@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.social-comments {
    .dx-texteditor {
        border: 0;
    }
    .dx-texteditor-input {
        border-width: 0 0 1px 0 !important;
    }
    .dx-placeholder {
        font-family: $helvetica-light !important;
        font-style: italic;
    }
}
