@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#user-profile {
    text-align: left;

    .title {
        border: 0;
    }

    .submenu.navbar {
        border-width: 0;
    }

    .subset-title-row {
        @include gradient-title-row;
    }

    .dx-tabs {
        margin-bottom: 1rem;
    }

    .silhouette-assistant {
        margin-top: 2rem;
    }

    .artwork-table-row {
        .social-actions,
        .heart-count {
            display: none;
        }
    }
    .masonry-wrapper {
        .social-button-wrapper {
            display: none;
        }
    }
    .image-not-available {
        opacity: 0;
    }

    /* DxAccordion title styles in accordion-title-toolbar */
    .dx-accordion-item {
        margin: 3px 3px 5px;
    }
    .dx-accordion-item-opened {
        margin-top: 6px !important; // dx default + missing border
    }
}
