@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.accordion-title-toolbar {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    min-height: 89px;
    margin-bottom: 0;
    font-family: $helvetica-bold;
    font-weight: 400;
    font-size: 1rem;
    color: $dark-gray-2;
    transition-property: background-color, height;
    transition-duration: 750ms;
    transition-timing-function: ease-out;
    .tool-head {
        display: inline-flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: flex-start; // scope name aligned left
        justify-items: center;
        justify-self: flex-start;
        min-width: 320px;
        // border: 1px dotted green;
        .tool-image-shortname {
            display: inline-flex;
            flex-direction: row;
            width: 100%;
            border-bottom: 2px solid $medium-gray;
            .title {
                align-self: center;
                margin-left: 0.5rem;
                font-size: 1.5rem;
                color: $dark-gray-2;
            }
            a.title {
                color: $light-purple;
            }
        }
        .tool-name {
            display: inline-flex;
            margin: 0.25rem 0.5rem;
            font-size: 1.25rem;
        }
    }
    .tool-links {
        display: inline-flex;
        flex-grow: 0;
        align-self: center;
        justify-content: flex-end;
        width: auto;
        margin: 4px 4rem 0 2rem;
        // border: 1px dotted magenta;
    }
    img {
        width: auto;
        max-height: 32px;
    }
    .material-design-icon {
        width: 24px !important;
        height: 24px !important;
    }
    @media screen and (max-width: $breakpoint-mobile) {
        .tool-head {
            align-items: flex-end; // scope name aligned right
            min-width: 60vw;
        }
        .tool-links {
            align-self: flex-end;
            margin-left: auto;
        }
    }
}
