@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.feature-article-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-content: baseline;
    align-self: flex-start;
    justify-content: center;
    margin-top: 0;
    background: $light-gray;
    .card-header {
        z-index: 200;
        width: auto;
        margin: 9px 0 0 0.5rem;
        padding: 0.5rem 1rem;
        color: $dark-gray;
        background: $light-gray;
        border-left: 3px solid black;
        header.tile {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0 1rem 1rem 0 !important;
            font-size: 1rem;
        }
    }
    .card-header-title {
        display: inline-block;
        width: auto;
        height: auto;
        min-width: unset;
        margin: 0;
        padding: 0;
        // @include fonts-debug(true, true);
        font-family: "Sabon LT W01 Regular", serif;
        color: inherit;
        text-align: left;
        h2.title {
            margin-bottom: 1rem;
            font-family: $article-title-font-sans-serif;
            font-size: 1.25rem;
            line-height: normal;
            letter-spacing: 0;
            color: inherit;
            @include dowrap;
        }
    }
    .ingress-text {
        height: auto;
        // min-height: 80px;
        // max-height: 180px;
        font-family: $helvetica-roman;
        font-weight: 400;
        // white-space: normal;
    }
    .details {
        margin: 1rem 0 0.5rem;
        font-family: "Sabon LT W01 Regular", serif;
    }
    .card-image {
        z-index: 100;
        align-self: stretch;
        width: 100%;
        height: inherit;
        min-height: fit-content;
        background-size: cover;
        background-position-x: 50%;
    }
    .card-contents {
        display: inline-flex;
        flex-direction: column;
        flex-grow: 1;
        align-self: left;
        width: 50%;
        margin-left: 2rem !important;
        padding: 0.5rem 2rem;
        background: rgba(255, 251, 251, 0.65);
        @media screen and (max-width: $breakpoint-tablet - 1) {
            width: auto;
        }
    }
    .card-hero {
        .article-ingress {
            max-width: 32rem;
            font-family: $helvetica-roman;
            word-break: initial;
            em {
                display: block;
                font-family: $helvetica-italic;
                font-style: normal;
                font-size: 1rem;
            }
            b {
                font-weight: normal;
                font-size: 1.125rem;
            }
            h2 {
                margin: 1rem 0;
                // @include fonts-debug(true, false);
                font-family: $article-title-font-sans-serif;
                font-weight: normal;
                font-size: 2.5rem;
                line-height: normal;
                word-break: initial;
                @media (max-height: 500px) {
                    font-size: 1.5rem;
                }
            }
            .ingress-text {
                height: auto;
                font-size: 1.15rem;
            }
            .btn {
                margin: 2rem auto 1rem 0;
                padding: 0.625rem;
                border: 1px solid black;
            }
        }
    }
}
