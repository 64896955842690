@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.entry-information-ticket-prices {
    .ticket-prices-table {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: flex-start;
    }
    .add-ticket-price {
        display: inline-flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 10rem;
        text-align: center;
        .material-design-icon {
            width: 32px;
            height: 32px;
            cursor: pointer;
        }
        &.disabled {
            display: none;
        }
    }
    .ticket-price {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        align-items: flex-start;
        justify-content: flex-start;
        min-width: 10em;
        margin: 0 1rem 2rem 0;
        font-family: $helvetica-roman;
        font-size: 0.875rem;
        color: $dark-gray;
        .ticket-classes {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 1;
            flex-wrap: wrap;
            align-self: flex-start;
            order: 2;
            width: 100%;
            margin-right: 2rem;
            .ticket-class {
                display: flex;
                justify-content: space-between;
                margin: 0.25rem;
                margin-left: 0;
                text-align: left;
                h4 {
                    font-family: inherit;
                }
                .material-design-icon {
                    margin: auto;
                    margin-right: 0;
                    cursor: pointer;
                }
            }
        }
        .price-amount {
            display: inline-flex;
            flex-wrap: wrap;
            align-self: stretch;
            order: 1;
            font-size: 1rem;
            .free-entry {
                font-family: $helvetica-italic;
                font-size: 1rem;
            }
            .dx-textbox, .dx-numberbox {
                display: inline-table;
                text-align: center;
            }
        }
    }

    .submit-button {
        justify-self: center;
        margin: 2rem auto;
    }
}
