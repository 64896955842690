@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.entry-information-entry-description {
    display: block;
    .panel-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        min-height: 400px;
        margin-bottom: 1rem;
        .image-not-available {
            display: none;
        }
        &:nth-child(even) {
            flex-direction: row-reverse;
            .image-box {
                margin-right: 1rem;
            }
        }
        &:nth-child(odd) {
            flex-direction: row;
            .entry-panel {
                margin-right: 1rem;
            }
        }
    }
    figure {
        display: inline-block;
        width: auto !important;
        height: auto !important;
        min-width: 280px;
        max-width: 800px;
        margin-bottom: 0;
        .image {
            width: 100% !important;
            height: 100% !important;
            background-size: cover;
        }
    }
    // stylelint-disable-next-line no-descending-specificity
    .entry-panel {
        display: inline-flex;
        flex-direction: column;
        width: auto;
        height: auto;
        min-width: 40rem;
        max-width: 60rem;
        margin: 0;
        padding: 1rem;
        font-size: 0.875rem;
        background: $light-gray-2;
        &.entry-information-map-location {
            width: 100%;
            max-width: 1200px;
        }
        .page-text {
            max-width: 55vw;
        }
        h2 {
            margin-bottom: 1rem;
            font-family: $helvetica-bold;
            font-size: 1.5rem;
        }
        h4 {
            font-family: $helvetica-bold;
            font-size: 0.875rem;
        }
        .entry-title {
            .dx-texteditor-container {
                font-family: $helvetica-bold;
                font-size: 1.5rem;
                line-height: normal;
            }
        }
    }
    // stylelint-disable-next-line no-descending-specificity
    .location-panel .entry-panel {
        width: 60vw;
        max-width: 730px; // 60% of $breakpoint-widescreen (1216px)
    }
    .page-text .dx-htmleditor {
        min-width: unset;
    }

    @media screen and (max-width: $breakpoint-widescreen - 1) {
        min-width: 50vw;
        .page-text {
            max-width: unset;
        }
        .location-panel {
            height: max-content;
            max-height: unset;
            .entry-panel {
                width: 100%;
                min-width: 60vw;
                max-width: unset;
            }
            .image-box {
                display: none;
            }
            .geo-map {
                // leave some space to scroll, to not get "locked into" the "scroll sandpit" of map element
                max-width: 90vw;
            }
        }
    }
    @media screen and (max-width: $breakpoint-desktop - 1) {
        .panel-wrapper {
            flex-direction: column !important;
            .image-box {
                width: 100% !important;
                max-width: unset !important;
                min-height: 220px;
                margin-right: 0 !important;
            }
            .entry-panel {
                width: 100% !important;
                max-width: unset !important;
                min-height: 220px;
                margin-right: 0 !important;
            }
        }
        .location-panel .geo-map {
            // leave some space to scroll
            max-width: 80vw;
        }
    }
    @media screen and (max-width: $breakpoint-tablet - 1) {
        max-width: unset;
        margin-right: auto;
        .entry-panel {
            min-width: unset !important;
        }
        .page-text {
            max-width: unset;
        }
    }
    @media screen and (max-width: $breakpoint-mobile - 1) {
        .location-panel .geo-map {
            // leave some space to scroll
            max-width: 70vw;
        }
    }
}
