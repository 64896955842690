@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.osm-forward-search-result {
    display: flex;
    align-items: flex-start;
    padding-bottom: 1rem;
    cursor: pointer;
    user-select: none; // no copy/drag select
    .result-info {
        display: flex;
        flex-direction: column;
    }
    .icon-taide-art {
        margin: 0.5rem 0.75rem 0.5rem 0;
    }
    .semantics {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        img {
            margin: 0.25rem 0.5rem 0.25rem 0;
        }
        .semantic-class {
            align-self: flex-end;
            margin-left: auto;
        }
    }
    &.selected {
        font-family: $main-sans-serif;
        font-size: 1.15rem;
        color: $dark-gray-2;
    }
    &.not-selected {
        font-family: $helvetica-italic;
        font-size: 1rem;
        color: $dark-gray-4;
    }
}
