@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#email-verified {
    @include info-message-page;

    .infobox {
        .error {
            font-size: 2.5rem;
        }
        .error-message {
            font-family: $helvetica-italic;
            color: $red;
        }
        .support-message {
            font-size: 1.5rem;
        }
        .success-message {
            margin: 2rem auto;
            font-family: $helvetica-italic;
            font-size: 1.25rem;
        }
        .error-email {
            color: $error;
        }
        .login-button {
            margin: 2rem auto 1rem;
        }
    }
}
