@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.privacy-policy {
    max-width: $mobile-content-max-width;
    margin-top: 2rem;

    .contents {
        margin-right: 1rem;
        padding-left: 2rem;
        font-family: $main-sans-serif;
        color: $dark-gray-2;
    }
    .title {
        margin: 0 3rem 2.5rem 0;
        font-family: $helvetica-bold;
        font-size: clamp(2.5vw, 3rem, 3.5vw);
        line-height: 1.5;
        color: inherit;
        text-align: left;
        word-break: break-word;
    }
    .para-title {
        margin: 2rem 0 0;
        font-family: $helvetica-bold;
        font-size: clamp(1.2vw, 1.25rem, 3vw);
        line-height: 1.5;
        color: inherit;
        text-align: left;
        word-break: break-word;
    }
    .para-text {
        max-width: 60em;
        margin: 1rem 0;
        font-family: $main-sans-serif;
        font-size: clamp(1vw, 1rem, 2.5vw);
        line-height: 1.75;
        color: inherit;
        text-align: left;
        word-break: break-word;
        white-space: pre-line;
    }
}
