@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-collections-management {
    font-family: $main-sans-serif;

    section {
        margin-bottom: 1rem;
        .subset-title-row {
            margin-bottom: 0.75rem;
        }
    }
    .user-tool {
        max-width: 400px;
        .tool-head img {
            max-height: 32px;
        }
        .tool-body a {
            font-size: 1rem;
        }
    }
}
