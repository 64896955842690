@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.actor-highlights {
    width: 100%;
    .columns {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @media (max-width: 850px) {
            justify-content: center;
            &.actor-query-options {
                justify-content: left;
            }
        }
        @media screen and (max-width: $breakpoint-mobile - 1) {
            flex-direction: column !important;
        }
    }
    .slick-track {
        min-height: unset;
        // without this margin, some content might get clipped
        margin-bottom: 1.5rem;
    }
    .actor-slides {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        padding: 0;
        .slick-slide:nth-child(odd) {
            .columns {
                flex-direction: row-reverse;
            }
        }
        .slick-slide:nth-child(even) {
            .actor-body-artworks .card {
                margin-left: auto;
            }
        }
        .actor-card {
            margin-top: 0;
            text-align: center;
            .card-image {
                width: 180px !important;
                height: auto;
                margin: 0 auto !important;
                figure {
                    height: auto !important;
                }
                .image {
                    margin: 0 auto;
                }
            }
            .card-header-title {
                align-items: center;
                margin-top: 1.5rem;
                text-align: center;
                h2.title {
                    overflow: visible;
                    font-size: 1.25em;
                }
            }
            .social-actions {
                display: inline-flex;
                flex-direction: row !important;
                width: 100%;
                margin: 0.75rem auto 1rem 0;
                padding: 0;
                font-size: 0.8rem !important;
                .social-button-wrapper {
                    margin: 0 auto;
                }
                .do-heart {
                    margin-left: 0;
                    border-color: black;
                    border-width: 2px;
                }
                .do-follow span {
                    vertical-align: sub;
                }
            }
            .makers, .ingress-text {
                display: none;
            }
        }
        .actor-body {
            margin-bottom: 1.5rem;
            article {
                // CSS fade!
                @include fade-read-more(400px);
            }
            .btn {
                height: 2rem;
                margin-top: 1.5rem;
                color: inherit;
                border: 0;
            }
        }
        .actor-body-artworks {
            &.is-6 {
                // stylelint-disable-next-line no-descending-specificity
                .card {
                    width: 280px !important;
                }
            }
            &.is-12 {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: $breakpoint-mobile - 1) {
        .actor-body-artworks {
            .artwork-card {
                width: 100% !important;
                height: auto;
            }
        }
    }
}
