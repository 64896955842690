@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.audio-card {
    color: black;
    background: white;
    .card-image {
        display: inline-flex;
        flex-grow: 0;
        width: 100%;
        height: 100%;
    }
    .image-not-available .image {
        background-size: auto;
    }
    .card-header {
        flex-grow: 0;
        width: 100%;
        padding: 0.25rem !important;
        padding-left: 0.5rem !important;
        font-size: 1.15rem;
        h2.title {
            @include dowrap;
            max-width: calc(100% - 55px); // leave space for .podcast-title-icon
            font-size: 20px;
        }
    }
    .card-contents {
        padding: 0.25rem 1rem 0.5rem;
        color: inherit;
    }
    .caption {
        font-size: 18px;
    }
    .social-button-wrapper {
        position: absolute;
        right: 0;
        bottom: 0.25rem;
    }
    &:hover {
        .social-button-wrapper {
            display: inline-flex;
        }
    }
    // .placeholder.material-design-icon {
    //     position: absolute;
    //     top: 75px;
    //     left: 44%;
    //     width: 64px;
    //     height: 64px;
    //     color: white;
    //     background: radial-gradient(rgba(255, 22, 34, 0.85) 0%, rgba(221, 128, 22, 0.85));
    //     border-radius: 32px;
    // }
}
