@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#artworks-index {
    font-size: 1rem;
    .query-options .is-4 {
        width: auto;
    }
    // default size
    .card-image {
        // height: 320px;
        .image {
            background-size: cover;
            background-position: center 50%;
        }
    }
    .slides-classification1,
    .slides-classification2,
    .slides-classification3,
    .slides-subClassification1,
    .slides-subClassification2,
    .slides-text1,
    .slides-text2 {
        .slick-slide {
            display: inline-block;
            float: none;
            vertical-align: baseline;
            & > div {
                margin: 0.375rem; // margin for box-shadow
            }
        }
        .credits {
            display: none;
        }
    }
    .slides-classification1,
    .slides-text2 {
        .slick-slide {
            display: inline-block;
            float: none;
            vertical-align: middle;
        }
    }
    .slides-subClassification1,
    .slides-text1 {
        .slick-slide {
            display: inline-block;
            float: none;
            vertical-align: top;
        }
    }
    .slides-classification2 {
        .slick-slide {
            display: inline-block;
            float: none;
            vertical-align: baseline;
        }
    }
    .slides-classification3 {
        .slick-arrow {
            top: 44.25%;
        }
    }
}
