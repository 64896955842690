@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.search-page {
    .card-image {
        flex-grow: 2;
        height: unset;
//        width: 280px !important;
//        min-height: 280px; // !important;
    }
    .card-contents {
        flex-grow: 0;
    }
    .plain-wrapper {
        .image {
            background-position: 50%;
        }
    }
    .collection-card {
        .card-image {
            min-height: 280px;
        }
    }
    .organization-card {
        .card-image {
            min-height: 280px;
        }
    }
    .event-card {
        .card-image {
            min-height: 280px;
        }
    }
    //.organization-card,
    //.event-card
  //  .card-image {
        // NOTE: some image items are wide and masonry helper increases width by multitude then,
        //       and this breaks the layout here so force 280x280 px images
      //  width: 280px !important;
      // height: 280px !important;
   // }
}
