@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#videos-index {
    .segment-title {
        display: block;
        width: 100%;
        margin: 0 auto 2rem;
        text-align: center;
        h1, h2 {
            font-family: "Sabon MT W01 Regular", serif;
            font-size: 3.25rem;
            color: $dark-gray;
        }
        hr {
            display: block;
            float: none;
            margin: 0.75rem auto;
        }
    }
    .query-options.columns {
        .column {
            flex-basis: unset;
        }
    }
    .search-input-text {
        max-width: 50vw;
        @media screen and (max-width: $breakpoint-tablet - 1) {
            max-width: unset;
        }
    }
    .video-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .video-card {
        display: inline-flex;
        flex-grow: 1;
        align-content: stretch;
        justify-content: space-between;
        width: 24vw;
        min-width: $mobile-absolute-max-width;
        max-width: 500px;
        margin: 0 1em 1em 0;
        box-shadow: -1px 2px 12px $dark-gray;
        &:nth-child(2n) {
            box-shadow: 1px -2px 12px $dark-gray-2;
        }
        &:nth-child(3n) {
            box-shadow: -1px -2px 12px $dark-gray-4;
        }
        &:nth-child(4n) {
            box-shadow: 1px 2px 12px $dark-gray-3;
        }
        .card-image {
            flex-grow: 1;
            min-height: 400px;
        }
    }
}
