@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.image-tool {
    position: static;
    top: 64px;
    right: 64px;
    z-index: 5000;
    display: inline-grid;
    align-content: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin: 1.25rem;
    color: black;
    text-align: center;
    cursor: pointer;
    background: white;
    .material-design-icon {
        width: 22px;
        height: 22px;
        color: black;
        // transform: scale(1.05);
    }
    &.circle {
        padding: 8px;
        border: 2px solid black;
        border-radius: 40px;
    }
    &.transparent {
        background: rgba(255, 251, 251, 0.5);
    }
}
