@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.home-actors {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    width: 95%;
    height: inherit;
    padding-bottom: 25vh;
    vertical-align: middle;
    .slick-wrapper {
        width: 100%;
        margin-top: 1rem;
    }
    .columns {
        display: flex;
        flex-wrap: wrap;
        width: 97%;
        margin: 0 auto;
        @media (max-width: 850px) {
            justify-content: center;
        }
        @media screen and (max-width: $breakpoint-mobile) {
            flex-direction: column !important;
        }
    }

    .other-actors {
        display: block;
        flex-wrap: wrap;
        .slick-track {
            display: flex;
            min-height: unset;
        }
        .slick-list {
            padding-left: 1rem;
        }
        .slick-dotted.slick-slider {
            margin-bottom: 50px;
        }
        .slick-slide {
            display: inline-flex;
            float: none;
            &.slick-active :last-child {
                margin: 0 auto;
            }
        }
        .card {
            align-items: center;
            margin: 0 auto;
            text-align: center;
            white-space: break;
            .card-header {
                margin: 1rem auto;
                h2 {
                    white-space: normal;
                }
                .artworks-counted {
                    font-size: smaller;
                }
                .card-header-title {
                    text-align: center;
                }
            }
            .card-image {
                width: 180px !important;
                height: 180px !important;
                margin: 0 auto;
                @include actor-circle;
                .image {
                    border: 0;
                }
            }
        }
    }
}
