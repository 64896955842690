@import "~assets/styles/mixins.scss";

@import '../FeatureArticle/index-cards';

.home-articles {
    padding-bottom: 25vh;
    background-color: $light-gray;
    .card-container {
        flex-basis: 320px;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-left: 0;
        @include feature-article-index-cards;
    }
    .spotlight {
        .card .card-image {
            flex-grow: 2;
            @media screen and (min-width: $breakpoint-desktop) {
                height: 50vh;
            }
            figure {
                height: inherit !important;
            }
        }
        .card h2.title {
            font-size: 2rem;
        }
    }
}
