@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

#register-info-page {
    @include info-message-page;
    padding: 2em 5vw;

    h1 {
        margin-bottom: 1rem;
        font-size: 2.5rem;
    }
    .silhouette-assistant {
        margin-top: 2rem;
    }
    // bigger margins for more text in speech bubble
    .info-message {
        margin-top: 0.5rem;
        font-family: $helvetica-thin;
        font-weight: 600;
        letter-spacing: 1px;
        h2, h3, h4 {
            display: block;
            margin: 1rem auto;
            font-family: $helvetica-bold;
            font-size: 1.25rem;
            color: $dark-gray-2;
        }
        h4 {
            margin: 1rem auto 0.5rem;
            font-size: 1rem;
        }
        & > div {
            margin-bottom: 0.25rem;
        }
        .login-button,
        .register-button {
            margin: 1.5rem auto 0.75rem;
            padding: 0.5rem 1.25rem;
        }
        .register-button {
            margin-bottom: 1.25rem !important;
        }
        .auth-provider-icons {
            display: inline-flex;
            justify-content: space-around;
            width: 120px;
            margin: auto;
            margin-left: 1em;
        }
    }
    .inverse-colors {
        h2, h4 {
            color: $medium-gray-3;
            border-color: $medium-gray-3;
        }
    }
    .register-link {
        margin-bottom: 2.5rem;
    }
    @media screen and (max-width: $breakpoint-mobile - 1) {
        .info-message {
            padding: 0.5rem;
            font-size: smaller;
            background: rgba(247, 251, 251, 0.75);
            border-radius: 0.25rem;
            box-shadow: 0 0 6px $light-gray-2;
            p {
                padding: 0 1.5rem;
            }
            h2 {
                font-size: 1.5rem;
            }
        }
    }
}
