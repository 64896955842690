@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.modal-save-pending {
    .success-emblem {
        font-size: 10em;
        color: rgb(236, 236, 24);
        transition: all 500ms ease-out;
    }
}
