@import "~assets/styles/mixins.scss";

@import './Card';

.video-player {
    display: inline-flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    height: inherit;
    padding: 0;
    background: black;
    .card-media {
        min-width: unset;
        max-width: unset;
        min-height: 450px;
        // min-height: min-content; // NOTE: fixes huge top and bottom black bars where supported, but breaks video where it isn't...
        // max-height: 70vh;
        overflow: initial;
        .azuremediaplayer {
            width: 100%;
        }
    }
    .card-image {
        width: auto;
        height: auto;
        min-height: 250px;
    }
    .card-header-title {
        h2.title {
            max-width: unset;
            font-size: 1.5rem;
            color: $medium-gray-3;
        }
    }
    .card-header,
    .card-contents {
        z-index: 5000;
        color: $medium-gray-3;
        background: black;
        .title {
            color: $medium-gray-3;
        }
    }
    .card-contents {
        justify-content: space-between;
        padding: 1rem;
    }
    .card-attribution {
        .credit-line, .copyright {
            color: $medium-gray-2;
        }
    }
    .social-button-wrapper {
        margin-left: auto;
    }
    .failure {
        margin: 1rem auto;
        .material-design-icon {
            width: 3rem;
            height: 3rem;
            color: $error;
        }
        .errors-message {
            margin: 2rem;
            font-size: 0.875rem;
            color: $medium-gray;
        }
    }

    @media screen and (max-width: $breakpoint-tablet - 1) {
        margin: 0;
    }
}
