@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.artwork-spotlights {
    position: static;
    margin-bottom: 2rem;
    .headerimages {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        height: 100%;
        // margin: 0 (-$main-content-margin) 2rem;
        margin: 0 0 1rem;
        overflow: hidden;
        .column:first-child {
            padding-right: 10px;
        }
        .card {
            width: 100% !important;
            &:hover {
                box-shadow: none !important;
            }
        }
        .card-header, .card-contents {
            display: none;
        }
        .card-image {
            height: 400px;
            .image {
                height: 400px; // required for image-viewer
            }
            figure {
                width: 100% !important;
            }
            figure .social-button-wrapper {
                right: 0.25rem;
                bottom: 0.25rem;
            }
        }
        // .card:last-child {
        //     .card-image {
        //         margin-left: auto !important; // no!
        //     }
        // }
        .column.is-12 {
            .card .tile {
                width: 98vw !important;
            }
            .card-image .image {
                width: 100% !important;
            }
        }
    }
    @media screen and (max-width: $breakpoint-tablet - 1) {
        // hide the entire block on narrow displays
        display: none;
    }
}
