@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#organization-page.content-page {
    .header {
        .organization-name {
            &, .dx-texteditor-container {
                margin: 0.5rem 0;
                font-family: $helvetica-roman;
                font-weight: normal;
                font-size: 2rem;
                line-height: 2.125rem;
            }
        }
        .locality-area {
            &, .dx-texteditor-container {
                font-family: $helvetica-thin;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
        hr {
            display: block;
            width: 55px;
            margin: 20px 0 20px;
        }
        .tagline {
            &, .dx-texteditor-container {
                margin: 2.5rem 2rem 2.5rem 0;
                font-family: 'Sabon MT W01 Italic', serif;
                font-weight: 600;
                font-size: 1.5rem !important;
            }
            &.page-text {
                margin-top: 0;
            }
        }
        .outline {
            margin: 0 0 1rem;
            padding: 0 1rem 2rem 0;
            text-align: left;
        }
        .contact {
            z-index: 1000;
            font-size: 1rem;
        }
        .select-image {
            margin-left: 0;
        }
        .banner {
            .logo {
                // on wide screens logo is hidden and banner
                // background visible, but when screen is narrow,
                // these @media rules hide the banner and display the logo.
                display: none;
                align-self: right;
                width: 150px;
                height: auto;
                margin: 1rem;
                margin-left: auto;
                color: black;
                .image {
                    display: block;
                    margin-left: auto;
                }
            }
            .ingress-image {
                min-width: 100px;
                min-height: 200px;
            }
        }
        .social-actions {
            display: flex;
            align-items: center;
            .do-follow {
                margin-right: 1rem;
            }
        }
        @media screen and (max-width: $breakpoint-tablet - 1) {
            .column.outline {
                width: auto;
            }
        }
        @media screen and (max-width: $breakpoint-mobile - 1) {
            .banner {
                padding-left: 0;
                .logo {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
        }
        @media screen and (max-width: $breakpoint-mobile-xs - 1) {
            .banner {
                min-height: unset !important;
                background-image: none !important;
                .logo {
                    display: block;
                }
            }
        }
    }

    .slick-track {
        min-height: unset;
    }

    // .slides-other-organizations {
    //     // show gray slides background over the margin
    //     margin-right: - $main-content-margin;
    //     margin-left: - $main-content-margin;
    //     padding-right: $main-content-margin;
    //     padding-left: $main-content-margin;
    //     background-color: $light-gray;
    //     .card {
    //         background-color: transparent;
    //     }
    //     .card-image {
    //         height: 45vh;
    //         .image {
    //             background-size: 100% 100%;
    //             background-position-x: 0;
    //         }
    //     }
    // }
}

.organization-info,
.organization-collections,
.organization-exhibitions,
.organization-events,
.organization-artworks,
.organization-visitor,
.organization-media,
.organization-contact {
    min-height: 200px;
}

.organization-news,
.organization-history {
    .card.orgnews .card-image {
        height: 400px;
    }
    .display-options {
        margin-bottom: 1rem;
    }
}

#organization-artworks {
    .owner {
        display: none;
    }
    .collection {
        display: block;
    }
}
