@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-settings {
    font-family: $main-sans-serif;

    .dx-tabs {
        margin-bottom: 1em;
    }

    .settings-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        .settings-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 1rem;
        }
        .settings-section-contents {
            display: inline-flex;
            flex-direction: column;
            width: auto;
            margin: 1rem 1rem 2rem;
            padding: 2rem 4rem;
            @media screen and (max-width: $breakpoint-tablet - 1) {
                padding: 1rem 0;
            }
        }
        .actions {
            margin: 2rem auto;
        }
    }
    table.settings-section-contents.table-hidden {
        display: none !important;
        visibility: hidden;
    }
    table {
        display: table;
    }
    tr {
        display: table-row;
        width: 100%;
        margin-bottom: 1rem;
        @media screen and (max-width: $breakpoint-tablet - 1) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    td {
        display: table-cell;
        min-width: clamp(5rem, 10vw, 12rem);
        margin-right: 2rem;
        margin-bottom: 1rem;
        font-size: 1.25rem;
        vertical-align: middle;
        &.top {
            vertical-align: top;
        }
        &:first-child {
            width: 14em; // text changes, so width needs to be fixed
            margin-right: 4rem;
        }
        &:nth-child(2) {
            font-size: 1rem;
        }
        .material-design-icon {
            width: 42px !important;
            height: 42px !important;
            padding-top: 0.15rem;
            vertical-align: middle;
        }
        @media screen and (max-width: $breakpoint-tablet - 1) {
            .dx-textbox {
                width: 80vw !important;
            }
        }
    }
    .bio-td, .handle-td {
        width: auto;
    }
    .settings-section.user-profile td {
        vertical-align: baseline;
    }
    .user-tools .user-tools-list {
        max-width: 20rem;
    }
    .settings-section.appearance {
        td:first-child {
            vertical-align: middle; // text
        }
        td:nth-child(2) {
            vertical-align: middle; // sun / moon icon
        }
        td:nth-child(3) {
            vertical-align: bottom; // switch icon
        }
    }
    .settings-section.browser-management {
        td:first-child {
            width: auto;
        }
    }
    .memberships,
    .user-tools {
        min-height: 70px;
        font-size: 0.85rem;
        ul {
            margin: 1rem 2rem;
            column-count: 3;
            column-gap: 2rem;
            column-fill: auto;
            column-width: 10rem;
            li {
                margin-bottom: 0.25rem;
            }
        }
    }
    .terms {
        .btn {
            margin-left: 2rem;
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        td {
            min-width: unset;
        }
        .appearance {
            td:first-child {
                width: 7rem;
            }
        }
    }
}
