@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.user-tool {
    display: flex;
    flex-direction: column;
    margin: 0 0 1.5rem;
    cursor: pointer;
    .tool-head {
        display: inline-flex;
        align-items: baseline;
        width: 100%;
        border-bottom: 2px solid $dark-gray-3;
        img {
            max-height: 40px;
            margin-right: 0.75rem;
        }
        h3 {
            font-family: $main-sans-serif;
            font-size: 1.1rem;
            vertical-align: text-bottom;
        }
    }
    .tool-body {
        display: inline-flex;
        a {
            font-family: $main-sans-serif;
            font-size: 1.5rem;
            &, &:hover {
                color: $dark-gray-2;
            }
        }
        .material-design-icon {
            width: 24px !important;
            height: 24px !important;
            margin-left: auto !important;
            color: $dark-gray-2;
            vertical-align: text-bottom !important;
        }
    }
    &.dark {
        .tool-head h3 {
            color: $medium-gray;
        }
        .tool-body a {
            color: $medium-gray;
        }
        .tool-body .material-design-icon {
            color: $medium-gray;
        }
    }
}
