@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.organization-query-options {
    .option.disabled {
        // hack for autocomplete with suggestions visible
        height: 35px;
    }
}
