@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.edit-read-access-group {
    display: inline-block;
    cursor: pointer;
    .label {
        letter-spacing: 1px;
    }
    .dx-collection {
        display: inline-flex;
        flex-direction: column;
        .dx-radiobutton {
            display: inline-flex;
            margin: 8px 0;
            line-height: auto;
            vertical-align: middle;
        }
    }
    .dx-item-content::first-letter {
        text-transform: lowercase;
    }
}
