@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.communityhome-page {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.communityhome-page-if {
    width: 100%;
    height: calc(100vh - 110px);
}
html::-webkit-scrollbar {
    display: none;
}
