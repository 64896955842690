@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.home-collections {
    background-image: linear-gradient(to top, $light-gray);
    .slick-slide {
        display: inline-block;
        float: none;
        vertical-align: top;
        .card-image .card-link,
        .card-image figure {
            min-height: 280px;
            @media screen and (max-width: $breakpoint-tablet - 1) {
                min-height: 400px;
            }
            @media screen and (min-width: $breakpoint-widescreen) {
                min-height: 550px;
            }
        }
        .card .card-header .card-header-title h1, .card .card-header .card-header-title h2 {
            @include dowrap;
        }
    }
    .explore {
        display: flex;
        justify-items: center;
        .btn {
            margin: 2rem auto;
            padding-top: 4px;
        }
    }
}
