@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#audio-page {
    text-align: left;
    .audio-card {
        width: 100% !important;
        .card-image {
            display: flex;
            flex-grow: 2;
            figure {
                height: 400px; // fix for Firefox
            }
        }
    }
    .podcast-title {
        margin: 1rem 0;
        h2.title {
            font-family: $helvetica-medium;
            font-weight: normal;
            font-size: 2rem;
        }
        .headphones-icon {
            float: right;
        }
    }
    .audio-player {
        width: auto;
        .card-header {
            display: none;
        }
        @media screen and (min-width: $breakpoint-tablet) {
            flex-direction: column;
        }
    }
    .artwork-card {
        &:hover {
            box-shadow: none;
        }
    }
}
