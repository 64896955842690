@import "~assets/styles/mixins.scss";

.submit-button {
    display: block;
    // overall button style in main app.scss
    &.disabled {
        .is-loading {
            margin-top: 6px;
        }
    }
}
