@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

#actor-art-vault {
    .vault-files {
        .card-image {
            // TODO smaller images
            .image {
                background-size: cover;
                background-position: center 50%;
            }
        }
        .credits {
            display: none;
        }
        .slick-slide {
            display: inline-block;
            float: none;
            vertical-align: top;
        }
        .slick-dots {
            position: relative;
            top: 0;
            bottom: unset;
            margin-bottom: 1rem;
        }
    }

    #tween-layer {
        position: absolute;
        z-index: 9999;
        display: none;
        img {
            width: 100%;
            height: 100%;
        }
    }

    #artwork-preview {
        #scroll-target {
            position: relative;
            top: -1.5rem;
        }
    }
}
