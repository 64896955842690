@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.modal-login-required {
    .o-modal__content {
        background: transparent;
        box-shadow: none;
        .character {
            width: 240px;
        }
    }
}
