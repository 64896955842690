@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

$event-block-height: 3rem;

.event-classes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 50vw;
    .event-class {
        display: inline-flex;
        width: 210px;
        height: $event-block-height;
        margin: 0 1rem 0.5rem 0;
        padding: 0.25rem 1rem 0.25rem 0.5rem;
        font-family: $helvetica-bold;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.1;
        color: gray;
        border: 1px solid $light-gray-3;
    }
    .material-design-icon {
        width: 22px;
        height: 22px;
        margin: auto 1rem auto 0.5rem;
    }
    span {
        margin: auto 0;
    }
    .dx-radiogroup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .dx-radiobutton {
            margin: 0 0 1rem;
        }
    }
    .dx-collection {
        display: inline-flex;
        flex-wrap: wrap;
        width: 100vw;
    }
    .dx-item {
        display: inline-flex;
        align-content: center;
        height: $event-block-height !important;
    }
    .dx-radio-value-container {
        display: none;
    }
    &.edit-mode {
        .dx-radio-value-container {
            display: inline-flex;
            align-items: center;
        }
    }

    @media screen and (max-width: $breakpoint-mobile - 1) {
        max-width: unset;
    }
}
