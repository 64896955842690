@import "~assets/styles/mixins.scss";

// @import '~assets/styles/mixins';

.event-card {
    margin-right: 1rem;
    .card-header {
        width: 100%;
        .card-header-title {
            font-family: 'Sabon LT W01 Bold', serif;
            font-weight: normal;
            h2.title {
                max-width: calc(100% - 55px); // leave space for .event-class-title-icon
                margin-bottom: 0;
                font-family: inherit;
                line-height: 1.25;
                letter-spacing: -0.5px;
                @include dowrap;
            }
        }
        .details {
            font-family: $helvetica-light;
        }
    }
    .card-link {
        color: $dark-gray-3;
    }
    .event-organizer {
        display: inline-flex;
        align-items: baseline;
        margin-left: 3px;
        font-family: $helvetica-roman;
        span.label {
            margin-right: 0.5em;
            font-family: inherit;
            font-weight: normal;
            font-size: 0.875rem;
        }
        .organizer-name {
            font-family: inherit;
            font-size: 1rem;
            line-height: 1;
        }
    }
    .event-period {
        padding: 0.25rem 0.125rem;
        font-family: $helvetica-roman;
        font-family: $helvetica-light;
        text-decoration: none;
        &, &:hover {
            color: $dark-gray-2;
        }
    }
    .body-html {
        font-family: $helvetica-light;
        font-weight: normal;
        letter-spacing: 1px;
    }
}
