@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.toggle-mode-edit {
    margin: auto;
    line-height: 0;
    .material-design-icon {
        width: 28px !important;
        height: 28px !important;
        cursor: pointer;
        &.disabled {
            cursor: default !important;
        }
        &.loading {
            color: $dark-gray-2;
        }
    }
    &.active {
        .material-design-icon {
            color: $havelock;
            transform: translateY(-1px) translateX(1px);
        }
    }
}
