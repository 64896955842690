@import "~assets/styles/mixins.scss";

// @import '~assets/styles/variables';

.organization-spotlights {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    min-height: unset;
    margin: 1rem 0 2rem;
    overflow: hidden;
    font-family: $helvetica-roman;
    font-size: 0.875rem;
    .card {
        flex-grow: 1;
        align-content: space-evenly;
        width: auto !important;
        height: 320px !important;
        min-width: 320px;
        max-width: 1024px;
        min-height: 320px;
        max-height: 320px;
        margin: 0 0.5rem 8px !important;
        background: transparent !important;
        a {
            display: inline-block;
            height: 100%;
        }
        .card-image {
            flex-grow: 1;
            width: auto;
            height: inherit;
            min-width: 97%;
        }
        figure {
            width: auto;
            height: 100% !important; // works on Firefox 68 and Chrome 80
        }
        .card-contents button {
            cursor: pointer;
        }
        .makers {
            font-size: 1.25rem !important;
        }
        .owner,
        .collection {
            display: none !important;
        }
        .card-header {
            position: absolute;
            top: 2rem;
            left: 0;
            z-index: 4000; // should be lesser than navbar drop-down
            width: auto;
            max-width: 100%;
            padding: 0.5rem 1rem;
            font-size: 1.5rem;
            color: white;
            background: rgba(5, 5, 0, 0.70);
            backdrop-filter: blur(5px);
            a {
                color: white;
            }
        }
        // change header position based on possible image focus point
        &.card-focus-top {
            .card-header {
                top: unset;
                bottom: 2rem;
            }
            .card-contents button {
                display: none;
            }
        }
        .card-header-title {
            color: $light-gray;
            h2.title {
                font-family: "Sabon MT W01 Italic", serif !important;
                font-size: 1.2rem !important;
                letter-spacing: 0.05em;
                color: white;
            }
        }
        .card-contents {
            position: absolute;
            bottom: 0;
            display: block;
            flex-grow: 1;
            justify-content: flex-start;
            text-align: center;
            a {
                width: 100%;
                text-align: center;
            }
            .btn {
                margin: 2rem auto;
                padding: 0.5rem 2rem;
                letter-spacing: 0.15em;
            }
        }
        .dimensions, .owner {
            display: none;
        }

        &.card-landscape {
            flex-grow: 2;
        }

        &:hover {
            box-shadow: none;
            .btn {
                text-decoration: underline;
            }
        }

        &:first-child {
            margin-left: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
            margin: 0 auto 0.5rem 1rem !important;
        }

        &.artwork-card {
            .card-header {
                padding-right: 1.5rem;
            }
        }
        &.event-card {
            .card-header-title {
                h2.title {
                    font-family: $helvetica-bold !important;
                    font-size: 1.25rem;
                }
            }
            .event-organizer {
                display: none; // is current organization
            }
            .event-period {
                font-family: $helvetica-roman;
                font-family: $helvetica-light;
                font-size: 1rem;
                color: $light-gray;
            }
        }

        //- Events have alternate row flow
        &.actor-events {
            .card {
                flex-direction: row-reverse;
            }
        }

        @media screen and (min-width: $breakpoint-widescreen - 1) {
            figure {
                max-width: 1024px !important;
            }
        }
        @media screen and (max-width: $breakpoint-widescreen - 1) {
            max-width: unset !important;
            // hide all items except the first two
            &:nth-child(1n) {
                display: none;
            }
            &:nth-child(1),
            &:nth-child(2) {
                display: flex;
                figure {
                    width: auto !important;
                    max-width: 45vw !important;
                }
            }
            @media screen and (max-width: 950px) {
                .card-image {
                    min-width: 100%;
                }
                // show only first item
                figure {
                    max-width: unset !important;
                }
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }

    &.card-count-1 {
        .card figure {
            max-width: 1024px !important;
        }
    }
    &.card-count-2 {
        .card figure {
            max-width: 45vw !important;
            @media screen and (max-width: $breakpoint-widescreen - 1) {
                max-width: unset !important;
            }
        }
    }
    &.card-count-3 {
        .card figure {
            max-width: 32vw !important;
            @media screen and (max-width: $breakpoint-widescreen - 1) {
                max-width: unset !important;
            }
        }
    }
    &.card-count-n {
        flex-wrap: wrap;
        .card:nth-child(1n) {
            display: flex;
        }
    }
}
